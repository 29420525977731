import { FC } from 'react';
import { InputProps } from './input-filter.types';
import { NumericRange } from '../../../../models';
import './input-filter.css';
import { NUMERIC_RANGE } from '../../../../constants';
import cn from 'classnames';

export const Input: FC<InputProps> = ({
  filter,
  updateFilterByAddingValue,
  isSelected,
  index,
  wrapper = '',
  disabled = false,
}) => {
  const isNumericRange = NUMERIC_RANGE.includes(filter.type);

  if (isNumericRange) {
    const inputValue = filter.value as NumericRange;
    return (
      <>
        <span
          className="c-input-filter__symbol u-font-size--t-small u-position--relative"
          id={wrapper}
        >
          <input
            className={cn('u-margin-right--gutter u-font-size--h-tiny c-input-filter c-input', {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'c-input-filter__disabled': disabled,
            })}
            placeholder="From"
            value={inputValue.from}
            autoFocus={isSelected}
            onChange={({ target: { value } }) =>
              updateFilterByAddingValue({
                ...inputValue,
                from: value.replace(',', '.'),
              })
            }
            data-qa={`filters-input-${index}-from`}
          />
        </span>
        <span className="c-input-filter-separator"> - </span>
        <span
          className="c-input-filter__symbol u-font-size--t-small u-position--relative"
          id={wrapper}
        >
          <input
            className={cn('u-margin-right--gutter u-font-size--h-tiny c-input-filter c-input', {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'c-input-filter__disabled': disabled,
            })}
            placeholder="To"
            value={inputValue.to}
            onChange={({ target: { value } }) =>
              updateFilterByAddingValue({
                ...inputValue,
                to: value.replace(',', '.'),
              })
            }
            data-qa={`filters-input-${index}-to`}
          />
        </span>
      </>
    );
  }

  const inputValue = filter.value as string;
  return (
    <span className="c-input-filter__symbol u-font-size--t-small u-position--relative" id={wrapper}>
      <input
        className={cn('u-margin-right--gutter u-font-size--h-tiny c-input-filter c-input', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'c-input-filter__disabled': disabled,
        })}
        placeholder="Enter value"
        value={inputValue}
        autoFocus={isSelected}
        onChange={({ target: { value } }) => updateFilterByAddingValue(value.replace(',', '.'))}
        data-qa={`filters-input-${index}`}
      />
    </span>
  );
};
