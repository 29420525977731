import { PropertyGroup } from '.';
import { translate } from '../../helpers/utils';
import { useSelectedLocales } from '../use-selected-locales/use-selected-locales';
import { usePropertyGroupAliases } from '../../features/aliases/use-property-group-aliases';

const PROPERTY_GROUP_IDS = Array.from({ length: 50 }, (_, i) => i);

export function usePropertyGroups(): PropertyGroup[] {
  const { data: aliases = {} } = usePropertyGroupAliases();
  const selectedLocales = useSelectedLocales();
  const singleSelectedLocale = selectedLocales.length === 1 ? selectedLocales[0] : null;

  return PROPERTY_GROUP_IDS.map(id => ({
    id,
    name: translate('analytics_property_groups_dropdown_item', String(id)),
    alias: singleSelectedLocale ? aliases[id]?.[singleSelectedLocale] : undefined,
  }));
}
