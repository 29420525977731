import { FC, useCallback } from 'react';
import { FilterFormField } from '../../../features/filters/filter-form-field/filter-form-field';
import { Filter, StringQualifier, NumericQualifier } from '../../../features/filters/types';
import { isNumber, translate } from '../../../helpers/utils';
import { PopoverFilterType } from './global-active-filters.types';
import { Popover } from '../../../core-ui/components/popover/popover';
import { inputFilterTypeMap } from '../custom-filters/input-filter/constants';
import { NUMERIC_RANGE } from '../../../constants';
import { NumericRange } from '../../../models';

type FilterPopoverProps = {
  anchor: HTMLElement;
  setAnchor: (anchor: HTMLElement | null) => void;
  selectedFilter: PopoverFilterType | undefined;
  clearFilter: (filter: PopoverFilterType) => void;
  applyFilter: (filter: PopoverFilterType) => void;
  setSelectedFilter: (filter: PopoverFilterType | undefined) => void;
  isFilterInFilters: boolean;
};

export const FilterPopover: FC<FilterPopoverProps> = ({
  anchor,
  setAnchor,
  selectedFilter,
  clearFilter,
  applyFilter,
  setSelectedFilter,
  isFilterInFilters,
}) => {
  const isValid = useCallback(() => {
    if (!selectedFilter || !selectedFilter.value) {
      return false;
    }
    const isNumericRange = NUMERIC_RANGE.includes(selectedFilter.type);
    if (isNumericRange) {
      const value = selectedFilter.value as NumericRange;
      return isNumber(value.from) && isNumber(value.to);
    }
    return true;
  }, [selectedFilter]);

  if (!selectedFilter) {
    return null;
  }

  const onSubmit = () => {
    if (!isValid()) return;
    applyFilter(selectedFilter);
    setAnchor(null);
  };

  return (
    <Popover
      anchor={anchor}
      onClose={() => {
        setAnchor(null);
        setSelectedFilter(undefined);
      }}
      buttons={{
        start: [
          {
            text: translate('analytics_filter_clear'),
            variant: 'flat',
            disabled: !isFilterInFilters,
            onClick: () => {
              setSelectedFilter(undefined);
              clearFilter(selectedFilter);
              setAnchor(null);
            },
          },
        ],
        end: [
          {
            text: 'Cancel',
            variant: 'tertiary',
            onClick: () => {
              setAnchor(null);
              setSelectedFilter(selectedFilter);
            },
          },
          {
            text: 'Apply',
            variant: 'primary',
            disabled: !isValid(),
            onClick: onSubmit,
          },
        ],
      }}
    >
      <FilterFormField
        filter={getFormFilter(selectedFilter)}
        setFilter={(currentFilter: Filter) =>
          setSelectedFilter({
            ...selectedFilter,
            value: currentFilter.value,
            type: currentFilter.qualifier,
            qualifierLabel: currentFilter.qualifierLabel,
          } as PopoverFilterType)
        }
        onSubmit={onSubmit}
      />
    </Popover>
  );
};

const getFormFilter = (filter: PopoverFilterType): Filter => {
  const formFilter = {
    id: filter.columnName,
    name: filter.columnLabel,
    decoration: filter.wrapper,
    qualifierLabel: filter.qualifierLabel,
  };
  const columnType = inputFilterTypeMap.get(filter.columnType);

  const isNumericRange = NUMERIC_RANGE.includes(filter.type);

  if (isNumericRange) {
    return {
      ...formFilter,
      value: filter.value as NumericRange,
      type: 'numeric',
      qualifier: filter.type as NumericQualifier,
    };
  }
  if (columnType === 'string') {
    return {
      ...formFilter,
      value: filter.value as string,
      type: 'string',
      qualifier: filter.type as StringQualifier,
    };
  }
  return {
    ...formFilter,
    value: filter.value as number,
    type: 'numeric',
    qualifier: filter.type as NumericQualifier,
  };
};
