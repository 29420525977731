import { ChangeEvent, FC } from 'react';
import { translate } from '../../../helpers/utils';
import { DatePeriod } from '../../../models/dates';
import styles from './compare-date-picker.module.css';

type CompareDatePickerSelectorProps = {
  isPeriodSelectable?: boolean;
  period: DatePeriod;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
};

export const CompareDatePickerSelector: FC<CompareDatePickerSelectorProps> = ({
  isPeriodSelectable,
  period,
  onChange,
}) => {
  const periodOptions = Object.values(DatePeriod).map((mappedPeriod: DatePeriod) => (
    <option key={mappedPeriod} value={mappedPeriod}>
      {translate(mappedPeriod)}
    </option>
  ));

  return (
    <>
      <div className={styles.datePeriodMarker} />
      {isPeriodSelectable ? (
        <select data-qa="date-picker-select-period" value={period} onChange={onChange}>
          {periodOptions}
        </select>
      ) : null}
    </>
  );
};
