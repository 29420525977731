import { FC } from 'react';
import { AnchoredMenu } from '../../../core-ui/components/menu/anchored-menu';
import { useMenuAnchor } from '../../../core-ui/components/menu/use-menu-anchor';
import styles from './qualifier-selector.module.css';
import { useFilterFormField } from './use-filter-form-field';
import { useFilters } from '../../../global/context/filter-context';

export const QualifierSelector: FC = () => {
  const { filter, changeQualifier } = useFilterFormField();
  const { anchor, showMenu, hideMenu } = useMenuAnchor();

  const { filterTypes } = useFilters();

  const qualifiers = filterTypes.filter(({ type }) => type.includes(filter.type));

  return (
    <>
      <a onClick={showMenu} className={styles.qualifierLink}>
        {filter.qualifierLabel}
      </a>
      {anchor && (
        <AnchoredMenu
          anchor={anchor}
          onClose={hideMenu}
          items={qualifiers.map(({ key, label }) => ({
            id: key,
            label,
            onClick: () => changeQualifier(key, label),
          }))}
        />
      )}
    </>
  );
};
