import { useContext } from 'react';
import { isNumericRangeFilter } from '../is-numeric-range-filter';
import { Filter, FilterQualifier } from '../types';
import { FilterFormFieldContext } from './filter-form-field-context';
import { NumericRange } from '../../../models';

export function useFilterFormField() {
  const context = useContext(FilterFormFieldContext);

  if (!context) {
    throw new Error('useFilterFormField must be used within a FilterFormFieldProvider');
  }
  const { filter, setFilter, onSubmit } = context;

  return {
    filter,
    onSubmit,
    setValue: (value: string | number | NumericRange) => {
      setFilter({ ...filter, value } as Filter);
    },

    changeQualifier: (qualifier: FilterQualifier, label: string) => {
      const newFilter = { ...filter, qualifier, qualifierLabel: label } as Filter;
      // If the filter changes between numeric range and numeric, reset the value
      if (isNumericRangeFilter(filter) !== isNumericRangeFilter(newFilter)) {
        return setFilter({ ...newFilter, value: undefined });
      }
      return setFilter(newFilter);
    },
  };
}
