import { ReactNode } from 'node_modules/@types/react';
import { Styles } from 'node_modules/react-joyride/dist';
import { ONBOARDING_TOURS } from '../../../constants/onboarding-targets';

type GetContentProps = {
  title: string;
  body: string;
};

type Step = {
  target: string;
  content: ReactNode;
  placement: string;
  disableBeacon?: boolean;
  disableSkipButton?: boolean;
  styles?: Partial<Styles>;
  placementBeacon?: string;
  trackingFeatureName?: string;
  offset?: number;
};

export const getContent = ({ title, body }: GetContentProps): JSX.Element => {
  return (
    <div>
      <h4>{title}</h4>
      <div>{body}</div>
    </div>
  );
};

const createStep = ({
  tour,
  step,
  content,
  disableBeacon,
  placement,
  placementBeacon,
  trackingFeatureName,
  styles,
}: {
  tour: { [key: string]: string | string[] };
  step: string;
  content: ReactNode;
  disableBeacon?: boolean;
  placement?: string;
  styles?: Partial<Styles>;
  placementBeacon?: string;
  trackingFeatureName: string;
}): Step => ({
  target: `[data-tour=${tour[step]}]`,
  content,
  disableBeacon: disableBeacon,
  placement: placement ?? 'top',
  placementBeacon,
  trackingFeatureName,
  styles,
});

const quickDataExploration = {
  STEP_1: createStep({
    tour: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION,
    step: 'STEP_1',
    disableBeacon: false,
    trackingFeatureName: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.title,
      body: 'You can now examine the performance of any metric by clicking the "More options" icon next to it.',
    }),
  }),
  STEP_2: createStep({
    tour: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION,
    step: 'STEP_2',
    disableBeacon: true,
    styles: { spotlight: { width: 420 } },
    trackingFeatureName: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.title,
      body: 'To dive deeper, select the related views to see performance details for the highest and lowest values or time periods.',
    }),
  }),
  STEP_3: createStep({
    tour: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION,
    step: 'STEP_3',
    disableBeacon: true,
    trackingFeatureName: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.QUICK_DATA_EXPLORATION.title,
      body: 'Your selected metrics are now sorted from High to Low or Low to High, for easier comparison.',
    }),
  }),
};

const changeBid = {
  STEP_1: createStep({
    tour: ONBOARDING_TOURS.CHANGE_BID,
    step: 'STEP_1',
    disableBeacon: false,
    placementBeacon: 'left',
    placement: 'left-start',
    trackingFeatureName: ONBOARDING_TOURS.CHANGE_BID.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.CHANGE_BID.title,
      body: 'Choose to input a specific value or adjust by percentage for flexible bid control',
    }),
    styles: {
      beacon: {
        transform: 'translateX(-15px) translateY(-1px)',
      },
    },
  }),
  STEP_2: createStep({
    tour: ONBOARDING_TOURS.CHANGE_BID,
    step: 'STEP_2',
    disableBeacon: true,
    placement: 'left-end',
    trackingFeatureName: ONBOARDING_TOURS.CHANGE_BID.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.CHANGE_BID.title,
      body: 'Set a direct value or apply a percentage to quickly update multiple bids at once.',
    }),
  }),
  STEP_3: createStep({
    tour: ONBOARDING_TOURS.CHANGE_BID,
    step: 'STEP_3',
    disableBeacon: true,
    placement: 'left-start',
    trackingFeatureName: ONBOARDING_TOURS.CHANGE_BID.trackingFeatureName,
    content: getContent({
      title: ONBOARDING_TOURS.CHANGE_BID.title,
      body: 'Preview bid updates in real-time to ensure optimal adjustments.',
    }),
  }),
};

export const ONBOARDING_TARGETS = {
  QUICK_DATA_EXPLORATION: quickDataExploration,
  CHANGE_BID: changeBid,
};
