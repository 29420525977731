import { addDays, format, subDays } from 'date-fns';
import { sortBy } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  useCompareDateRangePeriods,
  useIsComparing,
} from '../../components/date-picker/date-picker.state';
import { getMatchingFilters } from '../../components/toolbar/custom-filters/helpers/validate-filters';
import { DATE_FORMAT_STANDARD, NotificationLevel, NUMERIC_RANGE } from '../../constants';
import { AppContext } from '../../global/context/app-context';
import { useFilters } from '../../global/context/filter-context';
import ApiAnalyticsHelper from '../../helpers/api/analytics/api-analytics-helper';
import { BaseViewPayloadV2 } from '../../helpers/api/analytics/types';
import { RequestHelper } from '../../helpers/api/request-helper';
import { formatTotalRow } from '../../helpers/row-formatter';
import { generateCompositeConditionForBetween, translate } from '../../helpers/utils';
import {
  Aggregation,
  CampaignTypeCode,
  ColumnDef,
  DateRange,
  FilterColumn,
  FilterType,
  Row,
  Sort,
  SortDirection,
  Table,
  TableData,
  View,
} from '../../models';
import { biddingTypes2Filter } from '../../services/analytics-service';
import { getPartnersId } from '../../services/app-service';
import { showNotification } from '../../services/notification-service';
import { useAggregations } from '../use-aggregations/use-aggregations';
import { UsePaginationRtn, usePagination } from '../use-pagination';
import { useRowsSelection } from '../use-rows-selection';
import { useSortParams } from '../use-sort-params';

type UseViewTableOptions = {
  avoidFetchingSubtotal?: boolean;
  columnId?: string;
  biddingTypeId?: string;
  combineDatePeriods?: boolean;
  ignoreGlobalFilters?: boolean;
  includeNonPerformingData?: boolean;
};

export const useViewTable = (
  view: View,
  aggregation: Aggregation,
  payload: BaseViewPayloadV2,
  options: UseViewTableOptions,
): UseViewTableRtn => {
  const customFilters = payload.filters ?? [];
  const { partner, subPartners } = useContext(AppContext);
  const {
    filters: globalFilters,
    setFilters: setGlobalFilters,
    setFiltersData,
    filterTypes,
    selectedBiddingTypes,
  } = useFilters({
    hideGlobalBiddingTypeDropdown: options.biddingTypeId === undefined,
  });

  const pagination = usePagination();
  const [columns, setColumns] = useState<ColumnDef[]>([]);
  const [data, setTableData] = useState<TableData>({ rows: [], count: 0 });
  const [total, setTotal] = useState<Row[]>();
  const { selectedPages, selectedRowsById, setSelectedRowsById } = useRowsSelection();
  const [shouldFetchCountAndTotal, setShouldFetchCountAndTotal] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    globalFilters: options.ignoreGlobalFilters ? [] : globalFilters,
    customFilters,
  });

  const { columnKey, direction } = useSortParams(['sortBy', 'orderBy']);

  const [sort, setSort] = useState<Sort | undefined>(
    columnKey && direction
      ? {
          columnName: columnKey,
          direction: direction.toUpperCase() as SortDirection,
        }
      : undefined,
  );
  const { firstUpdate, firstUpdatePagAndSort, setFirstUpdatePagAndSort, setFirstUpdate } =
    useFirstUpdate();
  const [isUpdatingTableData, setIsUpdatingTableData] = useState<boolean>(false);
  const [isFetchingColumns, setIsFetchingColumns] = useState<boolean>(false);
  const [isUpdatingRow, setIsUpdatingRow] = useState<boolean>(false);
  const [search, setSearch] = useState<FilterColumn[]>([]);
  const [isComparing] = useIsComparing();
  const [compareDatePeriods] = useCompareDateRangePeriods();
  const allActiveFilters = getAllActiveFilters(
    view,
    isComparing,
    filters,
    columns,
    selectedBiddingTypes,
    search,
    options,
  );

  const aggregations = useAggregations(aggregation);

  useEffect(() => {
    const effect = async () => {
      const effectivePartnersId: number[] = getPartnersId(partner, subPartners);

      setIsFetchingColumns(true);
      try {
        const viewColumns = await ApiAnalyticsHelper.fetchViewColumns(view, effectivePartnersId);

        const prunedFilters: Filters = {
          globalFilters: getMatchingFilters(filters.globalFilters, viewColumns),
          customFilters: filters.customFilters,
        };

        setFilters(prunedFilters);
        setColumns(viewColumns);
        setFiltersData(
          viewColumns.filter(column => column.isComparable),
          filters.globalFilters,
        );
      } catch (e) {
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate((e as any).message),
        });
      } finally {
        setIsFetchingColumns(false);
      }
    };

    effect();

    return () => {
      ApiAnalyticsHelper.cancelAllRequests();
    };
  }, [partner, payload.locales, view]);

  useEffect(() => {
    if (columns.length === 0 || isUpdatingTableData) return;

    updateFilters('customFilters', customFilters);
  }, [selectedBiddingTypes]);

  useEffect(() => {
    if (columns.length === 0 || isUpdatingTableData) return;

    updateTableData();
  }, [options.includeNonPerformingData]);

  useEffect(() => {
    updateFilters('globalFilters', globalFilters);
  }, [globalFilters]);

  useEffect(() => {
    if (columns.length === 0 || isUpdatingTableData) return;

    updateTableData();
    return () => {
      ApiAnalyticsHelper.cancelAllRequests();
    };
  }, [columns, payload.locales]);

  useEffect(() => {
    if (columns.length === 0) return;
    if (firstUpdate) {
      setFirstUpdate(false);
      return;
    }
    resetData();
  }, [
    columns,
    filters,
    search,
    payload.from,
    payload.locales,
    payload.to,
    sort,
    partner,
    subPartners,
    aggregation,
    compareDatePeriods,
    selectedBiddingTypes,
  ]);

  useEffect(() => {
    if (firstUpdatePagAndSort) {
      setFirstUpdatePagAndSort(false);
      return;
    }

    if (columns.length !== 0) {
      if (shouldFetchCountAndTotal) {
        updateRowsWithCountAndTotal();
      } else {
        updateRows();
      }
    }
    setShouldFetchCountAndTotal(false);
  }, [sort, pagination.pagination]);

  const resetData = () => {
    setShouldFetchCountAndTotal(true);
    pagination.resetPagination();
    selectedPages.current = new Set();
    setSelectedRowsById(new Map());
  };

  const updateRowsWithCountAndTotal = async () => {
    setTableData({ rows: [], count: 0 });
    updateTableData();
  };

  async function fetchRows(partnersId: number[]): Promise<Row[]> {
    const otherCompareDatePeriods = isComparing ? compareDatePeriods : [];
    const datePeriods = [{ from: payload.from, to: payload.to }, ...otherCompareDatePeriods];
    const sortedDatePeriods = sortBy(datePeriods, ['from', 'to']).reverse();
    if (options.combineDatePeriods)
      return await ApiAnalyticsHelper.fetchRowsByView(view, aggregations, {
        locales: payload.locales,
        pagination: pagination.pagination,
        partnersId,
        sort,
        filters: allActiveFilters.concat(
          generateCompositeConditionForAllDateRanges(datePeriods, columns) ?? [],
        ),
      });
    return Promise.all(
      sortedDatePeriods.map(({ from, to }) =>
        ApiAnalyticsHelper.fetchRowsByView(view, aggregations, {
          ...payload,
          from,
          to,
          pagination: pagination.pagination,
          partnersId,
          sort,
          filters: allActiveFilters,
        }),
      ),
    ).then(rows => rows.flat());
  }

  function fetchCount(effectivePartnersId: number[]) {
    const otherCompareDatePeriods = isComparing ? compareDatePeriods : [];
    const datePeriods = [{ from: payload.from, to: payload.to }, ...otherCompareDatePeriods];
    return ApiAnalyticsHelper.fetchTotalCountByView(view, aggregations, {
      locales: payload.locales,
      pagination: pagination.pagination,
      partnersId: effectivePartnersId,
      sort,
      filters: allActiveFilters.concat(
        generateCompositeConditionForAllDateRanges(datePeriods, columns) ?? [],
      ),
    });
  }

  function fetchSubTotal(effectivePartnersId: number[]) {
    if (isComparing || options.avoidFetchingSubtotal) return Promise.resolve(undefined);
    return ApiAnalyticsHelper.fetchSubTotalByView(view, aggregations, {
      ...payload,
      pagination: pagination.pagination,
      partnersId: effectivePartnersId,
      sort,
      filters: allActiveFilters,
    });
  }

  function fetchTotal(effectivePartnersId: number[]) {
    if (isComparing) return Promise.resolve(undefined);
    return ApiAnalyticsHelper.fetchTotalByView(view, {
      ...payload,
      defaultFilters: allActiveFilters,
      partnersId: effectivePartnersId,
    });
  }

  const updateTableData = async () => {
    setIsUpdatingTableData(true);
    const effectivePartnersId: number[] = getPartnersId(partner, subPartners);
    try {
      const rowsPromise = fetchRows(effectivePartnersId);
      const countPromise = fetchCount(effectivePartnersId);
      const subTotalPromise = fetchSubTotal(effectivePartnersId);
      const totalPromise = fetchTotal(effectivePartnersId);

      const promises: [
        Promise<Row[] | undefined>,
        Promise<number | undefined>,
        Promise<Row | undefined>,
        Promise<Row | undefined>,
      ] = [rowsPromise, countPromise, subTotalPromise, totalPromise];
      const responses: [Row[] | undefined, number | undefined, Row | undefined, Row | undefined] =
        await Promise.all(promises);
      setTableData({ rows: responses[0] ?? [], count: responses[1] ?? responses[0]?.length ?? 0 });
      setTotal(getTotal(responses[3], responses[2], columns ?? []));
    } catch (e) {
      if ((e as Error).message !== RequestHelper.cancelMessage) {
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate((e as any).message),
        });
      }
    } finally {
      setIsUpdatingTableData(false);
    }
  };

  const updateRows = async () => {
    setIsUpdatingRow(true);
    setTableData(prevState => ({ rows: [], count: prevState.count }));
    try {
      const rows = await fetchRows(getPartnersId(partner, subPartners));
      setTableData(prevState => ({ rows, count: prevState.count }));
    } catch (e) {
      if ((e as Error).message !== RequestHelper.cancelMessage) {
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate((e as any).message),
        });
      }
    } finally {
      setIsUpdatingRow(false);
    }
  };

  const selectRow = (selectedRows: Set<number>) => {
    const next = new Map<number, Row>();
    const findRow = (id: number) => data.rows.find(rowTmp => rowTmp[options.columnId!] === id);
    Array.from(selectedRows).forEach(rowIdTmp => {
      const row = findRow(rowIdTmp) ?? selectedRowsById.get(rowIdTmp);
      if (row) next.set(rowIdTmp, row);
    });
    setSelectedRowsById(next);
  };

  const updateFilters = (filterType: keyof Filters, filtersTmp: FilterColumn[]) => {
    const nextFilters = { ...filters };
    nextFilters[filterType] = filtersTmp;
    setFilters(nextFilters);
    setGlobalFilters(nextFilters.globalFilters);
  };

  const clearGlobalFilters = () => {
    updateFilters('globalFilters', []);
  };

  const setCustomFilters = (filtersTmp: FilterColumn[]) => {
    updateFilters('customFilters', filtersTmp);
  };

  return {
    table: { columns, data, total },
    updateRows,
    updateRowsWithCountAndTotal,
    resetData,
    isLoading: isFetchingColumns || isUpdatingRow || isUpdatingTableData,
    retry: updateTableData,
    setSearch,
    pagination: {
      ...pagination,
      setNextPage: () => {
        if (data.count > pagination.pagination.numItemsPerPage) {
          pagination.setNextPage();
        }
      },
    },
    selection: {
      selectedPages: selectedPages.current,
      selectRow,
      setSelectedPages: (pages: Set<number>) => (selectedPages.current = pages),
      selectedRowsById,
      setSelectedRowsById: (newSelection: Map<number, Row>) => setSelectedRowsById(newSelection),
    },
    sort: {
      sort,
      setSort: (sortTmp: Sort) => setSort(sortTmp),
    },
    filters: {
      allActiveFilters,
      hasGlobalFilters: filters.globalFilters.length > 0,
      setCustomFilters,
      filterTypes,
      clearGlobalFilters,
    },
  };
};

export type UseViewTableRtn = {
  table: Table;
  updateRows: () => void;
  updateRowsWithCountAndTotal: () => void;
  resetData: () => void;
  isLoading: boolean;
  retry: () => void;
  setSearch: (value: FilterColumn[]) => void;
  pagination: UsePaginationRtn;
  selection: {
    selectedPages: Set<number>;
    selectRow: (selectedRows: Set<number>) => void;
    setSelectedPages: (pages: Set<number>) => void;
    selectedRowsById: Map<number, Row>;
    setSelectedRowsById: (newSelection: Map<number, Row>) => void;
  };
  sort: {
    sort: Sort | undefined;
    setSort: (sort: Sort) => void;
  };
  filters: {
    allActiveFilters: FilterColumn[];
    hasGlobalFilters: boolean;
    setCustomFilters: (filters: FilterColumn[]) => void;
    filterTypes: FilterType[];
    clearGlobalFilters: () => void;
  };
};

type Filters = {
  globalFilters: FilterColumn[];
  customFilters: FilterColumn[];
};

const getTotal = (
  total: Row | undefined,
  subTotal: Row | undefined,
  columns: ColumnDef[],
): Row[] => {
  let finalTotal: Row[];
  if (total !== undefined && subTotal !== undefined) {
    finalTotal = formatTotalRow([subTotal, total], columns);
  } else if (total !== undefined) {
    finalTotal = formatTotalRow([total], columns);
  } else {
    finalTotal = [];
  }

  return finalTotal;
};

const useFirstUpdate = () => {
  const firstUpdate = useRef(true);
  const firstUpdatePagAndSort = useRef(true);

  return {
    firstUpdate: firstUpdate.current,
    firstUpdatePagAndSort: firstUpdatePagAndSort.current,
    setFirstUpdatePagAndSort: (value: boolean) => (firstUpdatePagAndSort.current = value),
    setFirstUpdate: (value: boolean) => (firstUpdate.current = value),
  };
};

const getAllActiveFilters = (
  view: View,
  isComparing: boolean,
  { globalFilters, customFilters }: Filters,
  columns: ColumnDef[],
  selectedBiddingTypes: CampaignTypeCode[],
  search: FilterColumn[],
  options?: UseViewTableOptions,
): FilterColumn[] => {
  if (columns.length === 0) return [];

  let allGlobalFilters = globalFilters;
  if (view === View.TIME && isComparing) allGlobalFilters = [];

  const conditions = getMatchingFilters(allGlobalFilters, columns)
    .map(filter => {
      if (NUMERIC_RANGE.includes(filter.type)) {
        return generateCompositeConditionForBetween(filter);
      }
      return filter;
    })
    .filter(filter => !!filter);
  const { biddingTypeId, includeNonPerformingData } = options ?? {};

  if (biddingTypeId) conditions.push(biddingTypes2Filter(selectedBiddingTypes, biddingTypeId));
  if (includeNonPerformingData !== undefined) {
    conditions.push({
      columnName: 'is_performance_data',
      columnLabel: 'is_performance_data',
      type: 'EQUALS',
      value: includeNonPerformingData ? '0' : '1',
    });
  }

  conditions.push(...customFilters);
  conditions.push(...search);

  return conditions;
};

export const generateCompositeConditionForAllDateRanges = (
  datePeriods: DateRange[],
  columns: ColumnDef[],
): FilterColumn | null => {
  if (columns.length === 0) return null;

  const dateColumnKey = columns.find(
    column => column.name === 'Date' && column.type === 'int',
  )!.key;
  const compositeCondition: FilterColumn[] = datePeriods.map(({ from, to }) => ({
    columnName: '',
    columnLabel: '',
    type: 'AND',
    value: '',
    condition: [
      {
        columnName: dateColumnKey,
        columnLabel: '',
        type: 'GREATER',
        value: format(subDays(from, 1), DATE_FORMAT_STANDARD),
      },
      {
        columnName: dateColumnKey,
        columnLabel: '',
        type: 'LESS',
        value: format(addDays(to, 1), DATE_FORMAT_STANDARD),
      },
    ],
  }));

  if (compositeCondition.length === 1) return compositeCondition[0];
  return {
    columnName: '',
    columnLabel: '',
    type: 'OR',
    value: '',
    condition: compositeCondition,
  };
};
