import { FC, useState } from 'react';
import { FileUploaderOption, Props } from './button-dropdown-file-uploader.types';
import styles from './button-dropdown-file-uploader.module.css';
import { FileUploader } from '../../global/app/selector-bar/file-uploader';
import { TranslationKey, translate } from '../../helpers/utils';
import { ButtonDropdown } from '../button-dropdown';
import { NotificationLevel } from '../../constants';
import { validateLocales, uploadFileByType } from '../../services/file-bidding-service';
import { showNotification } from '../../services/notification-service';
import { BidFileType } from '../../models';
import { useSelectedPartner } from '../../hooks/use-selected-partner';
import { ButtonLoading } from '../button-loading';
import { useBiddingFilesValidationRules } from '../../helpers/csv/validation/bidding-files-validation-rules';
import { useUploadInventoryFile } from './upload-inventory';
import { useBaseBidFileConverter } from './convert-base-bid-file-modal/use-base-bid-file-converter';
import { useValidateCsv } from '../csv-validator/use-validate-csv';

// TODO: remove class name and replace it with variant (button variant)
export const ButtonDropdownFileUploader: FC<Props> = ({ options, classNameButton, onClick }) => {
  const partner = useSelectedPartner();
  const [isFileBeingUploaded, setIsFileBeingUploaded] = useState(false);
  const validateCsv = useValidateCsv();
  const uploadInventoryFile = useUploadInventoryFile();
  const baseBidFileConverter = useBaseBidFileConverter();
  const biddingFilesValidationRules = useBiddingFilesValidationRules();

  const onSelectedOption = async (bidFileType: BidFileType, file: File) => {
    const { errorValidateLocales } = options.find(({ type }) => type === bidFileType)!;

    try {
      if (bidFileType === BidFileType.BASE_BID) {
        setIsFileBeingUploaded(true);
        const result = await baseBidFileConverter.convert(file);
        if (result.isOldBaseBidFileFormat) return;
      }

      const { headerRules, rowRules } = biddingFilesValidationRules[bidFileType];
      const result = await validateCsv({ file, headerRules, rowRules });
      if (result.isCancelled) return;

      const isInventoryUpload = bidFileType === BidFileType.INVENTORY;
      if (isInventoryUpload) {
        await uploadInventoryFile(file);
        return;
      }

      setIsFileBeingUploaded(true);
      await validateLocales(file, bidFileType, partner.locales, errorValidateLocales);
      const isFileUploaded = await uploadFileByType(bidFileType, partner.partnerId, file);
      if (isFileUploaded) {
        showNotification({
          message: 'upload_bids_file_success',
          level: NotificationLevel.SUCCESS,
        });
      }
    } catch (e) {
      showNotification({
        message: (e as any).message,
        level: NotificationLevel.ERROR,
      });
    } finally {
      setIsFileBeingUploaded(false);
    }
  };

  if (isFileBeingUploaded) return <ButtonLoading />;

  return (
    <ButtonDropdown
      qaId="upload-bids"
      title={translate('upload_bids_container_upload_bids_field')}
      optionNodes={options.map(optionTmp => (
        <FileUploader
          optionType={optionTmp.type}
          key={optionTmp.type}
          qaId={optionTmp.type}
          buttonText={translate(optionTmp.text as TranslationKey)}
          isDisabled={!!optionTmp.isDisabled}
          disabledMessage={optionTmp.disabledMessage}
          uploadFileFn={file => onSelectedOption(optionTmp.type, file)}
          maxFileSize={optionTmp.maxFileSize}
          downloadSample={
            optionTmp.sampleFileUrl !== undefined
              ? {
                  url: optionTmp.sampleFileUrl,
                  translationKey: 'analytics_home_upload_bid_sample',
                }
              : undefined
          }
        />
      ))}
      footerHint={translate('analytics_home_upload_file_size_limit_warning')}
      options={options.map(toButtonDropdownOption)}
      onSelectOption={onSelectedOption}
      classNameButton={classNameButton ?? styles.button}
      onClick={onClick && (() => onClick())}
    />
  );
};

const toButtonDropdownOption = ({ text, type, sampleFileUrl, isDisabled }: FileUploaderOption) => ({
  text,
  value: type,
  sampleFileUrl,
  isDisabled,
});
