import cn from 'classnames';
import { ChangeEvent, FC, Fragment, useMemo } from 'react';
import { FEATURE_FLAGS } from '../../../constants';
import { Button } from '../../../core-ui/components/button/button';
import { getComparePeriodWithLabels, Period } from '../../../helpers/utils/format-date';
import { translate, TranslationKey } from '../../../helpers/utils/translations';
import { DatePeriod, DateRange, DateRangePeriod } from '../../../models';
import { CompareColor } from '../date-picker.state';
import { MAX_COMPARE_DATE_PERIODS } from '../use-date-picker-with-compare';
import styles from './compare-date-picker.module.css';
import { CompareDateRangeInputs } from './compare-date-range-inputs';

type CompareDatePickerPeriodsContainerProps = {
  dateRangePeriod: {
    from: Date | undefined;
    to: Date | undefined;
    period: DatePeriod;
  };
  isComparingPendingValue: boolean;
  compareDateRangePeriods: DateRangePeriod[];
  compareColors: CompareColor[];
  hoverValue?: string;
  selectedDatePeriodIndex: number | null;
  setSelectedDatePeriodIndex: (index: number) => void;
  setCompareValue: (value: { from?: Date; to?: Date }, index: number) => void;
  addNewCompareDateRangePeriod: VoidFunction;
  removeCompareDateRangePeriod: (index: number) => void;
  setCompareDateRangePeriods: (periods: DateRangePeriod[]) => void;
};

export const CompareDatePickerPeriodsContainer: FC<CompareDatePickerPeriodsContainerProps> = ({
  dateRangePeriod,
  isComparingPendingValue,
  compareDateRangePeriods,
  compareColors,
  hoverValue,
  selectedDatePeriodIndex,
  setSelectedDatePeriodIndex,
  setCompareValue,
  addNewCompareDateRangePeriod,
  removeCompareDateRangePeriod,
  setCompareDateRangePeriods,
}) => {
  const timeRangeOptions = useMemo(() => {
    if (!dateRangePeriod) return [];
    const customPeriod =
      dateRangePeriod.from && dateRangePeriod.to
        ? [
            {
              timeRangeName: DatePeriod.CUSTOM,
              from: dateRangePeriod.from,
              to: dateRangePeriod.to,
              label: '',
              subLabel: '',
              fullLabel: '',
            },
          ]
        : [];
    const comparePeriods = Object.keys(Period).map(option =>
      getComparePeriodWithLabels(
        dateRangePeriod as DateRange,
        Period[option as keyof typeof Period],
      ),
    );

    return [...customPeriod, ...comparePeriods].map(({ timeRangeName, from, to }, idx) => ({
      id: idx,
      label: timeRangeName,
      value: idx,
      from,
      to,
    }));
  }, [dateRangePeriod]);

  const onChangeSelectHandler = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
    const selectedIndex = parseInt(event.currentTarget.value);
    const updatedCompareRanges = compareDateRangePeriods.map((group, idx) =>
      idx === index ? { ...timeRangeOptions[selectedIndex], period: DatePeriod.CUSTOM } : group,
    );
    setCompareDateRangePeriods(updatedCompareRanges);
  };

  if (!isComparingPendingValue) return null;
  return (
    <>
      {compareDateRangePeriods.map((datePeriod, index: number) => (
        <Fragment key={index}>
          <div
            className={styles.periodSelectorWrapper}
            key={index}
            onMouseOver={() => setSelectedDatePeriodIndex(index)}
          >
            <div
              className={styles.datePeriodMarker}
              style={{ background: compareColors[index]?.primary }}
            />
            <select
              data-qa="date-picker-select-period"
              className={cn({
                [styles.disabled]: !FEATURE_FLAGS.enablePredefinedRangesCalendarCompare,
              })}
              value={datePeriod.timeRangeName}
              onChange={e => onChangeSelectHandler(e, index)}
              disabled={!FEATURE_FLAGS.enablePredefinedRangesCalendarCompare}
            >
              {Object.values(timeRangeOptions).map(timePeriod => (
                <option key={timePeriod.id} value={timePeriod.value}>
                  {translate(timePeriod.label as TranslationKey)}
                </option>
              ))}
            </select>
            <CompareDateRangeInputs
              from={datePeriod.from}
              to={datePeriod.to}
              hoverValue={hoverValue}
              isSelected={selectedDatePeriodIndex === index}
              onClick={() => setSelectedDatePeriodIndex(index)}
              onFromChange={(value: Date | undefined) => setCompareValue({ from: value }, index)}
              onToChange={(value: Date | undefined) => setCompareValue({ to: value }, index)}
            />
          </div>

          <div className={styles.periodRangesActions}>
            {compareDateRangePeriods.length < MAX_COMPARE_DATE_PERIODS && (
              <Button
                variant="flat"
                onClick={addNewCompareDateRangePeriod}
                text={translate('analytics_date_add_period')}
              />
            )}
            <Button
              variant="flat"
              onClick={() => removeCompareDateRangePeriod(index)}
              text={translate('analytics_date_remove')}
            />
          </div>
        </Fragment>
      ))}
    </>
  );
};
