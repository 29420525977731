import cn from 'classnames';
import { ChangeEvent, FC, RefObject, createRef, useEffect, useState } from 'react';
import parse from 'date-fns/parse';
import subWeeks from 'date-fns/sub_weeks';
import endOfISOWeek from 'date-fns/end_of_iso_week';
import startOfISOWeek from 'date-fns/start_of_iso_week';
import startOfMonth from 'date-fns/start_of_month';
import subMonths from 'date-fns/sub_months';
import lastDayOfMonth from 'date-fns/last_day_of_month';
import startOfYear from 'date-fns/start_of_year';
import subYears from 'date-fns/sub_years';
import lastDayOfYear from 'date-fns/last_day_of_year';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import isSameDay from 'date-fns/is_same_day';
import isValid from 'date-fns/is_valid';
import DayPicker from 'react-day-picker';
import {
  formattedDate,
  getDifferenceBetweenDays,
  getLastXDays,
  translate,
  isUndefinedOrNull,
} from '../../helpers/utils';
import { DATE_SELECTED_NAME, DateSelected } from '../../services/tracker/customer-actions';
import './date-picker.css';
import { DatePickerProps } from './date-picker.types';
import TrackerService, { getCurrentPath } from '../../services/tracker/tracker-service';
import { EVENT_MOUSEDOWN, DAYS_IN_LAST_12_MONTHS } from '../../constants/constants';
import { isFirstDayOfMonth, isMonday, isToday, startOfToday } from 'date-fns';
import { DatePeriod } from '../../models';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';
import { Button } from '../../core-ui/components/button/button';

export const DatePicker: FC<DatePickerProps> = props => {
  const [from, setFrom] = useState<Date | undefined>(props.from);
  const [to, setTo] = useState<Date | undefined>(props.to);
  const [period, setPeriod] = useState(props.period || DatePeriod.YESTERDAY);
  const [fromInput, setFromInput] = useState(formattedDate(props.from));
  const [toInput, setToInput] = useState(formattedDate(props.to));
  const [isToInputFocus, setIsToInputFocus] = useState(false);

  const currentView = String(useCurrentView()).toLowerCase();

  const currentViewSLTracking: { [key: string]: string } = {
    sponsored_hotel: 'sponsored-analytics hotel view',
    sponsored_hotel_detail: 'sponsored-analytics hotel detail view ',
    sponsored_pos: 'sponsored-analytics POS view',
    sponsored_time: 'sponsored-analytics time period view',
  };

  const numberOfMonths = 2;
  const WEEKDAYS_SHORT: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  const datePickerRef: RefObject<HTMLElement> = createRef();

  useEffect(() => {
    if (period === DatePeriod.CUSTOM) {
      setFromInput(formattedDate(from as Date));
      setToInput(formattedDate(to as Date));
    }
  }, [period]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // @see: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
      const dropdown = document.querySelector('.js-calendar') as HTMLElement;
      const target = event.target as Node;
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(target) &&
        dropdown &&
        !dropdown.contains(target)
      ) {
        props.onClose();
      }
    };
    document.addEventListener(EVENT_MOUSEDOWN, handleClickOutside);
    return () => document.removeEventListener(EVENT_MOUSEDOWN, handleClickOutside);
  }, [datePickerRef, props, props.onClose]);

  const handleDayClick = (day: Date) => {
    const limitDate: Date = getLastSelectableDay();

    if (day > limitDate) {
      return;
    }

    if (isUndefinedOrNull(from)) {
      setFrom(day);
      setTo(undefined);
      setFromInput(formattedDate(day));
      setToInput('');
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(true);
    } else if (!isUndefinedOrNull(from) && isUndefinedOrNull(to)) {
      setTo(day);
      setFromInput(currValue => (from ? formattedDate(from) : currValue));
      setToInput(formattedDate(day));
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(false);
    } else if (from !== undefined && to !== undefined && !DayPicker.DateUtils.isSameDay(from, to)) {
      setFrom(day);
      setTo(day);
      setFromInput(formattedDate(day));
      setToInput(formattedDate(day));
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(true);
    } else if (from !== undefined && to !== undefined) {
      const range: { from: Date | null | undefined; to: Date | null | undefined } =
        DayPicker.DateUtils.addDayToRange(day, {
          from,
          to,
        });

      if (range.from === null || range.to === null) return;
      setFrom(range.from);
      setTo(range.to);
      setFromInput(range.from ? formattedDate(range.from) : '');
      setToInput(range.to ? formattedDate(range.to) : '');
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(false);
    }
  };

  const onChangeSelectHandler = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    const today = new Date();
    const yesterday = getLastXDays(1);
    switch (value) {
      case DatePeriod.CUSTOM:
        setFrom(undefined);
        setTo(undefined);
        setFromInput('');
        setToInput('');
        setPeriod(DatePeriod.CUSTOM);
        setIsToInputFocus(false);
        break;
      case DatePeriod.YESTERDAY:
        setFrom(yesterday);
        setTo(yesterday);
        setPeriod(DatePeriod.YESTERDAY);
        break;
      case DatePeriod.LAST_2:
        setFrom(getLastXDays(2, props.isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_2);
        break;
      case DatePeriod.LAST_3:
        setFrom(getLastXDays(3, props.isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_3);
        break;
      case DatePeriod.LAST_7:
        setFrom(getLastXDays(7, props.isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_7);
        break;
      case DatePeriod.LAST_30:
        setFrom(getLastXDays(30, props.isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_30);
        break;
      case DatePeriod.THIS_WEEK: {
        const startDate = startOfISOWeek(today);
        const endDate = isToday(startDate) ? startDate : getTo();
        setFrom(startDate);
        setTo(endDate);
        setPeriod(DatePeriod.THIS_WEEK);
        break;
      }
      case DatePeriod.LAST_WEEK: {
        const lastWeek = subWeeks(today, 1);
        setFrom(startOfISOWeek(lastWeek));
        setTo(endOfISOWeek(lastWeek));
        setPeriod(DatePeriod.LAST_WEEK);
        break;
      }
      case DatePeriod.THIS_MONTH: {
        const startDate = startOfMonth(today);
        const endDate = isToday(startDate) ? startDate : getTo();
        setFrom(startDate);
        setTo(endDate);
        setPeriod(DatePeriod.THIS_MONTH);
        break;
      }
      case DatePeriod.LAST_MONTH: {
        const lastMonth = subMonths(today, 1);
        setFrom(startOfMonth(lastMonth));
        setTo(lastDayOfMonth(lastMonth));
        setPeriod(DatePeriod.LAST_MONTH);
        break;
      }
      case DatePeriod.LAST_12_MONTHS: {
        setFrom(getLastXDays(DAYS_IN_LAST_12_MONTHS, props.isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_12_MONTHS);
        break;
      }
      case DatePeriod.THIS_YEAR: {
        const startDate = startOfYear(today);
        const endDate = isToday(startDate) ? startDate : getTo();
        setFrom(startDate);
        setTo(endDate);
        setPeriod(DatePeriod.THIS_YEAR);
        break;
      }
      case DatePeriod.LAST_YEAR: {
        const lastYear = subYears(today, 1);
        setFrom(startOfYear(lastYear));
        setTo(lastDayOfYear(lastYear));
        setPeriod(DatePeriod.LAST_YEAR);
        break;
      }
      default:
        break;
    }
  };

  function getTo(): Date {
    const today = new Date();
    const yesterday = getLastXDays(1);
    return props.isTodayChoosable ? today : yesterday;
  }

  function isFirstDayOfThisPeriod(): boolean {
    const today = startOfToday();
    return (
      (period === DatePeriod.THIS_WEEK && isMonday(today)) ||
      (period === DatePeriod.THIS_MONTH && isFirstDayOfMonth(today)) ||
      (period === DatePeriod.THIS_YEAR && isSameDay(today, startOfYear(today)))
    );
  }

  function getLastSelectableDay(): Date {
    const limitDate: Date =
      props.isTodayChoosable || isFirstDayOfThisPeriod() ? startOfToday() : getLastXDays(1);
    limitDate.setHours(0, 0, 0);
    return limitDate;
  }

  const onApply = () => {
    const lastSelectableDay = getLastSelectableDay();

    let toTmp = to;
    if (to !== undefined && to >= lastSelectableDay) {
      toTmp = lastSelectableDay;
      toTmp.setHours(to.getHours(), to.getMinutes(), to.getSeconds());
    }
    let fromTmp = from;
    if (
      to !== undefined &&
      ((from !== undefined && from >= lastSelectableDay) || from === undefined)
    ) {
      fromTmp = lastSelectableDay;
      fromTmp.setHours(to.getHours(), to.getMinutes(), to.getSeconds());
    }

    if (fromTmp !== undefined && toTmp !== undefined) {
      props.onUpdateRange(fromTmp, toTmp, period);
    }

    const currentPath = getCurrentPath().split('/')[0];
    const getCurrentViewTrack = () => {
      if (currentView.includes('sponsored')) return currentViewSLTracking[currentView];
      if (currentView.includes('hotel')) return currentView.replace(/_/g, ' ');
      return getCurrentPath().split('/')[0];
    };

    const currentViewTrack = getCurrentViewTrack();

    TrackerService.track(`${DATE_SELECTED_NAME} ${currentPath}`, {
      view: currentViewTrack,
      calendar: period === DatePeriod.CUSTOM,
      manual: false,
      predefined: period !== DatePeriod.CUSTOM,
      num_days: getDifferenceBetweenDays(from!, to!) + 1,
      predefined_name: period,
    } as DateSelected & { view: string });
  };

  const { onClose, isPeriodChoosable } = props;
  const modifiers = {
    start: from,
    end: to,
  };
  const isCustomPeriod =
    period === DatePeriod.CUSTOM || isPeriodChoosable === undefined || isPeriodChoosable === false;
  const dateFrom = parse(Date.parse(fromInput));
  const dateTo = parse(Date.parse(toInput));
  const isFromInputValid =
    isValid(dateFrom) && (isBefore(dateFrom, dateTo) || isSameDay(dateFrom, dateTo));
  const isToInputValid =
    isValid(dateTo) && (isAfter(dateTo, dateFrom) || isSameDay(dateTo, dateFrom));
  const selectedDays = (() => {
    if (from === undefined || to === undefined) {
      return from || to;
    }
    return [from, { from, to }];
  })();

  return (
    <section
      className="c-datePicker u-background--white u-box-shadow u-border-radius u-display--flex"
      ref={datePickerRef}
      style={{ left: 16, top: props.isModal ? 120 : 'auto' }}
      onClick={props.onClick}
    >
      <div className="u-padding--medium">
        <DayPicker
          className={cn('DatePicker Selectable u-background--white')}
          disabledDays={date => {
            const limitDate: Date = getLastSelectableDay();
            date.setHours(0, 0, 0);
            return date > limitDate;
          }}
          fixedWeeks={false}
          toMonth={getLastSelectableDay()}
          numberOfMonths={numberOfMonths}
          selectedDays={selectedDays}
          modifiers={modifiers}
          onDayClick={handleDayClick}
          showOutsideDays={true}
          firstDayOfWeek={1}
          weekdaysShort={WEEKDAYS_SHORT}
        />
      </div>
      <article className="u-padding--medium u-display--flex u-flex-direction--column u-flex-grow--1">
        <DatePickerLabel
          isPeriodChoosable={isPeriodChoosable ?? false}
          period={period}
          onChangeSelectHandler={onChangeSelectHandler}
        />

        <div className="u-display--flex">
          {!isCustomPeriod && (
            <DatePickerInputs
              isPeriodChoosable={isPeriodChoosable}
              from={from}
              setPeriod={setPeriod}
              to={to}
            />
          )}
          {isCustomPeriod && (
            <CustomPeriodInputs
              {...{
                isFromInputValid,
                fromInput,
                setFromInput,
                setFrom,
                isToInputValid,
                isToInputFocus,
                toInput,
                setToInput,
                setTo,
              }}
            />
          )}
        </div>
        <DatePickerCTAs
          onApply={onApply}
          isCustomPeriod={isCustomPeriod}
          isFromInputValid={isFromInputValid}
          isToInputValid={isToInputValid}
          onClose={onClose}
        />
      </article>
    </section>
  );
};

type DatePickerLabelProps = {
  isPeriodChoosable: boolean;
  period: DatePeriod;
  onChangeSelectHandler: (event: ChangeEvent<HTMLSelectElement>) => void;
};

const DatePickerLabel: FC<DatePickerLabelProps> = ({
  isPeriodChoosable,
  period,
  onChangeSelectHandler,
}) => {
  if (!isPeriodChoosable) return <label>{translate('history_calendar_time_date')}</label>;

  const DatePeriodOptions = Object.values(DatePeriod).map(datePeriod => (
    <option key={datePeriod} value={datePeriod}>
      {translate(datePeriod)}
    </option>
  ));

  return (
    <label>
      {translate('analytics_calendar_time_period')}
      <select
        data-qa="date-picker-select-period"
        value={period}
        onChange={onChangeSelectHandler}
        className="c-datePicker__select"
      >
        {DatePeriodOptions}
      </select>
    </label>
  );
};

type DatePickerInputsProps = {
  isPeriodChoosable: boolean;
  from: Date | undefined;
  setPeriod: (period: DatePeriod) => void;
  to: Date | undefined;
};
const DatePickerInputs: FC<DatePickerInputsProps> = ({
  isPeriodChoosable,
  from,
  setPeriod,
  to,
}) => (
  <>
    <input
      data-qa="date-picker-from"
      className={cn('c-datePicker__input u-margin-right--small', {
        'c-datePicker__input--disabled': isPeriodChoosable,
      })}
      value={from === undefined ? '' : formattedDate(from)}
      type="text"
      onChange={() => {
        /* This is only necessary to avoid complains about setting the value but not a onChange method */
      }}
      onFocus={() => setPeriod(DatePeriod.CUSTOM)}
    />
    <input
      data-qa="date-picker-to"
      className={cn('c-datePicker__input', {
        'c-datePicker__input--disabled': isPeriodChoosable,
      })}
      value={to === undefined ? '' : formattedDate(to)}
      type="text"
      onChange={() => {
        /* This is only necessary to avoid complains about setting the value but not a onChange method */
      }}
      onFocus={() => setPeriod(DatePeriod.CUSTOM)}
    />
  </>
);
type CustomPeriodInputsProps = {
  isFromInputValid: boolean;
  fromInput: string;
  setFromInput: (value: string) => void;
  setFrom: (value: Date | undefined) => void;
  isToInputValid: boolean;
  isToInputFocus: boolean;
  toInput: string;
  setToInput: (value: string) => void;
  setTo: (value: Date | undefined) => void;
};

const CustomPeriodInputs: FC<CustomPeriodInputsProps> = props => {
  const {
    isFromInputValid,
    fromInput,
    setFromInput,
    setFrom,
    isToInputValid,
    isToInputFocus,
    toInput,
    setToInput,
    setTo,
  } = props;

  return (
    <>
      <input
        className={cn('c-datePicker__input u-margin-right--small', {
          'c-datePicker__input--invalid': !isFromInputValid,
        })}
        value={fromInput}
        onChange={event => {
          setFromInput(event.target.value);
        }}
        onBlur={() => {
          if (isFromInputValid) {
            setFrom(new Date(fromInput));
            setFromInput(formattedDate(new Date(fromInput)));
          } else {
            setFrom(undefined);
          }
        }}
        type="text"
      />
      <input
        className={cn('c-datePicker__input', {
          'c-datePicker__input--invalid': !isToInputValid,
          'c-datePicker__input--is-focus': isToInputFocus,
        })}
        value={toInput}
        onChange={event => {
          setToInput(event.target.value);
        }}
        onBlur={() => {
          if (isToInputValid) {
            setTo(new Date(toInput));
            setToInput(formattedDate(new Date(toInput)));
          } else {
            setTo(undefined);
          }
        }}
        type="text"
      />
    </>
  );
};

type DatePickerCTAsProps = {
  onApply: () => void;
  isCustomPeriod: boolean;
  isFromInputValid: boolean;
  isToInputValid: boolean;
  onClose: () => void;
};

const DatePickerCTAs: FC<DatePickerCTAsProps> = ({
  onApply,
  isCustomPeriod,
  isFromInputValid,
  isToInputValid,
  onClose,
}) => {
  return (
    <div className={cn('u-margin-top--auto datePickerCTA')}>
      <Button
        data-qa="date-picker-apply"
        text={translate('analytics_home_apply')}
        onClick={onApply}
        disabled={isCustomPeriod ? !isFromInputValid || !isToInputValid : false}
      />
      <Button
        data-qa="date-picker-cancel"
        variant="flat"
        text={translate('analytics_home_cancel')}
        onClick={onClose}
      />
    </div>
  );
};
