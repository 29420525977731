import cn from 'classnames';
import { ComponentProps, FC, ChangeEvent, KeyboardEvent } from 'react';
import styles from './input.module.css';

type TextInputProps = {
  onSubmit?: () => void;
} & ComponentProps<'input'>;

export const TextInput: FC<TextInputProps> = ({
  value,
  onChange,
  className,
  autoFocus,
  onSubmit,
  ...props
}) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit && onSubmit();
    }
  };

  return (
    <input
      {...props}
      className={cn(styles.input, className)}
      onChange={onChange}
      type="text"
      value={value}
      autoFocus={autoFocus}
      onKeyUp={handleKeyPress}
    />
  );
};
