import { useEffect, useState, useCallback } from 'react';
import ApiAdminHelper from '../../../../helpers/api/admin/api-admin-helper';
import { PartnerAdministration } from '../../../../models';

export function usePartnersFor(memberId: number, memberUuid: string) {
  const [partners, setPartners] = useState<PartnerAdministration[]>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchPartners = useCallback(async () => {
    try {
      setLoading(true);
      setPartners(await ApiAdminHelper.getMemberRelatedPartners(memberId, memberUuid));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setPartners, memberId]);

  useEffect(() => {
    fetchPartners();
  }, [memberId, fetchPartners]);

  return {
    partners,
    isLoading,
    refetchPartners: fetchPartners,
  };
}
