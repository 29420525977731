import { FC } from 'react';
import { translate } from '../../../helpers/utils';
import { Switch } from '../../switch';
import { TooltipPosition, TooltipWrapper } from '../../tooltip-wrapper';
import styles from './compare-date-picker.module.css';

type CompareDatePickerSwitchProps = {
  isCompareEnabled: boolean;
  isComparingPendingValue: boolean;
  handleToggle: (isActive: boolean) => void;
};

export const CompareDatePickerSwitch: FC<CompareDatePickerSwitchProps> = ({
  isCompareEnabled,
  isComparingPendingValue,
  handleToggle,
}) => {
  return (
    <div className={styles.compareSwitchWrapper}>
      <h1>{translate('analytics_date_compare')}</h1>
      <TooltipWrapper
        position={TooltipPosition.TRAILING_CENTER}
        noTinyPaddingTop={true}
        message={translate('analytics_date_compare_disabled_tooltip')}
      >
        <Switch
          isDisabled={!isCompareEnabled}
          isActive={isComparingPendingValue}
          onChange={handleToggle}
          variant="circle"
          size="medium"
        />
      </TooltipWrapper>
    </div>
  );
};
