import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { NotificationLevel } from '../../constants';
import { showNotification } from '../../services/notification-service';
import trackerService from '../../services/tracker/tracker-service';
import { URL_PROPERTY_GROUP_ALIASES } from './use-property-group-aliases';
import { URL_CPA_GROUP_ALIASES } from './use-cpa-group-aliases';
import { Aliases, aliasesQueryKey } from './aliases';
import { translate } from '../../helpers/utils';

export type AliasVariant = 'PG' | 'CPA';

type AssignAliasRequest = {
  partnerId: number;
  id: number;
  alias: string | null;
  localeCode: string;
};

export function useUpdateAliasMutation(variant: AliasVariant) {
  const queryClient = useQueryClient();
  const urlPrefix = variant === 'PG' ? URL_PROPERTY_GROUP_ALIASES : URL_CPA_GROUP_ALIASES;

  async function assignAlias(request: AssignAliasRequest) {
    const body: any = {
      partnerId: request.partnerId,
      localeCode: request.localeCode,
      alias: request.alias,
    };
    if (variant === 'PG') body.propertyGroupId = request.id;
    if (variant === 'CPA') body.cpaGroupId = request.id;
    const result = await axios.post(`${urlPrefix}/assign`, body, {
      baseURL: process.env.REACT_APP_URL,
    });
    trackAliasEvent('assigned', request);
    return result;
  }

  async function deleteAlias(request: AssignAliasRequest) {
    const { id, partnerId, localeCode } = request;
    const result = axios.delete(`${urlPrefix}/${partnerId}/${id}/${localeCode}`, {
      baseURL: process.env.REACT_APP_URL,
    });
    trackAliasEvent('deleted', request);
    return result;
  }

  const trackAliasEvent = (event: 'assigned' | 'deleted', properties: AssignAliasRequest) =>
    trackerService.trackWithPrefix(`${variant} Alias`, event, properties);

  return useMutation(
    async (request: AssignAliasRequest) => {
      if (request.alias === null) {
        return await deleteAlias(request);
      }
      return await assignAlias(request);
    },
    {
      onMutate: async request => {
        const queryKey = aliasesQueryKey(variant, request.partnerId);
        await queryClient.cancelQueries({ queryKey });
        const previousAliases = queryClient.getQueryData<Aliases>(queryKey);
        queryClient.setQueryData(queryKey, (old: Aliases) => {
          const newAliases = old ? cloneDeep(old) : {};
          if (!newAliases[request.id]) {
            newAliases[request.id] = {};
          }
          if (request.alias) {
            newAliases[request.id][request.localeCode] = request.alias;
          } else {
            delete newAliases[request.id][request.localeCode];
          }
          return newAliases;
        });
        return { previousAliases, queryKey };
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      onError: (_, __, context) => {
        queryClient.setQueryData(context!.queryKey, context!.previousAliases);
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate('aliases_update_error'),
        });
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      onSettled: (_, __, ___, context) => {
        queryClient.invalidateQueries({ queryKey: context!.queryKey });
      },
    },
  );
}
