import { FC } from 'react';
import { InputField } from '../../../core-ui/components/input/input';
import { isNumericRangeFilter } from '../is-numeric-range-filter';
import { Filter } from '../types';
import { FilterFormFieldContext } from './filter-form-field-context';
import styles from './filter-form-field.module.css';
import { QualifierSelector } from './qualifier-selector';
import { useFilterFormField } from './use-filter-form-field';

export type FilterFormFieldProps = {
  filter: Filter;
  setFilter: (filter: Filter) => void;
  onSubmit: () => void;
};

export const FilterFormField: FC<FilterFormFieldProps> = ({ filter, setFilter, onSubmit }) => {
  return (
    <FilterFormFieldContext.Provider value={{ filter, setFilter, onSubmit }}>
      <section className={styles.filterFormField}>
        <div className={styles.nameAndQualifier}>
          <p className={styles.filterName}>{filter.name}</p>
          <QualifierSelector />
        </div>
        <div className={styles.inputs}>
          <FilterFormFieldInputs />
        </div>
      </section>
    </FilterFormFieldContext.Provider>
  );
};

const FilterFormFieldInputs: FC = () => {
  const { filter, setValue, onSubmit } = useFilterFormField();

  if (isNumericRangeFilter(filter)) {
    return (
      <>
        <InputField
          key="from"
          name="from"
          value={filter.value?.from || ''}
          type="numeric"
          onInputChange={({ target: { value } }) => setValue({ ...filter.value, from: value })}
          onSubmit={onSubmit}
          decoration={filter.decoration}
          autoFocus
        />
        <span className={styles.inputSeparator}>-</span>
        <InputField
          key="to"
          name="to"
          value={filter.value?.to || ''}
          type="numeric"
          onInputChange={({ target: { value } }) => setValue({ ...filter.value, to: value })}
          onSubmit={onSubmit}
          decoration={filter.decoration}
        />
      </>
    );
  }

  return (
    <InputField
      value={filter.value}
      onInputChange={({ target: { value } }) => setValue(value)}
      type={filter.type}
      decoration={filter.decoration}
      onSubmit={onSubmit}
      autoFocus
    />
  );
};
