import { getFormattedDates, getLastXDays } from '../../utils';
import { RequestHelper } from '../request-helper';
import {
  AGGREGATION_NAME,
  COLUMN_KEY_PARTNER_REFERENCE,
  FEATURE_FLAGS,
  VIEW_NAME,
  VIEW_TOTAL_TYPE,
} from '../../../constants';
import {
  Aggregation,
  ColumnDef,
  FilterColumn,
  FilterType,
  Hotel,
  Row,
  TotalTypes,
  View,
} from '../../../models';
import {
  Destination,
  DESTINATION_AGGREGATIONS,
  DESTINATION_COLUMNS,
  FetchTotalBody,
  PropertyPartnerName,
  TotalPayload,
  ViewPayload,
} from './types';
import { row2Hotel } from '../../transformers';
import { AxiosError } from 'axios';
import { DestinationDimensionName } from '../../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';
import { parseDestination } from '../../../views/custom-dashboard/destination-dimension-menu';
import subWeeks from 'date-fns/sub_weeks';

const URL_FETCH_COLUMNS_BASE = '/analytics/columns';
const URL_FETCH_ROWS_BASE = '/analytics/data';
const URL_FETCH_COUNT_BASE = '/analytics/data/count';
const URL_FETCH_FILTER_TYPES = '/analytics/columns/filter-types';
const URL_FETCH_REDESIGNED_FILTER_TYPES = '/analytics/columns/filter-types/redesigned';
export const TYPE_SUBTOTAL = 'SUBTOTAL';

class ApiAnalyticsHelper extends RequestHelper {
  private static instance: ApiAnalyticsHelper;

  public static getInstance() {
    if (!ApiAnalyticsHelper.instance) {
      ApiAnalyticsHelper.instance = new ApiAnalyticsHelper();
    }

    return ApiAnalyticsHelper.instance;
  }

  public async fetchViewColumns(view: View, partnersId: number[]): Promise<ColumnDef[]> {
    try {
      const response = await this.postRequest(`${URL_FETCH_COLUMNS_BASE}/${VIEW_NAME[view]}`, {
        partnerId: partnersId,
      });
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'analytics_view_columns_error');
      return [];
    }
  }

  public async fetchFilterTypes(): Promise<FilterType[]> {
    const fetchFilterTypeUrl = FEATURE_FLAGS.enableFilterRedesign2
      ? URL_FETCH_REDESIGNED_FILTER_TYPES
      : URL_FETCH_FILTER_TYPES;

    try {
      const response = await this.getRequest(fetchFilterTypeUrl);
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'analytics_filter_types_error');
      return [];
    }
  }

  public async fetchRowsByView(
    view: View,
    aggregations: Aggregation[],
    payload: ViewPayload,
  ): Promise<Row[]> {
    const { partnersId, from, to, filters, sort, pagination, locales } = payload;

    const body = {
      condition: filters,
      sort,
      pagination: {
        page: pagination.numPage,
        size: pagination.numItemsPerPage,
      },
      partnerId: partnersId,
      aggregation: aggregations.map(aggregation => AGGREGATION_NAME[aggregation]),
      localeCodes: locales,
      date: getFormattedDates(from, to),
    };
    try {
      const response = await this.postRequest(`${URL_FETCH_ROWS_BASE}/${VIEW_NAME[view]}`, body);
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'analytics_rows_by_view_error');
      return [];
    }
  }

  public async fetchSubTotalByView(
    view: View,
    aggregations: Aggregation[],
    payload: ViewPayload,
  ): Promise<Row> {
    const { partnersId, from, to, filters, sort, pagination, locales } = payload;

    const body = {
      condition: filters,
      sort,
      pagination: {
        page: pagination.numPage,
        size: pagination.numItemsPerPage,
      },
      partnerId: partnersId,
      aggregation: aggregations.map(aggregation => AGGREGATION_NAME[aggregation]),
      localeCodes: locales,
      date: getFormattedDates(from, to),
      type: TYPE_SUBTOTAL,
    };
    try {
      const response = await this.postRequest(`${URL_FETCH_ROWS_BASE}/${VIEW_NAME[view]}`, body);
      return response.data[0];
    } catch (e) {
      this.handleError(e as AxiosError, 'analytics_subtotal_by_view_error');
      return {} as Row;
    }
  }

  public async fetchTotalByView(view: View, payload: TotalPayload): Promise<Row> {
    const { partnersId, from, to, locales, defaultFilters } = payload;

    const body: FetchTotalBody = {
      partnerId: partnersId,
      aggregation: AGGREGATION_NAME[Aggregation.TOTAL],
      localeCodes: locales,
      date: getFormattedDates(from, to),
      type: TotalTypes[VIEW_TOTAL_TYPE[view]],
    };

    ApiAnalyticsHelper.defineFetchTotalBodyConditions(body, defaultFilters);

    try {
      const response = await this.postRequest(`${URL_FETCH_ROWS_BASE}/${VIEW_NAME[view]}`, body);
      return response.data[0];
    } catch (e) {
      this.handleError(e as AxiosError, 'analytics_total_by_view_error');
      return {} as Row;
    }
  }

  public async fetchTotalCountByView(
    view: View,
    aggregations: Aggregation[],
    payload: ViewPayload,
  ): Promise<number> {
    const { partnersId, from, to, locales, filters } = payload;
    const body = {
      partnerId: partnersId,
      aggregation: aggregations.map(aggregation => AGGREGATION_NAME[aggregation]),
      condition: filters,
      localeCodes: locales,
      date: getFormattedDates(from, to),
    };
    try {
      const response = await this.postRequest(`${URL_FETCH_COUNT_BASE}/${VIEW_NAME[view]}`, body);
      return response.data.resultCount;
    } catch (e) {
      this.handleError(e as AxiosError, 'analytics_total_count_by_view_error');
      return 0;
    }
  }

  public async fetchHotel(
    partnersId: number[],
    partnerRef: string,
    locales: string[],
  ): Promise<Hotel | undefined> {
    const body = {
      condition: [{ columnName: COLUMN_KEY_PARTNER_REFERENCE, type: 'EQUALS', value: partnerRef }],
      partnerId: partnersId,
      aggregation: AGGREGATION_NAME[Aggregation.BY_PARTNER_REF],
      localeCodes: locales,
    };

    try {
      const response = await this.postRequest(
        `${URL_FETCH_ROWS_BASE}/${VIEW_NAME[View.PROPERTY_STATIC_DETAILS]}`,
        body,
      );

      if (response.data.length === 0) {
        return undefined;
      }

      return row2Hotel(response.data[0]);
    } catch (e) {
      this.handleError(e as AxiosError, 'analytics_hotel_error');
      return;
    }
  }

  private static defineFetchTotalBodyConditions(
    body: FetchTotalBody,
    defaultFilters: FilterColumn[],
  ) {
    switch (body.type) {
      case TotalTypes[TotalTypes.TOTAL_WITH_FILTERS]:
        body.condition = defaultFilters;

        break;
      default:
        body.condition = undefined;
    }
  }

  public async fetchPropertiesByNameOrRef(
    partnersId: number[],
    inputValue: string,
  ): Promise<PropertyPartnerName[]> {
    const inputCondition = [
      {
        columnName: 'name2',
        columnLabel: '',
        type: 'CONTAINS',
        value: inputValue,
      },
      {
        columnName: 'pid',
        columnLabel: '',
        type: 'CONTAINS',
        value: inputValue,
      },
    ];

    const body = {
      condition: [
        {
          columnName: '',
          columnLabel: '',
          type: 'OR',
          value: '',
          condition: inputCondition,
        },
      ],
      partnerId: partnersId,
      aggregation: AGGREGATION_NAME[Aggregation.BY_ITEM_ID],
      pagination: { page: 1, size: 10 },
      sort: [{ columnName: 'pid', direction: 'ASC' }],
    };

    const response = await this.postRequest(`${URL_FETCH_ROWS_BASE}/property`, body);
    try {
      return response.data.map(this.data2PropertyInfo);
    } catch (e) {
      this.handleError(e as AxiosError, 'filters_dropdown_error');
      return [];
    }
  }

  public async fetchPropertiesById(
    partnersId: number[],
    itemIds: string[],
  ): Promise<PropertyPartnerName[]> {
    if (!itemIds.length) return [];
    const body = {
      condition: [
        {
          columnName: 'id',
          columnLabel: '',
          type: 'IN',
          value: itemIds.join(),
          condition: [],
        },
      ],
      partnerId: partnersId,
      aggregation: AGGREGATION_NAME[Aggregation.BY_ITEM_ID],
      pagination: { page: 1, size: itemIds.length },
      sort: [{ columnName: 'id', direction: 'ASC' }],
    };

    const response = await this.postRequest(`${URL_FETCH_ROWS_BASE}/property`, body);
    try {
      return response.data.map(this.data2PropertyInfo);
    } catch (e) {
      this.handleError(e as AxiosError, 'filters_dropdown_error');
      return [];
    }
  }

  private data2PropertyInfo = (item: { id: number; pid: string; name2: string }) => {
    return {
      partnerRef: item.pid,
      name: item.name2,
      itemId: item.id,
    };
  };

  public async fetchTopCities(
    partnersId: number[],
    localeCodes: readonly string[],
  ): Promise<Destination[]> {
    const aggregation = AGGREGATION_NAME[Aggregation.CHART_CITY];
    const oneWeekAgo = subWeeks(new Date(), 1);
    const yesterday = getLastXDays(1);
    try {
      const body = {
        partnerId: partnersId,
        aggregation,
        date: getFormattedDates(oneWeekAgo, yesterday),
        localeCodes,
        pagination: { page: 1, size: 10 },
        shownColumns: 'city',
        sort: [
          {
            columnName: 'clicks2',
            direction: 'DESC',
          },
        ],
      };
      const response = await this.postRequest(
        `${URL_FETCH_ROWS_BASE}/property-location-view-cb-with-sl`,
        body,
      );
      return (response.data as { city: string }[]).map(({ city }) => city).map(parseDestination);
    } catch (e) {
      const error = e as Error;
      this.handleError(e as AxiosError, error.message);
      return [];
    }
  }
  public async fetchDestinations(
    partnersId: number[],
    inputValue: string,
    destinationType: DestinationDimensionName,
  ): Promise<Destination[]> {
    const condition = [destinationFilter(inputValue)];
    try {
      const aggregations = DESTINATION_AGGREGATIONS[destinationType];
      const shownColumns = DESTINATION_COLUMNS[destinationType];
      const body = {
        partnerId: partnersId,
        condition,
        aggregation: aggregations.map(aggregation => AGGREGATION_NAME[aggregation]),
        pagination: { page: 1, size: 100 },
        shownColumns,
        sort: shownColumns.map(columnName => ({ columnName, direction: 'ASC' })),
      };
      const response = await this.postRequest(`${URL_FETCH_ROWS_BASE}/property-location`, body);
      return response.data as Destination[];
    } catch (e) {
      const error = e as Error;
      this.handleError(e as AxiosError, error.message);
      return [];
    }
  }
}

function destinationFilter(inputValue: string): Partial<FilterColumn> {
  return {
    columnName: 'destination',
    columnLabel: '',
    type: 'CONTAINS',
    value: inputValue,
  };
}

export default ApiAnalyticsHelper.getInstance();
