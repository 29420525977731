import { View, ColumnDef } from '../../models';
import { DropdownOption } from '../../components/multi-select-dropdown';
import { Column } from '../../components/grid';
import {
  getHiddenColumnsByViewFromLocalStorage,
  saveHiddenColumnsByViewToLocalStorage,
} from '../../helpers/local-storage-helper';
import { useEffect, useState } from 'react';

export const useShownMetricsSelector = (
  view: View,
  numOfStickyColumns: number,
  columns: ColumnDef[],
  formattedColumns: Column[],
) => {
  const [shownMetrics, setShownMetrics] = useState<DropdownOption[]>([]);
  const [columnTitlesByName] = useState(new Map<string, string>());

  useEffect(() => {
    if (shownMetrics.length === 0) return;
    const shownMetricNames = shownMetrics.map(column => column.value);
    const hiddenMetricNames = getMetricColumns()
      .map(column => column.key)
      .filter(column => !shownMetricNames.includes(column));
    saveHiddenColumnsByViewToLocalStorage(view, hiddenMetricNames);
  }, [shownMetrics]);

  useEffect(() => {
    const hiddenMetricNames = getHiddenColumnsByViewFromLocalStorage(view);
    const shownMetrics = getMetricColumns()
      .filter(column => !hiddenMetricNames.includes(column.key))
      .map(columnToOption);
    setShownMetrics(shownMetrics);
  }, [columns]);

  useEffect(() => {
    formattedColumns.forEach(formattedColumn => {
      const columnName = formattedColumn.key;
      const columnTitle = columns.find(column => column.key === columnName)?.name ?? columnName;
      columnTitlesByName.set(columnName, columnTitle);
    });
  }, [formattedColumns]);

  const getMetricColumns = (): Column[] => formattedColumns.slice(numOfStickyColumns);

  const getVisibleFormattedColumns = (): Column[] => {
    const stickyColumns = formattedColumns.slice(0, numOfStickyColumns);
    const shownMetricNames = shownMetrics.map(column => column.value);
    const visibleMetricColumns = getMetricColumns().filter(column =>
      shownMetricNames.includes(column.key),
    );
    return stickyColumns.concat(visibleMetricColumns);
  };

  const columnToOption = (column: Column): DropdownOption => ({
    key: columnTitlesByName.get(column.key) ?? column.key,
    value: column.key,
  });

  return {
    shownMetrics: shownMetrics,
    setShownMetrics: setShownMetrics,
    availableMetrics: getMetricColumns().map(columnToOption),
    visibleFormattedColumns: getVisibleFormattedColumns(),
  };
};
