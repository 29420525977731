import cn from 'classnames';
import { isAfter, isBefore, isValid } from 'date-fns';
import { createRef, CSSProperties, FC, RefObject, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import { DATE_PICKER_COMPARE_TOGGLE } from '../../constants';
import { formattedDate, WEEKDAYS_SHORT } from '../../helpers/utils';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { IS_DATE_COMPARISON_ENABLED, useSortParams } from '../../hooks/use-sort-params';
import { DatePeriod, DateRangePeriod } from '../../models';
import TrackerService from '../../services/tracker/tracker-service';
import { CompareDatePickerFooter } from './compare-date-picker/compare-date-picker-footer';
import { CompareDatePickerPeriodsContainer } from './compare-date-picker/compare-date-picker-periods-container';
import { CompareDatePickerSelector } from './compare-date-picker/compare-date-picker-selector';
import { CompareDatePickerSwitch } from './compare-date-picker/compare-date-picker-switch';
import styles from './compare-date-picker/compare-date-picker.module.css';
import './date-picker-with-compare.css';
import { DatePickerWithCompareProps } from './date-picker.types';
import { useDatePicker } from './use-date-picker';
import { useDatePickerStyle } from './use-date-picker-style';
import { useDatePickerWithCompare } from './use-date-picker-with-compare';

export const DatePickerWithCompare: FC<DatePickerWithCompareProps> = ({
  onClose,
  isPeriodChoosable,
  isTodayChoosable,
  isComparingPreselected = false,
}) => {
  const datePickerRef: RefObject<HTMLElement> = createRef();
  const dropdown = useRef(document.querySelector('.js-calendar') as HTMLElement);
  const currentView = String(useCurrentView()).toLowerCase().replace(/_/g, ' ');
  useOnClickOutside([datePickerRef, dropdown], onClose);
  const { isDateComparisonActive, removeSortParams } = useSortParams([IS_DATE_COMPARISON_ENABLED]);

  const {
    from,
    to,
    period,
    fromInput,
    toInput,
    isToInputFocus,
    isFromInputValid,
    isToInputValid,
    modifiers,
    isCustomPeriod,
    isFromInputFocus,
    onApply,
    onChangeSelectHandler,
    handleDayClick,
    setPeriod,
    getLastSelectableDay,
    onFromInputBlur,
    onToInputBlur,
    onChangeFromInput,
    onChangeToInput,
    onFromInputFocus,
    onToInputFocus,
  } = useDatePicker({ onClose, isPeriodChoosable, isTodayChoosable });

  const [isComparingPendingValue, setIsComparingPendingValue] = useState(isComparingPreselected);

  const {
    compareColors,
    isCompareEnabled,
    compareDateRangePeriods,
    selectedDatePeriodIndex,
    selectedDatePeriod,
    compareModifiers,
    currentSelectionColor,
    setCompareValue,
    setSelectedDatePeriodIndex,
    setIsComparing,
    handleCompareDayClick,
    addNewCompareDateRangePeriod,
    removeCompareDateRangePeriod,
    setCompareDateRangePeriods,
    onCompare,
  } = useDatePickerWithCompare({
    from,
    to,
    period,
    setPeriod,
    onClose,
    getLastSelectableDay,
    isComparingPendingValue,
    setIsComparingPendingValue,
  });

  const { setOtherDatePeriodsStyle } = useDatePickerStyle({
    isComparing: isComparingPendingValue,
    compareDateRangePeriods,
    compareModifiers,
    selectedDatePeriodIndex,
  });

  const [hoverValue, setHoverValue] = useState<string | undefined>(undefined);

  const handleMouseEnter = (date: Date) => {
    const lastSelectableDay = getLastSelectableDay();
    if (isBefore(date, lastSelectableDay)) {
      setHoverValue(formattedDate(date));
    }
  };

  const handleMouseLeave = () => setHoverValue(undefined);

  const handleToggle = (isActive: boolean) => {
    if (!isComparingPendingValue) {
      TrackerService.track(DATE_PICKER_COMPARE_TOGGLE, {
        view: currentView,
      });
    }
    setIsComparingPendingValue(isActive);
    if (!isActive && isDateComparisonActive) removeSortParams([IS_DATE_COMPARISON_ENABLED]);
  };

  const areDatesValid = compareDateRangePeriods.every(
    dateRangePeriod =>
      dateRangePeriod.from &&
      isValid(dateRangePeriod.from) &&
      dateRangePeriod.to &&
      isValid(dateRangePeriod.to) &&
      !isAfter(dateRangePeriod.from, dateRangePeriod.to),
  );
  const isCompareInputInvalid = isCustomPeriod
    ? !isFromInputValid || !isToInputValid || !compareDateRangePeriods.length || !areDatesValid
    : false;

  const isApplyButtonDisabled = isCustomPeriod ? !isFromInputValid || !isToInputValid : false;

  return (
    <section
      className="c-datePicker u-background--white u-box-shadow u-border-radius u-display--flex u-flex-direction--column"
      ref={datePickerRef}
      style={
        {
          left: 16,
          '--selected-color': currentSelectionColor?.primary,
          '--selected-color-light': currentSelectionColor?.secondary,
        } as CSSProperties
      }
    >
      <div className="u-display--flex">
        <DayPicker
          className={cn(
            { Selectable: !isComparingPendingValue },
            'DatePickerWithCompare u-background--white',
          )}
          disabledDays={date => {
            const limitDate: Date = getLastSelectableDay();
            date.setHours(0, 0, 0);
            return date > limitDate;
          }}
          fixedWeeks={false}
          toMonth={getLastSelectableDay()}
          numberOfMonths={2}
          selectedDays={selectedDatePeriod as DateRangePeriod}
          modifiers={isComparingPendingValue ? compareModifiers : modifiers}
          modifiersStyles={{
            selectedDatePeriod: {
              borderRadius: '0px',
            },
            otherDatePeriods: {
              backgroundColor: '#EBECED',
            },
          }}
          onDayClick={selectedDatePeriodIndex !== null ? handleCompareDayClick : handleDayClick}
          onDayMouseEnter={handleMouseEnter}
          onDayMouseLeave={handleMouseLeave}
          showOutsideDays={true}
          firstDayOfWeek={1}
          weekdaysShort={WEEKDAYS_SHORT}
          onMonthChange={() => setOtherDatePeriodsStyle()}
        />
        <article className={styles.compareDatePickerSidebar}>
          <div className={styles.periodSelectorWrapper}>
            <CompareDatePickerSelector
              isPeriodSelectable={isPeriodChoosable}
              period={period}
              onChange={onChangeSelectHandler}
            />
            {isCustomPeriod ? (
              <>
                <input
                  className={cn(styles.datePickerInput, {
                    [styles.invalid]: !isFromInputValid,
                  })}
                  value={
                    selectedDatePeriodIndex == null && isFromInputFocus && hoverValue
                      ? hoverValue
                      : fromInput
                  }
                  onChange={onChangeFromInput}
                  onClick={() => setSelectedDatePeriodIndex(null)}
                  onBlur={onFromInputBlur}
                  onFocus={onFromInputFocus}
                  type="text"
                />
                <input
                  className={cn(styles.datePickerInput, {
                    [styles.invalid]: !isFromInputValid,
                  })}
                  value={
                    selectedDatePeriodIndex == null && isToInputFocus && hoverValue
                      ? hoverValue
                      : toInput
                  }
                  onChange={onChangeToInput}
                  onClick={() => setSelectedDatePeriodIndex(null)}
                  onFocus={onToInputFocus}
                  onBlur={onToInputBlur}
                  type="text"
                />
              </>
            ) : (
              <>
                <input
                  data-qa="date-picker-from"
                  className={cn(styles.datePickerInput, { [styles.disabled]: isPeriodChoosable })}
                  value={from === undefined ? '' : formattedDate(from)}
                  type="text"
                  onFocus={() => setPeriod(DatePeriod.CUSTOM)}
                  onChange={() => {}}
                />
                <input
                  data-qa="date-picker-to"
                  className={cn(styles.datePickerInput, { [styles.disabled]: isPeriodChoosable })}
                  value={to === undefined ? '' : formattedDate(to)}
                  type="text"
                  onFocus={() => setPeriod(DatePeriod.CUSTOM)}
                  onChange={() => {}}
                />
              </>
            )}
          </div>
          <CompareDatePickerSwitch
            isCompareEnabled={isCompareEnabled}
            isComparingPendingValue={isComparingPendingValue}
            handleToggle={handleToggle}
          />
          <CompareDatePickerPeriodsContainer
            {...{
              dateRangePeriod: { from, to, period },
              isComparingPendingValue,
              compareDateRangePeriods,
              compareColors,
              hoverValue,
              selectedDatePeriodIndex,
              setSelectedDatePeriodIndex,
              setCompareValue,
              addNewCompareDateRangePeriod,
              removeCompareDateRangePeriod,
              setCompareDateRangePeriods,
            }}
          />
        </article>
      </div>
      <CompareDatePickerFooter
        isComparingPendingValue={isComparingPendingValue}
        isCompareButtonDisabled={isCompareInputInvalid}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClose={onClose}
        onCompare={onCompare}
        onApply={onApply}
        setIsComparing={setIsComparing}
      />
    </section>
  );
};
