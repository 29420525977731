import cn from 'classnames';
import { ComponentProps, FC, ChangeEvent, KeyboardEvent } from 'react';
import styles from './input.module.css';
import { NumberInput } from './number-input';
import { TextInput } from './text-input';

export type InputProps = {
  type?: 'string' | 'numeric';
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
  decoration?: string;
} & ComponentProps<'input'>;

export const InputField: FC<InputProps> = ({
  type = 'string',
  value,
  onInputChange,
  className,
  decoration,
  placeholder,
  autoFocus,
  onSubmit,
  ...props
}) => {
  if (type === 'numeric')
    return (
      <NumberInput
        className={cn(styles.input, className)}
        value={value as number}
        onInputChange={onInputChange}
        decoration={decoration}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onSubmit={onSubmit}
      />
    );

  return (
    <TextInput
      {...props}
      className={cn(styles.input, className)}
      onChange={onInputChange}
      type="text"
      value={value}
      onSubmit={onSubmit}
      autoFocus={autoFocus}
    />
  );
};
