/* eslint-disable @typescript-eslint/naming-convention */
export const en = {
  available_soon: 'Available soon',
  csv_validator_modal_title: "We've identified some issues",
  csv_validator_modal_description:
    'The csv file you want to upload contains incomplete or incorrect inputs. Please download the summary file to identify the issues.',
  csv_validator_upload_anyway: 'Upload anyway',
  csv_validator_review_issues: 'Review issues',
  csv_validator_total_rows: 'total rows',
  csv_validator_errors: 'errors',
  csv_validator_warnings: 'warnings',
  csv_validator_validating_message: 'Validating csv file...',
  convert_base_bid_file_modal_title: 'You tried uploading an old file format',
  convert_base_bid_file_modal_description:
    'This file format is no longer supported for base bids, but you can convert it to the new format.',
  convert_base_bid_file_modal_old_format_example: 'Old format example:',
  convert_base_bid_file_modal_new_format_example: 'New format example:',
  convert_base_bid_file_modal_download_converted_file: 'Download converted file',
  convert_base_bid_file_modal_failed_to_download_converted_file:
    'Failed to download the converted file',
  add_dashboard_dropdown__title: 'Add',
  'add_widget_item_menu__global-inventory-description':
    'Keep track of which properties are active and matched',
  'add_widget_item_menu__global-inventory-title': 'Global Inventory Status',
  'add_widget_item_menu__mblu-description':
    'Monitor and compare your Beat, Meet, Lose, and Unavailability shares',
  'add_widget_item_menu__mblu-title': 'Beat-Meet-Lose-Unavailability',
  'add_widget_item_menu__pos-overview-description':
    'Monitor your Rate Accuracy Score and CPC Impact',
  'add_widget_item_menu__pos-overview-title': 'Point of Sale Overview',
  'add_widget_item_menu__timeline-description':
    'Monitor and compare metrics of your choice over time',
  'add_widget_item_menu__timeline-title': 'Timeline',
  'add_widget_item_menu__yesterday-overview-description':
    'A quick glance at your top metrics from yesterday',
  'add_widget_item_menu__yesterday-overview-title': "Yesterday's Overview",
  admin_member_search_title: 'Search',
  admin_members: 'Members',
  admin_members_add_partner_button: 'Assign partner',
  admin_members_basic_information_title: 'Member information',
  admin_members_email: 'Email',
  admin_members_error_assign_partners:
    'Oops! An error occurred and we could not assign the chosen partners to the selected member. Please reload the page or try again later.',
  admin_members_error_get_member_partners:
    'Oops! An error occurred and we could not retrieve the partners associated with the selected member. Please reload the page or try again later.',
  admin_members_error_get_partners:
    'Oops! An error occurred and we could not retrieve the list of partners. Please reload the page or try again later.',
  admin_members_error_set_partner_level:
    'Oops! An error occurred and we were unable to update the partner level for the selected member. Please reload the page or try again later.',
  admin_members_error_un_assign_partners:
    "Oops! An error occurred and we couldn't unassign the chosen partner to the selected member. Please reload the page or try again later.",
  admin_members_error_user_does_not_exist:
    'No results found. Please check the email address and ensure that it is associated with an account.',
  admin_members_full_name: 'Full Name',
  admin_members_level: 'Level',
  admin_members_member_id: 'Member ID',
  admin_members_partner_level: 'Partner Level',
  admin_members_related_partners_title: "Member's assigned partners",
  admin_members_search_placeholder: 'Search for an email address',
  admin_partners: 'Partners',
  admin_partners_not_implemented_yet:
    "This function is still cooking in the oven. We're working on it and hope to have it ready for you soon. ",
  analytic_tooltip_bid_cpc:
    'The Bid CPC is the CPC applied to the hotel. It will always show the current bid, regardless of the date range.',
  analytic_tooltip_sl_bid_history: 'History of SL bids for this item.',
  analytic_tooltip_bm_status:
    'The Status describes the current state of any changes to Bid Modifiers. No change: Displayed Bid Modifier is live and no change has been made. Pending: Displayed Bid Modifier has been changed and is waiting to be processed. Processing: Change to the Bid Modifier is being processed to go live. ',
  analytic_tooltip_magnitude:
    "Magnitude indicates how severe an observed rate discrepancy is. P1 = Advertiser's landing page; P2 = Next page in advertiser's booking funnel. Can be the item, cookies, booking, error page; P3 = Advertiser's booking or error page.",
  analytic_tooltip_na_discrepancies:
    "NAs indicate how often we are seeing a rate being unavailable on the weg page, e.g. if the room or hotel is booked out. P1 = Advertiser's landing page; P2 = Next page in advertiser's booking funnel. Can be the item, cookies, booking, error page; P3 = Advertiser's booking or error page.",
  analytic_tooltip_opportunity_cpc:
    'Opportunity CPC indicates the lowest possible CPC required to achieve an increase in Used Potential (Clicks/Max Potential). It considers both your rate and CPC level, as well as those of your competitors.',
  analytic_tooltip_price_difference:
    "Frequency indicates how often rate discrepancies are observed. P1 = Advertiser's landing page; P2 = Next page in advertiser's booking funnel. Can be the item, cookies, booking, error page; P3 = Advertiser's booking or error page.",
  analytic_tooltip_status:
    'The Status describes the current state of any changes to CPC. No change: displayed Bid CPC is live/no change has been made. Pending: displayed Bid CPC has been change/waiting to go live. Processing: change to the Bid CPC is being processed to go live. ',
  analytic_tooltip_trivago_url: 'This is a direct link to the property on trivago.',
  analytics_avg_dd_modifier_tooltip:
    'This column indicates the average Default Date Bid Modifier that was applied for the selected breakout.',
  analytics_avg_gs_modifier_tooltip:
    'Indicates the average Group Size Modifier that was applied for the selected time period.',
  analytics_avg_los_modifier_tooltip:
    'Indicates the average Length of Stay Bid Modifier that was applied for the selected time period.',
  analytics_avg_ttt_modifier_tooltip:
    'Indicates the average Time to Travel Bid Modifier that was applied for the selected time period.',
  analytics_bidding_modifiers: 'Bid Modifiers',
  analytics_bidding_type_dropdown_information_tooltip:
    'Bidding Type selection allows you to filter your results by the type of bidding used. If you select multiple bidding types for multiple points of sale in "Hotels" view, the data will be aggregated.',
  analytics_bidding_type_dropdown_multiple_selected: 'bidding types selected',
  analytics_bidding_type_dropdown_single_selected: 'Bidding Type',
  analytics_bidding_type_onboarding_text:
    'With the "Bidding Type" tab, you can analyze the bidding types\' performance in more detail',
  analytics_bidding_types_dropdown_selection_error: 'You must select at least one Bidding Type',
  analytics_calendar_custom_period: 'Custom period',
  analytics_calendar_last_2_days: 'Last 2 days',
  analytics_calendar_last_3_days: 'Last 3 days',
  analytics_calendar_last_7_days: 'Last 7 days',
  analytics_calendar_last_30_days: 'Last 30 days',
  analytics_calendar_last_month: 'Last month',
  analytics_calendar_last_12_months: 'Last 12 months',
  analytics_calendar_last_week: 'Last week',
  analytics_calendar_last_year: 'Last year',
  analytics_calendar_this_month: 'This month',
  analytics_calendar_this_week: 'This week',
  analytics_calendar_this_year: 'This year',
  analytics_calendar_time_period: 'Time period',
  analytics_calendar_yesterday: 'Yesterday',
  analytics_chart_builder_combined_modifiers_title: 'Combined Modifiers',
  analytics_chart_builder_combined_modifiers_add: 'Add modifiers',
  analytics_chart_builder_combined_modifiers_error_max_length: '$$ selected (max $$)',
  analytics_chart_builder_combined_modifiers_all_breakouts: 'All breakouts',
  analytics_chart_builder_clear_all: 'Clear all',
  analytics_chart_builder_chart_type: 'Chart type',
  analytics_chart_builder_chart_variant_line: 'Line',
  analytics_chart_builder_dimension_combinedModifiers_title: 'Combined Modifiers',
  analytics_chart_builder_dimension: 'dimension',
  analytics_chart_builder_dimension_biddingType_title: 'Bidding Type',
  analytics_chart_builder_dimension_bm_dd_breakout_0: 'False',
  analytics_chart_builder_dimension_bm_dd_breakout_1: 'True',
  analytics_chart_builder_dimension_bm_dd_search_placeholder: 'Search default date',
  analytics_chart_builder_dimension_bm_dd_title: 'Default Date',
  analytics_chart_builder_dimension_bm_gs_breakout_1: '1 person (1)',
  analytics_chart_builder_dimension_bm_gs_breakout_2: '2 people (2)',
  analytics_chart_builder_dimension_bm_gs_breakout_3: '3-5 people (3)',
  analytics_chart_builder_dimension_bm_gs_breakout_6: '6+ people (6)',
  analytics_chart_builder_dimension_bm_gs_search_placeholder: 'Search group size',
  analytics_chart_builder_dimension_bm_gs_title: 'Group Size',
  analytics_chart_builder_dimension_bm_los_breakout_1: '1 days (1)',
  analytics_chart_builder_dimension_bm_los_breakout_2: '2 days (2)',
  analytics_chart_builder_dimension_bm_los_breakout_3: '3 days (3)',
  analytics_chart_builder_dimension_bm_los_breakout_4: '4-6 days (4)',
  analytics_chart_builder_dimension_bm_los_breakout_7: '7 days (7)',
  analytics_chart_builder_dimension_bm_los_breakout_8: '8-12 days (8)',
  analytics_chart_builder_dimension_bm_los_breakout_13: '13-14 days (13)',
  analytics_chart_builder_dimension_bm_los_breakout_15: '15+ days (15)',
  analytics_chart_builder_dimension_bm_los_search_placeholder: 'Search length of stay',
  analytics_chart_builder_dimension_bm_los_title: 'Length of Stay',
  analytics_chart_builder_dimension_bm_title: 'Bid Modifier',
  analytics_chart_builder_dimension_bm_ttt_breakout_0: '0 days (0)',
  analytics_chart_builder_dimension_bm_ttt_breakout_1: '1 day (1)',
  analytics_chart_builder_dimension_bm_ttt_breakout_2: '2-4 days (2)',
  analytics_chart_builder_dimension_bm_ttt_breakout_5: '5-7 days (5)',
  analytics_chart_builder_dimension_bm_ttt_breakout_8: '8-14 days (8)',
  analytics_chart_builder_dimension_bm_ttt_breakout_15: '15-28 days (15)',
  analytics_chart_builder_dimension_bm_ttt_breakout_29: '29-40 days (29)',
  analytics_chart_builder_dimension_bm_ttt_breakout_41: '41-60 days (41)',
  analytics_chart_builder_dimension_bm_ttt_breakout_61: '61-90 days (61)',
  analytics_chart_builder_dimension_bm_ttt_breakout_91: '91-180 days (91)',
  analytics_chart_builder_dimension_bm_ttt_breakout_181: '181-300 days (181)',
  analytics_chart_builder_dimension_bm_ttt_breakout_301: '301+ days (301)',
  analytics_chart_builder_dimension_bm_ttt_search_placeholder: 'Search time to travel',
  analytics_chart_builder_dimension_bm_ttt_title: 'Time to Travel',
  analytics_chart_builder_dimension_brand_title: 'Brand',
  analytics_chart_builder_dimension_country_title: 'Country',
  analytics_chart_builder_dimension_destination_city_title: 'Destination · City',
  analytics_chart_builder_dimension_destination_country_title: 'Destination · Country',
  analytics_chart_builder_dimension_destination_region_title: 'Destination · Region',
  analytics_chart_builder_dimension_destination_title: 'Destination',
  analytics_chart_builder_dimension_timePeriod_title: 'Time Period',
  analytics_chart_builder_dimension_menu_tooltip:
    'Dimension can be selected when only one metric is chosen',
  analytics_chart_builder_dimension_posCountry_title: 'Countries',
  analytics_chart_builder_dimension_posRegion_title: 'Regions',
  analytics_chart_builder_dimension_propertyGroups_title: 'Property Groups',
  analytics_chart_builder_dimension_propertyName_title: 'Property Name & PartnerRef',
  analytics_chart_builder_get_started: 'to get started',
  analytics_chart_builder_get_started_add: 'Add',
  analytics_chart_builder_get_started_and_or: 'and/or',
  analytics_chart_builder_menu_delete: 'Delete',
  analytics_chart_builder_menu_move: 'Move',
  analytics_chart_builder_menu_rename: 'Rename',
  analytics_chart_builder_metric: 'metrics',
  analytics_chart_builder_save_as_modal_board: 'Board',
  analytics_chart_builder_save_as_modal_cancel: 'Cancel',
  analytics_chart_builder_save_as_modal_name: 'Name',
  analytics_chart_builder_unsaved_changes_modal_body:
    'You have unsaved changes. Do you want to save them before you leave?',
  analytics_chart_builder_unsaved_changes_modal_continue: 'Continue editing',
  analytics_chart_builder_unsaved_changes_modal_discard: 'Discard changes',
  analytics_chart_builder_unsaved_changes_modal_save: 'Save',
  analytics_chart_builder_unsaved_changes_modal_save_as: 'Save As',
  analytics_chart_builder_unsaved_changes_modal_saved: 'Saved',
  analytics_chart_builder_unsaved_changes_modal_saving: 'Saving',
  analytics_chart_builder_unsaved_changes_modal_title: 'Unsaved changes',
  analytics_chart_builder_untitled: 'Untitled',
  analytics_chart_builder_failed_to_export_chart: 'Failed to export chart to CSV file',
  analytics_check_status: 'Please check the status of these properties individually.',
  analytics_control_panel_apply_tooltip: 'No bids changes to apply',
  analytics_control_panel_cpa:
    'Some of the properties you selected are currently set to CPA. The CPCs you have entered will be stored and only be used if and when those properties are allocated to the CPC campaign.',
  analytics_control_panel_current_bid_cpc: 'Bid CPC',
  analytics_control_panel_current_bid_cpc_na: 'N/A',
  analytics_control_panel_error_limit_cpc: 'The $$ you entered exceeds the maximum amount of €$$.',
  analytics_control_panel_locked: 'Oops! Something went wrong',
  analytics_control_panel_locked_limit_reached: 'Daily limit for $$ pushes is reached.',
  analytics_control_panel_locked_multiple_locales_or_brands:
    'You cannot edit a $$ in Global view or with multiple points of sale or brands selected.',
  analytics_control_panel_multiple: 'Multiple',
  analytics_control_panel_properties_selected: ' properties selected',
  analytics_current_modifier_tooltip:
    "Indicates the Bid Modifier that is currently applied for the point of sale/bidding dimension/breakout combination. However, if the Status is 'Pending', it means that your Current Bid Modifier hasn't been pushed live yet.",
  analytics_date_add_period: 'Add period',
  analytics_date_remove: 'Remove',
  analytics_date_compare: 'Compare',
  analytics_date_compare_disabled_tooltip:
    'The comparison can only be applied in “Hotels”, "Point of sale", “Time period” and "Hotel details" view',
  analytics_date_compare_onboarding_tooltip:
    'The Time Period Comparison functionality with "<b>Hotels</b>", "<b>Points of sale</b>" and "<b>Time period</b>" view offers a comparative analysis of hotel or locale performance across up to 3 chosen time frames, enabling users to identify performance changes and analyze trends over time.',
  analytics_date_compare_trend_onboarding_tooltip:
    'Now you can compare data changes between different time periods, and switch between viewing relative growth rate and absolute percentage or numerical changes.',
  analytics_dd_tooltip:
    'This column displays the Default Date breakout, which indicates whether the data refers to a Default Date search or not.',
  analytics_filter_sets_error: 'Unable to load filters. Please reload page to filter table data',
  analytics_filter_types_error: 'Unable to load filters. Please reload page to filter table data',
  analytics_filters_active: 'Active filters: ',
  analytics_filters_clear: 'Clear filters',
  analytics_filter_clear: 'Clear filter',
  analytics_filters_copy: 'copy',
  analytics_home_apply: 'Apply',
  analytics_home_bidding_type_view: 'Bidding Type',
  analytics_home_bm_daily_push_limit_message: 'Daily limit for Bid Modifier pushes is reached.',
  analytics_home_bm_pos_combined_modifiers: 'Combined Modifiers',
  analytics_home_bm_pos_default_date: 'Default Date',
  analytics_home_bm_pos_group_size: 'Group Size',
  analytics_home_bm_pos_length_of_stay: 'Length of Stay',
  analytics_home_bm_pos_time_to_travel: 'Time to Travel',
  analytics_home_calendar: 'Calendar',
  analytics_home_cancel: 'Cancel',
  analytics_home_data_selector: 'Show data for:',
  analytics_home_export_report: 'Export report',
  analytics_home_filters: 'Filters',
  analytics_home_hotel_view: 'Hotels',
  analytics_home_others: 'Other',
  analytics_home_others_accommodation_type_dimension: 'Accommodation type',
  analytics_home_others_city_dimension: 'City',
  analytics_home_others_country_dimension: 'Country',
  analytics_home_others_rating_dimension: 'Rating',
  analytics_home_others_region_dimension: 'Region',
  analytics_home_others_search_city: 'Search city',
  analytics_home_others_search_country: 'Search country',
  analytics_home_others_search_region: 'Search region',
  analytics_home_others_stars_dimension: 'Star classification',
  analytics_home_placeholder_search_country: 'Search Countries...',
  analytics_home_placeholder_search_region: 'Search Regions...',
  analytics_home_placeholder_search_subbrands: 'Search brands',
  analytics_home_pos_global: 'Global',
  analytics_home_pos_selector: 'Point of Sale',
  analytics_home_pos_selector_selected: 'selected',
  analytics_home_pos_view: 'Points of sale',
  analytics_home_region_aggregation: 'Aggregation of ',
  analytics_home_region_aggregation_1: ' points of sale',
  analytics_home_search_item_hotels: 'Search property name or partner reference',
  analytics_home_search_item_pos: 'Search point of sale',
  analytics_home_select_country: 'Countries',
  analytics_home_select_region: 'Regions',
  analytics_home_selector_subMenu_selected: 'Selected',
  analytics_home_subbrand_selector: 'Brand',
  analytics_home_subbrand_selector_selected: 'selected',
  analytics_home_subbrands_all: 'All',
  analytics_home_time_view: 'Time period',
  analytics_home_upload_base_bids: 'Upload a CPC base bid file',
  analytics_home_upload_bid_modifiers: 'Upload a CPC bid modifier file',
  analytics_home_upload_cpa_bid_modifiers: 'Upload a CPA bid modifier file',
  analytics_home_upload_bid_sample: 'Sample file',
  analytics_home_upload_file_size_limit_warning: 'Maximum file size limit is 40 MB.',
  analytics_home_upload_property_groups: 'Upload a property groups file',
  analytics_hotel_detail_bid_cpc: 'Bid CPC',
  analytics_hotel_detail_bidding_type: 'Bidding type',
  analytics_hotel_detail_city: 'City',
  analytics_hotel_detail_country: 'Country',
  analytics_hotel_detail_cpa_value: 'CPA value',
  analytics_hotel_detail_details: 'Details',
  analytics_hotel_detail_partner_ref: 'Partner Ref',
  analytics_hotel_detail_pending_bid_cpc: 'Pending CPC',
  analytics_hotel_detail_pending_bidding_type: 'Pending bidding type',
  analytics_hotel_detail_pending_sl_bid_cpc: 'Pending SL CPC',
  analytics_hotel_detail_rating: 'Rating',
  analytics_hotel_detail_region: 'Region',
  analytics_hotel_detail_sl_bid_cpc: 'SL Bid CPC',
  analytics_hotel_detail_star: 'star',
  analytics_hotel_detail_stars: 'stars',
  analytics_hotel_detail_stars_field: 'Stars',
  analytics_hotel_detail_status: 'Status',
  analytics_hotel_detail_trivago_id: 'trivago ID',
  analytics_hotel_detail_trivago_link: 'trivago link',
  analytics_hotel_error:
    'Unable to load hotel details. Please reload the page to display hotel data',
  analytics_hotel_not_found_back_button: 'Back to Hotel View',
  analytics_hotel_not_found_partner_change:
    'This property is not included in your inventory for the brand you have selected.',
  analytics_hotel_not_found_pos_change:
    'This property is not included in your inventory for the point of sale you have selected. Please choose another point of sale. ',
  analytics_hotel_not_found_title: 'Property PREF: $$ not found',
  analytics_hotel_not_found_unknown:
    'We could not find this property for the current point of sale/brand selection.<ul><li>Check another point of sale</li><li>Check another brand</li></ul>',
  analytics_hotel_url_link_text: 'url',
  analytics_input_filter_select_column: 'Select column',
  analytics_input_filter_select_condition: 'Select condition',
  analytics_perform_bids_error:
    'An error occurred and your bid changes were not applied. Please try again.',
  analytics_perform_bids_partial_success: 'Bid changes have been applied to ',
  analytics_perform_bids_success: 'You have applied bid changes for ',
  analytics_perform_bids_success_extra_info:
    'Bids are scheduled to be pushed, please check the status of your bids in the ‘History’ tab.',
  analytics_property_group_dropdown_disabled_tooltip:
    "This feature is disabled because you've either selected multiple POS or brands, or the POS you are viewing uses trivago-Generated Bid Modifiers.",
  analytics_property_group_dropdown_information_tooltip:
    'Property Groups allow you to apply different Bid Modifiers to properties that you have grouped together within a POS. You can create and manage your groups via Property Group import file uploads.',
  analytics_property_group_dropdown_multiple_selected: '$$ Property Groups selected',
  analytics_property_groups_dropdown_item: 'PG $$',
  analytics_property_groups_dropdown_on_boarding:
    'Select one of your Property Groups here to edit your Bid Modifiers.',
  analytics_property_groups_dropdown_selection_error: 'You must select at least one Property Group',
  analytics_property_groups_onboarding_text:
    'The <b>Property Groups</b> view enables you to monitor and analyze performance data according to specific bid modifier categories, as well as the ability to delve into detailed performance breakouts for each property group.',
  property_group_aliases_tooltip:
    'To assign an alias to a Property Group, move your cursor over the desired property group and click the "+ Add alias" button. Keep in mind that you can create or view aliases for Property Groups only when a specific sub-brand and a specific Point of Sale are selected.',
  cpa_group_aliases_tooltip:
    'To assign an alias to a CPA Group, move your cursor over the desired CPA group and click the "+ Add alias" button. Keep in mind that you can create or view aliases for CPA groups only when a specific sub-brand and a specific Point of Sale are selected.',
  alias_disabled_tooltip:
    'Keep in mind that you can create or view aliases only when a specific sub-brand and a specific Point of Sale are selected.',
  aliases_update_error: 'Failed to update alias',
  aliases_edit: 'Edit',
  aliases_add: '+ Add alias',
  analytics_revert_error: 'Oops! ',
  analytics_revert_modal_info_bids:
    'This will effectively cancel any base bid changes you have submitted for tomorrow across <strong>all points of sale<strong/> (including file uploads), reverting these bids back to their previous denominations.',
  analytics_revert_modal_info_campaign_allocations:
    'This will cancel all campaign allocation files and related actions that are scheduled to be pushed.',
  analytics_revert_modal_info_cpa_value:
    'This will cancel all pending CPA value changes that are scheduled to be pushed.',
  analytics_revert_modal_info_modifiers:
    'This will effectively cancel any Bid Modifier changes you have submitted for tomorrow across <strong>all points of sale<strong/> (including file uploads), reverting these Bid Modifiers back to their previous values.\n',
  analytics_revert_modal_info_property_groups:
    'This will cancel all Property Groups changes across all points of sale that are scheduled to be pushed.',
  analytics_revert_modal_title_info_bids: 'Revert all bid changes',
  analytics_revert_modal_title_info_campaign_allocations: 'Revert campaign allocation changes',
  analytics_revert_modal_title_info_cpa_value: 'Revert CPA Value changes',
  analytics_revert_modal_title_info_modifiers: 'Revert all bid modifier changes',
  analytics_revert_modal_title_info_property_groups: 'Revert all Property Groups changes',
  analytics_revert_success: 'Success! ',
  analytics_rows_by_view_error:
    'Unable to load row data. Please reload the page to display table data',
  analytics_score_na: 'N/A',
  analytics_subtotal_by_view_error:
    'Unable to load subtotal amount. Please reload the page to display the subtotal',
  analytics_table_subtotal_rows: 'Filtered total',
  analytics_table_total_rows: 'Total',
  analytics_toolbar_export_performance_grid:
    'Performance grid for properties with impressions (.csv)',
  analytics_toolbar_export_performance_grid_excel:
    'Performance grid for properties with impressions (.xlsx)',
  analytics_toolbar_export_without_impressions_performance_grid:
    'Performance grid for all properties (.csv)',
  analytics_toolbar_export_without_impressions_performance_grid_excel:
    'Performance grid for all properties (.xlsx)',
  analytics_tooltip_abv:
    'Average Booking Value indicates the average room night revenue generated per booking as tracked by the trivago Tracking Pixel.',
  analytics_tooltip_active_matched: 'Properties that are currently matched and active on trivago',
  analytics_tooltip_active_unmatched:
    'Properties that were included in at least one of your inventory import files, but not yet matched on trivago',
  analytics_tooltip_adv_sl_impress:
    'The number of impressions for the property you received in the breakout on your Sponsored Listings campaign.',
  analytics_tooltip_avg_base_bid:
    'This indicates the average base bid that was applied to this property for the selected time frame.',
  analytics_tooltip_avg_cpc:
    'The Average Cost-Per-Click indicates the average amount you have been charged for a click.',
  analytics_tooltip_avg_cpc_modified:
    'This indicates the average modified bid that was applied to this property for the selected time frame.',
  analytics_tooltip_base_bid_cost:
    'This indicates the total cost generated before the application of Bid Modifiers.',
  analytics_tooltip_beat:
    'Beat indicates how many times your rate was the unique cheapest rate compared to competitors’ rates, for the total number of impressions the hotel received.',
  analytics_tooltip_beat_vs_champ:
    'Indicates how often your rate was lower than the top position (champion) rate. A Conversion tracking solution is required to receive this data.',
  analytics_tooltip_bid_modifier:
    'This is the average bid modifier that was applied for this point of sale/breakout combination.',
  analytics_tooltip_booking_rate:
    "Booking Rate (i.e. Conversion Rate) is the percentage of your clicks which led to transactions as tracked by one of trivago's Conversion Tracking solutions.",
  analytics_tooltip_booking_value_index:
    'Booking Value Index indicates the estimated average booking amount per click for a property on a POS, compared to your Average Booking Amount per click on the respective POS. ',
  analytics_tooltip_bookings:
    "Bookings indicates the number of your transactions as tracked by one of trivago's Conversion Tracking solutions.\nNote that for items on CPA bidding, bookings are allocated to the date of the corresponding click-out, which can be in the past days.\nConsequently, the count of bookings for a period of time will not match the invoicing period which is based on the date of the booking.",
  analytics_tooltip_city: 'The city in which this property is located.',
  analytics_tooltip_clicks: 'Clicks indicates the number of clicks as counted by trivago.',
  analytics_tooltip_cost:
    'Cost indicates the total CPC and CPA cost (does not include Sponsored Listing)',
  analytics_tooltip_country: 'The country in which this property is located.',
  analytics_tooltip_cpa:
    'CPA indicates your acquisition cost as a percentage of your total booking volume. It is calculated by Cost/Gross Revenue.',
  analytics_tooltip_cpc_impact:
    'Your CPC Impact considers your Rate Accuracy Score and is calculated relative to that of all other advertisers in the respective POS.',
  analytics_tooltip_gross_rev:
    'Gross Revenue indicates your total booking value as tracked by the trivago Tracking Pixel.',
  analytics_tooltip_gs_breakout:
    'This column displays the Group Size, as well as the corresponding breakout value in brackets.',
  analytics_tooltip_hotel_impress:
    'This column indicates the number of impressions received for a specific property even when you are not participating in the auction.  \nAs opposed to the meta performance report, this column accounts for all breakouts including those where you are not participating.\nAn impression is recorded for all properties on a search results page when a user makes a click on any of the properties displayed.',
  analytics_tooltip_impr_share:
    'Your Impression Share indicates the percentage of impressions you received out of the total number of hotel impressions. An impression is recorded for every advertiser that provides a rate via an API region request.',
  analytics_tooltip_inactive_matched:
    'Properties that were not included in your last inventory import file, but were previously active and matched on trivago.',
  analytics_tooltip_inactive_unmatched:
    'Properties that were not included in your last inventory file, and were not previously matched on trivago.',
  analytics_tooltip_inc_traffic:
    'The Incremental Traffic metric indicates the amount of traffic (as a percentage value) that you would gain by increasing your CPC bids by 10% across all inventory on this POS.',
  analytics_tooltip_los_breakout:
    'This column displays the actual length of stay in days, as well as the corresponding (breakout value) in brackets.',
  analytics_tooltip_los_modifier:
    'Indicates which Bid Modifier was applied per Length of Stay breakout.',
  analytics_tooltip_lose:
    'Lose indicates how many times your rate was more expensive/not the cheapest rate compared to one or more competitors’ rates, for the total number of impressions the hotel received.',
  analytics_tooltip_lose_vs_champ:
    'Indicates how often your rate was more expensive than the top position (champion) rate. A Conversion tracking solution is required to receive this data.',
  analytics_tooltip_lps:
    "The Landing Page Score is a number given between 1 (poor exposure) and 10 (excellent exposure). It's a measurement that estimates the quality of the user experience on your landing page.",
  analytics_tooltip_margin:
    'The Margin indicates the commission percentage generated from bookings as tracked by the trivago Tracking Pixel.',
  analytics_tooltip_max_potential:
    'Maximum Potential indicates the maximum traffic that you can achieve for a hotel or a POS by bidding up.',
  analytics_tooltip_meet:
    "Meet indicates how many times your rate was the cheapest rate but in parity with one or more competitors' rates, for the total number of impressions the hotel received.",
  analytics_tooltip_meet_vs_champ:
    'Indicates how often your rate was either in the top position or was the same as the champion rate. A Conversion tracking solution is required to receive this data.',
  analytics_tooltip_net_rev:
    'The Net Revenue indicates the value of total booking commission as tracked by the trivago Tracking Pixel. The calculation is Gross Revenue multiplied by Margin.',
  analytics_tooltip_outbid_ratio:
    'Your Outbid Ratio indicates the reduced exposure of your rates from all potential impressions in the region search results as a percentage value, due to being outbid by another advertiser.',
  analytics_tooltip_profit:
    'Profit indicates the value remaining from booking commission after the Total Cost is deducted.\nProfit is calculated by Net Revenue - Total Cost\nNote: Only available if trivago Tracking Pixel has been implemented and if commission data has been supplied.',
  analytics_tooltip_property_group:
    'This column indicates which Property Group each property belongs to. Property Groups allow you to apply different Bid Modifiers to properties that you have grouped together within a point of sale. ',
  analytics_tooltip_hotel_impressions:
    'This column indicates the number of impressions received for a specific property even when you are not participating in the auction.\nAs opposed to the meta performance report, this column accounts for all breakouts including those where you are not participating.\nAn impression is recorded for all properties on a search results page when a user makes a click on any of the properties displayed.',
  analytics_tooltip_ras:
    "Your Rate Accuracy Score is a data-driven measurement that indicates your performance in terms of the consistency between your rates on trivago and the rates on your landing page. Your score is determined on a scale ranging from Excellent to Failed. P1 = Advertiser's landing page; P2 = Next page in advertiser's booking funnel. Can be the item, cookies, booking, error page; P3 = Advertiser's booking or error page.",
  analytics_tooltip_rev_click:
    "The Net Revenue per Click is your net revenue, as tracked by the trivago Tracking Pixel, divided by your clicks.\nWhy it matters: you can determine whether you're breaking even by comparing the Net Rev/Click to your CPC.",
  analytics_tooltip_roas:
    'Return on Advertising Spend indicates the effectiveness of your rates in relation to your Average CPC.\nROAS is calculated by Net rev / Cost.\nNote: Only available if the trivago Tracking Pixel has been implemented and commission data has been supplied.',
  analytics_tooltip_stars: 'The number of stars that this property possesses (from 1 to 5).',
  analytics_tooltip_region: 'The Region in which this property is located.',
  analytics_tooltip_rating: 'The trivago score of the property.',
  analytics_tooltip_trivago_id:
    'trivagoID indicates a unique number trivago assigns to identify each property.',
  analytics_tooltip_top_pos_share:
    'Top Position Share indicates the percentage of all impressions the property received for which your offer was in the top position.',
  analytics_tooltip_total_cost_modified:
    'This indicates the total cost generated after the application of Bid Modifiers.',
  analytics_tooltip_ttt_breakout:
    'This column displays the actual time to travel in days, as well as the corresponding (breakout value) in brackets.\nPlease note that only the data recorded from the 31st of July, 2023, is available for TTT\'s "1-day (1)" breakout.',
  analytics_tooltip_ttt_modifier:
    'This indicates which Bid Modifier was applied per Time to Travel breakout.',
  analytics_tooltip_unavailability:
    'Unavailability denotes the frequency of rate non-submission or API non-responsiveness in relation to the hotel’s total impressions.',
  analytics_total_by_view_error:
    'Unable to load total amount. Please reload the page to display the total',
  analytics_total_count_by_view_error:
    'Unable to load total count. Please reload the page to display total count',
  analytics_view_columns_error:
    'Unable to load table columns. Please reload the page to display table data',
  analytics_grid_context_menu_hotel_onboarding_tooltip:
    'Discover how this hotel performs in the different dimensions available, gaining deeper insights into your performance.',
  banner_enable_error: 'Unable to enable banner. Please try again later',
  banner_disable_error: 'Unable to disable banner. Please try again later',
  banner_get_error: 'Unable to load banner. Please try again later',
  app_update_translations_error: 'Unable to update translations for $$',
  assign_partner_modal_primary_button: 'Assign',
  assign_partner_modal_search_title: 'Search',
  assign_partner_modal_title: 'Assign partner ',
  auth_login_error: 'Unable to login. Please try again',
  auth_refresh_error: 'Unable to extend session. Please login again',
  base_bid_suggestion_download: 'Download ',
  base_bid_suggestion_download_all: 'Download all',
  base_bid_suggestion_over_max_for_download_all:
    'This option is only available when there are less than 40 million suggestions. When there are more, please download your suggestions per point of sale. ',
  base_bid_suggestion_row_active_edit: 'Edit',
  base_bid_suggestion_row_active_measured_cpa: 'Measured CPA',
  base_bid_suggestion_row_active_measured_cpa_tooltip:
    "Indicates the approx. percentage of your revenue (i.e. generated booking amount) that you're actually paying to trivago. It's updated daily using aggregated data from the past 30 days, or from when you last changed your Target CPA (if more recent).",
  base_bid_suggestion_row_active_suggestions: 'Base bid suggestions',
  base_bid_suggestion_row_active_suggestions_average: 'Average bid change: ',
  base_bid_suggestion_row_active_suggestions_loading: 'Generating suggestions...',
  base_bid_suggestion_row_active_suggestions_tooltip:
    "Applying these suggestions will help you optimize toward your proposed Target CPA. The suggestions are calculated using a combination of trivago's market knowledge and all the aggregated data you have available for this point of sale.",
  base_bid_suggestion_row_active_target_cpa: 'Target CPA',
  base_bid_suggestion_row_active_target_cpa_tooltip:
    'Indicates the percentage of your revenue (i.e. generated booking amount) that you intend to pay trivago as an acquisition cost.',
  base_bid_suggestion_row_missing_pixel_tracking_state_1:
    'You need a Conversion Tracking Solution to receive suggestions.',
  base_bid_suggestion_row_missing_pixel_tracking_state_2:
    ' Visit the Resource Center for more information about <a target="_blank" href="https://resourcecenter.trivago.com/trivago-essentials/technical-topics/conversion-tracking/">Conversion Tracking.</a>',
  base_bid_suggestion_row_not_generated_automated_bidding_1:
    'This point of sale is already being optimized with Automated Bidding.',
  base_bid_suggestion_row_not_generated_automated_bidding_2:
    'If you\'d like to receive suggestions, you will need to switch to Manual Bidding via your <a href="/campaigns">Campaigns > Settings</a> page.',
  base_bid_suggestion_row_pending_changes:
    '<strong>Pending changes: </strong>You have already applied suggestions for $$ today. These will go live tomorrow. You can revert changes in the Analytics tab.',
  bidding_campaigns__title: 'Campaigns',
  bidding_control_panel_cpa_value_field: 'CPA value',
  bidding_control_panel_cpa_value_over_limit_max:
    '<b>Error:</b> The CPA value you have entered is higher than 100%. Please enter a lower value. ',
  bidding_control_panel_cpa_value_over_limit_multiple:
    'You have entered an invalid CPA value for at least one of the points of sale selected. This error could occur if the value that you entered is lower than the minimum CPA value allowed.',
  bidding_control_panel_cpa_value_over_limit_single:
    'You have entered an invalid CPA value. This error could occur if the value that you entered is lower than the minimum CPA value allowed.',
  bidding_control_panel_modal_apply_changes: 'Apply changes',
  bidding_control_panel_modal_detail_1:
    'You are submitting CPA value changes that are scheduled to be pushed. You can see all pending changes under the "Pending CPA Value" column in Campaigns.',
  bidding_control_panel_modal_title: 'Apply CPA Value Changes',
  bidding_control_panel_multiple_selection: 'campaigns selected',
  bidding_control_panel_placeholder_multiple: 'Multiple',
  bidding_control_panel_tooltip: 'No changes to apply',
  bidding_notification_upload_file_failure:
    '<strong>Oops!</strong> Something went wrong. Please try again later.',
  bidding_notification_upload_file_failure_body:
    'Something went wrong. Please check the file format and try again.',
  bidding_notification_upload_file_too_big_failure: 'Maximum file size limit of 40 MB exceeded!',
  bidding_suggestion_info_body_1:
    'These suggestions have been generated to support and guide you with your Manual Bidding campaigns, so that you can optimize toward a proposed Target CPA. ',
  bidding_suggestion_info_body_2:
    "They are calculated using a combination of trivago's market knowledge and your aggregated performance data. Simply download the suggestions per point of sale and decide whether to include them in your regular base bid file upload. ",
  bidding_suggestion_info_heading:
    'You have a total of <strong>$$</strong> base bid suggestions across $$ point(s) of sale',
  bidding_suggestion_info_heading_loading:
    '<p>Generating suggestions...</p>This may take up to 30 seconds',
  bidding_suggestion_info_heading_no_suggestions:
    'Base bid suggestions are only available for <strong>Manual Bidding</strong> campaigns with <strong>Conversion Tracking</strong>',
  bidding_suggestion_info_learn_more:
    '<a target="_blank" href="https://resourcecenter.trivago.com/base-bid-suggestions/">Learn more.</a>',
  bidding_suggestion_info_time_tooltip:
    'Base bid suggestions are generated daily using all aggregated data that was available yesterday. If you change your Target CPA, suggestions will be recalculated instantly using the same data. ',
  bidding_suggestion_info_title: 'How do base bid suggestions work?',
  bidding_suggestion_title: 'Base Bid Suggestions',
  bidding_suggestions_campaign_search_placeholder: 'Search points of sale',
  bidding_suggestions_get_all_error:
    '<strong>Oops!</strong> An error occurred and we could not retrieve your Target CPA or Measured CPA. Please reload the page or try again later.',
  bidding_suggestions_get_number_of_suggestions_error:
    '<strong>Oops!</strong> An error occurred and we could not retrieve suggestions for you. Please reload the page or try again later.',
  bidding_suggestions_last_update: 'Last updated: ',
  bidding_suggestions_no_data_result_message: 'No results found',
  bidding_suggestions_save_error:
    '<strong>Oops!</strong> An error occurred and your Target CPA was not saved. Please try again.',
  bidding_table_no_data_result_message: 'No results for current filter selection',
  bm_control_panel__multiple_property_group_selected: '($$ Property Groups selected)',
  bm_control_panel__single_property_group_selected: '(Property Group $$)',
  bm_control_panel_apply: 'Apply',
  bm_control_panel_bid_modifier: 'Bid Modifier: ',
  bm_control_panel_bidding_modifier_over_limit: 'You must enter a value between 0.01 and 10.0.',
  bm_control_panel_blocked_message:
    'You cannot edit Bid Modifiers because multiple points of sale, brands or both CPC and CPA bidding types are selected.',
  bm_control_panel_blocked_message_cpa_only_no_ttt:
    'You cannot edit Bid Modifiers because multiple point of sale, brands or CPA bidding types are selected.',
  bm_control_panel_multiple_breakouts_selected: 'breakouts selected',
  bm_control_panel_notification_message_error:
    'An error occurred and your Bid Modifier changes were not applied. Please try again.',
  bm_control_panel_notification_message_partial_success:
    'Bid Modifier changes have been applied to ',
  bm_control_panel_notification_message_success:
    'You have edited Bid Modifiers for $$ breakout(s). Bids are scheduled to be pushed, please check the status of your bids in ‘History’ tab.',
  bm_control_panel_placeholder_multiple: 'Multiple',
  bm_control_panel_selected_bid_modifiers: ' selected breakouts.',
  bm_control_panel_single_breakout_current_value: 'Current Bid Modifier: ',
  bm_control_panel_single_breakout_pending_value: 'Pending Bid Modifier change: ',
  bm_control_panel_ttt_1_onboarding_tooltip:
    'Please note that only the data recorded from the 31st of July, 2023, is available for TTT\'s "1-day (1)" breakout.',
  bm_property_group_tooltip:
    'This column indicates which Property Group your are editing your Bid Modifiers for.',
  campaign_dashboard__search_placeholder: 'Search for a campaign',
  campaign_groups_dropdown_disabled_tooltip: 'Filtering campaigns is disabled',
  campaign_groups_dropdown_information_tooltip:
    'Please select the campaigns you want to display. By default, only campaigns with active inventory are displayed.',
  campaign_groups_dropdown_multiple_selected: 'Campaigns selected',
  campaign_groups_dropdown_selection_error: 'You must select at least one Campaign',
  campaign_only_actives_filter_tooltip: 'Campaigns without active inventory are hidden.',
  campaigns_control_panel_locked_limit_reached: 'Daily limit for CPA value pushes is reached.',
  campaigns_inventory_upload_onboarding_tooltip:
    'With the <b>Inventory Upload</b> feature you have the convenience of uploading your inventory directly on trivago Intelligence, providing an alternative to the previously required use of SFTP or tAA.',
  campaigns_toolbar_export: 'Campaigns (.csv)',
  campaigns_toolbar_export_excel: 'Campaigns (.xlsx)',
  campaigns_tooltip_properties: 'Number of active properties that have a bid attached.',
  campaigns_tooltip_inactive_properties:
    'Properties that are currenly matched on trivago, but the bidding is not defined.',
  campaigns_tooltip_invisible_properties: 'Active properties with bids of 0 or 1 cent.',
  campaigns_tooltip_properties_without_bid:
    'Properties that are active but do not have a bid attached across all campaigns.',
  campaigns_properties_label: 'Live-bid Active',
  campaigns_properties_without_bids_label: 'No-bid Active',
  invisible_properties_label: 'Low-bid Active',
  canceled_history_key: 'Canceled',
  common_apply: 'Apply',
  common_campaign: 'campaign',
  common_campaigns: 'campaigns',
  common_cancel: 'Cancel',
  common_clear_filters_message: 'Clear filters',
  common_close: 'Close',
  common_delete: 'Delete',
  common_fail: '<strong>Oops!</strong> ',
  common_loading_message: 'Loading...',
  common_logout: 'Log out',
  common_no_data_result_message: 'No results for current filter selection',
  common_not_available: 'N/A',
  common_of_the: ' of the ',
  common_off: 'Off',
  common_on: 'On',
  common_properties: 'properties',
  common_property: 'property',
  common_retry: 'Retry',
  common_revert: 'Revert',
  common_save: 'Save',
  common_selected_campaigns: ' selected campaigns. ',
  common_selected_properties: ' selected properties. ',
  common_success: '<strong>Success!</strong> ',
  common_days: 'days',
  dashboard_chart_builder_bid_modifier_tooltip:
    'There are a few data differences in charts with Bid Modifier dimensions from Bid Modifier Analytics views:\n\n1. In charts with Bid Modifier dimensions booking related data is reflected based on the date bookings are received, while in Bid Modifier Analytics views it is reflected based on click-out date associated with the booking.\n\n2. In charts with Bid Modifier dimensions, CPA data is present, while in Bid Modifier Analytics views only CPC data is currently available.',
  dashboard_chart_builder_onboarding:
    'The <b>Chart Builder</b> feature allows you to customize your dashboards by creating widgets that chart performance metrics and dimensions, offering a visual representation of data to easily track and understand performance trends.',
  dashboard_chart_builder_time_period_onboarding:
    'Compare performance metrics across two time periods with the "Time Period" dimension.',
  dashboard_chart_builder_onboarding_learn_more: 'Learn more',
  dashboard_chart_builder_property_group_tooltip:
    '<p>Only 1 Point of Sale can be selected.</p>\n<p>Only 1 Brand can be selected.</p>',
  dashboard_create_modal_blank_option: 'Create from new',
  dashboard_create_modal_button: 'Create',
  dashboard_create_modal_choose_name: 'Dashboard name ',
  dashboard_create_modal_info:
    'Please note, the dashboard you create will be visible and editable by all user accounts associated with: $$.',
  dashboard_create_modal_input_error_out_of_scope:
    'Your dashboard name must be between 2-255 characters. ',
  dashboard_create_modal_input_error_repeated_name:
    'This name already exists within your organization. Please choose a different name. ',
  dashboard_create_modal_input_placeholder: 'e.g. Dashboard 123',
  dashboard_create_modal_starter_option: 'Use default template ',
  dashboard_create_modal_title: 'Create new dashboard',
  dashboard_dropdown_create_dashboard: 'Create new dashboard',
  dashboard_dropdown_no_results: 'Dashboard not found ',
  dashboard_dropdown_on_boarding:
    'You can now create, search, and switch between multiple dashboards within your organization by clicking here.',
  dashboard_dropdown_search_dashboard: 'Search dashboards ',
  dashboard_dropdown_selected_dashboard: 'Selected',
  dashboard_edit_widget_error:
    'An error occurred and your changes were not saved. Please try again.',
  dashboard_empty_1: 'This dashboard is empty.',
  dashboard_empty_2: 'Fill it with the data you need.',
  dashboard_global_inventory_download_in_progress: 'Download in progress',
  dashboard_global_inventory_download_disclaimer:
    "There may be discrepancies in the file and what's shown in the widget. Data in the file is more up-to-date, since the widget is updated only once a day",
  dashboard_global_inventory_missing_matches_tooltip:
    'Property IDs that were present in bidding files, but not in inventory files',
  dashboard_global_inventory_duplicate_items_tooltip:
    'Property IDs that were provided several times in your inventory file',
  dashboard_global_inventory_widget_title: 'Global Inventory Status',
  dashboard_header_create_dashboard: 'Create a new dashboard',
  dashboard_header_delete_dashboard: 'Delete dashboard',
  dashboard_header_modal_delete_body: '$$ will be permanently deleted for all user accounts.',
  dashboard_header_modal_delete_title: 'Are you sure?',
  dashboard_header_modal_last_dashboard_body:
    'You are unable to delete this dashboard, as you must have at least one active dashboard. Please either <strong>create a new dashboard</strong> first or <strong>reset the current dashboard</strong>. ',
  dashboard_header_modal_last_dashboard_title: 'Could not delete dashboard',
  dashboard_header_name_edit: 'Edit name ',
  dashboard_loading_data: 'Loading data...',
  dashboard_pos_overview_widget_current_data:
    'This widget reflects data aggregated over the last 7 days',
  dashboard_pos_overview_widget_select_one_pos_brand: 'Select only one POS and Brand',
  dashboard_pos_overview_widget_title: 'Point of Sale Overview',
  dashboard_remove_widget: 'Remove widget',
  dashboard_reset_to_default: 'Reset to default dashboard',
  dashboard_widget_error: 'We are unable to load the data. Please try again later.',
  dashboard_widget_no_data: 'No data available. Please refine your filter.',
  dashboard_yesterday_overview_widget_compared: "Compared to the last 7 days' average",
  dashboard_yesterday_overview_widget_title: "Yesterday's Overview ($$)",
  dashboards_delete_error:
    'An error occurred and the dashboard could not be deleted. Please try again.',
  dashboards_edit_error:
    'An error occurred that prevented you from editing the dashboard. Please try again.',
  dashboards_get_all_error:
    'An error occurred and the dashboard could not be retrieved. Please try again.',
  dashboards_save_error: 'An error occurred and your changes were not saved. Please try again.',
  edit_target_cpa_target_cpa_error: 'Please enter a value between 2.0% and 70.0%',
  edit_target_cpa_title: 'Target CPA',
  error_history_key: 'Error',
  export_report_csv_option: 'Export (.csv)',
  export_report_excel_option: 'Export (.xlsx)',
  export_report_file_too_large:
    'File size exceeds limit. Please choose .csv format for large datasets.',
  express_booking_desktop: 'Desktop',
  express_booking_info_body_1:
    'These suggestions have been generated to show you which of your points of sale, and on which device (desktop or mobile), we recommend switching to our Express Booking solution in order to help improve the performance of your campaign. ',
  express_booking_info_body_2:
    'For a more detailed explanation, read our Resource Center article, <a href="https://resourcecenter.trivago.com/express-booking-suggestions-in-trivago-intelligence/">Express Booking Suggestions in trivago Intelligence</a>, or to implement a switch, please contact your Account Manager.  ',
  express_booking_info_title: '<b>What are Express Booking suggestions?</b>',
  express_booking_mobile: 'Mobile',
  express_booking_no_results_message:
    'There are currently no Express Booking suggestions for this campaign. Please note that we only show suggestions for partners that have our conversion tracking solution implemented and when our most conservative estimated click-to-book is higher than your current conversion. ',
  express_booking_search_placeholder: 'Search for a point of sale',
  express_booking_show_mobile_desktop: 'Show desktop/mobile',
  express_booking_title: 'Express Booking Suggestions',
  file_download_analytics_error: 'Could not download analytics report. Please try again later',
  file_download_artifact_error: 'Could not download artifact. Please try again later',
  file_download_sample_error: 'Could not download sample. Please try again later',
  file_download_error: 'Could not download file. Please try again later',
  file_uploader_not_available: 'Option unavailable',
  filters_dropdown_error: 'Something went wrong. Failed to fetch the options.',
  global_bidding_right_disabled: 'Your account configuration does not permit this action.',
  history_at: ' at ',
  history_calendar_time_date: 'Time range',
  history_column_details: 'Details',
  history_column_event_id: 'Event ID',
  history_column_files: 'Files',
  history_column_status: 'Status',
  history_cpa_value_push: 'CPA value push',
  history_cpa_value_push_error_history_key:
    ' An error occurred and your CPA values were not imported.',
  history_cpa_value_push_inprogress_history_key:
    'Your file was successfully uploaded and your CPA values are being imported.',
  history_cpa_value_push_pending_history_key: 'Your file is being uploaded.',
  history_cpa_value_push_success_history_key:
    'Your CPA values were successfully imported and are scheduled to be pushed.',
  history_cpa_value_push_waiting_history_key:
    'Your CPA values push is awaiting further input from Account Manager.',
  history_inventory_import: 'Inventory import',
  history_inventory_import_canceled_history_key: 'File processing was canceled.',
  history_inventory_import_error_history_key:
    'An error occurred and your inventory was not imported.',
  history_inventory_import_inprogress_history_key:
    'Your file was successfully uploaded and is being processed.',
  history_inventory_import_pending_history_key: 'Your file is being uploaded.',
  history_inventory_import_success_history_key: 'Your inventory was successfully imported.',
  history_inventory_import_waiting_history_key:
    'Your inventory import is awaiting further input from Account Manager.',
  history_dpi_bm_import:
    'CPC bid modifiers uploaded outside trivago Intelligence. Scheduled upload',
  history_dpi_bm_import_canceled_history_key: 'File processing has been canceled',
  history_dpi_bm_import_error_history_key: 'An error occurred and Bid Modifiers were not pushed.',
  history_dpi_bm_import_inprogress_history_key:
    'Your file was successfully uploaded and your Bid modifiers are being imported.',
  history_dpi_bm_import_manual:
    'CPC bid modifiers uploaded outside trivago Intelligence. Manual upload',
  history_dpi_bm_import_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_bm_import_manual_error_history_key:
    'An error occurred and Bid Modifiers were not pushed.',
  history_dpi_bm_import_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Bid modifiers are being imported.',
  history_dpi_bm_import_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_bm_import_manual_success_history_key: 'All Bid Modifiers have been pushed.',
  history_dpi_bm_import_manual_waiting_history_key:
    'Bid Modifier upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_bm_import_pending_history_key: 'Your file is being uploaded.',
  history_dpi_bm_import_success_history_key: 'All Bid Modifiers have been pushed.',
  history_dpi_bm_import_waiting_history_key:
    'Bid Modifier upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_combined_bidding: 'Combined bidding',
  history_dpi_combined_bidding_canceled_history_key: 'File processing has been canceled',
  history_dpi_combined_bidding_error_history_key: 'An error occurred and bids were not pushed.',
  history_dpi_combined_bidding_inprogress_history_key:
    'Your file was successfully uploaded and your Combined Bids are being imported.',
  history_dpi_combined_bidding_pending_history_key: 'Your file is being uploaded.',
  history_dpi_combined_bidding_success_history_key: 'All bids have been pushed.',
  history_dpi_combined_bidding_waiting_history_key:
    'Combined Bids upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_cpa_alloc:
    'Campaign allocations uploaded outside trivago Intelligence. Scheduled upload',
  history_dpi_cpa_alloc_canceled_history_key: 'File processing has been canceled',
  history_dpi_cpa_alloc_error_history_key:
    'An error occurred and campaign allocations were not pushed.',
  history_dpi_cpa_alloc_inprogress_history_key:
    'Your file was successfully uploaded and your Campaign allocations are being imported.',
  history_dpi_cpa_alloc_manual:
    'Campaign allocations uploaded outside trivago Intelligence. Manual upload',
  history_dpi_cpa_alloc_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_cpa_alloc_manual_error_history_key:
    'An error occurred and Campaign allocations were not pushed.',
  history_dpi_cpa_alloc_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Campaign allocations are being imported.',
  history_dpi_cpa_alloc_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_cpa_alloc_manual_success_history_key:
    'Campaign allocations were successfully pushed.',
  history_dpi_cpa_alloc_manual_waiting_history_key:
    'CPA allocation upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_cpa_alloc_pending_history_key: 'Your file is being uploaded.',
  history_dpi_cpa_alloc_success_history_key: 'Campaign allocations were successfully pushed. ',
  history_dpi_cpa_alloc_waiting_history_key:
    'CPA allocation upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_cpa_value: 'CPA values uploaded outside trivago Intelligence. Scheduled upload',
  history_dpi_cpa_value_canceled_history_key: 'File processing has been canceled',
  history_dpi_cpa_value_error_history_key: 'An error occurred and CPA values were not pushed.',
  history_dpi_cpa_value_inprogress_history_key:
    'Your file was successfully uploaded and your CPA values are being imported.',
  history_dpi_cpa_value_manual: 'CPA values uploaded outside trivago Intelligence. Manual upload',
  history_dpi_cpa_value_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_cpa_value_manual_error_history_key:
    'An error occurred and CPA values were not pushed.',
  history_dpi_cpa_value_manual_inprogress_history_key:
    'Your file was successfully uploaded and your CPA values are being imported.',
  history_dpi_cpa_value_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_cpa_value_manual_success_history_key: 'CPA values were successfully pushed.',
  history_dpi_cpa_value_manual_waiting_history_key:
    'CPA Value upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_cpa_value_pending_history_key: 'Your file is being uploaded.',
  history_dpi_cpa_value_success_history_key: 'CPA values were successfully pushed.',
  history_dpi_cpa_value_waiting_history_key:
    'CPA Value upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_inventory_import: 'Inventory uploaded outside trivago Intelligence. Scheduled upload',
  history_dpi_inventory_import_canceled_history_key: 'File processing was canceled.',
  history_dpi_inventory_import_error_history_key:
    'An error occurred and Inventory imports were not pushed.',
  history_dpi_inventory_import_inprogress_history_key:
    'Your file was successfully uploaded and your Inventory Imports are being imported.',
  history_dpi_inventory_import_manual:
    'Inventory uploaded outside trivago Intelligence. Manual upload',
  history_dpi_inventory_import_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_inventory_import_manual_error_history_key:
    'An error occurred and Inventory imports were not pushed.',
  history_dpi_inventory_import_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Inventory Imports are being imported.',
  history_dpi_inventory_import_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_inventory_import_manual_success_history_key:
    'Inventory imports were successfully pushed.',
  history_dpi_inventory_import_manual_waiting_history_key:
    'Inventory Imports upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_inventory_import_pending_history_key: 'Your file is being uploaded.',
  history_dpi_inventory_import_success_history_key: 'Inventory imports were successfully pushed.',
  history_dpi_inventory_import_waiting_history_key:
    'Inventory Imports upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_property_groups: 'Property Groups file upload outside of tI.  Scheduled upload.',
  history_dpi_property_groups_canceled_history_key: 'File processing has been canceled',
  history_dpi_property_groups_error_history_key:
    'An error occurred and Property Groups were not pushed',
  history_dpi_property_groups_inprogress_history_key:
    'Your file was successfully uploaded and your Property Groups are being imported.',
  history_dpi_property_groups_manual: 'Property Groups file upload outside of tI. Manual upload.',
  history_dpi_property_groups_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_property_groups_manual_error_history_key:
    'An error occurred and Property Groups were not pushed.',
  history_dpi_property_groups_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Property Groups are being imported.',
  history_dpi_property_groups_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_property_groups_manual_success_history_key:
    'Property Groups were successfully pushed.',
  history_dpi_property_groups_manual_waiting_history_key:
    'Property Groups upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_property_groups_pending_history_key: 'Your file is being uploaded.',
  history_dpi_property_groups_success_history_key: 'Property Groups were successfully pushed',
  history_dpi_property_groups_waiting_history_key:
    'Property Groups upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_push: 'CPC base bids uploaded outside trivago Intelligence. Scheduled upload',
  history_dpi_push_canceled_history_key: 'File processing has been canceled. ',
  history_dpi_push_error_history_key: 'An error occurred and bids were not pushed.',
  history_dpi_push_inprogress_history_key:
    'Your file was successfully uploaded and your Bids are being imported.',
  history_dpi_push_manual: 'CPC base bids uploaded outside trivago Intelligence. Manual upload',
  history_dpi_push_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_push_manual_error_history_key: 'An error occurred and bids were not pushed.',
  history_dpi_push_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Bids are being imported.',
  history_dpi_push_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_push_manual_success_history_key: 'All bids have been pushed.',
  history_dpi_push_manual_waiting_history_key:
    'Bid upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_push_pending_history_key: 'Your file is being uploaded.',
  history_dpi_push_success_history_key: 'All bids have been pushed.',
  history_dpi_push_waiting_history_key:
    'Bid upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_sl_bid_modifiers:
    'Sponsored Listings Bidding Modifiers file upload outside of tI. Scheduled upload.',
  history_dpi_sl_bid_modifiers_canceled_history_key: 'File processing has been canceled',
  history_dpi_sl_bid_modifiers_error_history_key:
    'An error occurred and Sponsored Listings Bidding Modifiers file upload was not pushed',
  history_dpi_sl_bid_modifiers_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings Bidding Modifiers are being imported.',
  history_dpi_sl_bid_modifiers_manual:
    'Sponsored Listing Bid Modifiers uploaded outside trivago Intelligence. Manual upload',
  history_dpi_sl_bid_modifiers_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_sl_bid_modifiers_manual_error_history_key:
    'An error occurred and Sponsored Listings Bidding Modifiers file were not pushed.',
  history_dpi_sl_bid_modifiers_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings Bidding Modifiers are being imported.',
  history_dpi_sl_bid_modifiers_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_sl_bid_modifiers_manual_success_history_key:
    'Sponsored Listings Bidding Modifiers file upload was successfully pushed.',
  history_dpi_sl_bid_modifiers_manual_waiting_history_key:
    'Sl Bid Modifiers upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_sl_bid_modifiers_pending_history_key: 'Your file is being uploaded.',
  history_dpi_sl_bid_modifiers_success_history_key:
    'Sponsored Listings Bidding Modifiers file upload was successfully pushed',
  history_dpi_sl_bid_modifiers_waiting_history_key:
    'Sl Bid Modifiers upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_sl_bids: 'Sponsored Listings bid file upload outside of tI. Scheduled upload.',
  history_dpi_sl_bids_canceled_history_key: 'File processing has been canceled',
  history_dpi_sl_bids_error_history_key:
    'An error occurred and Sponsored Listings bid file was not pushed',
  history_dpi_sl_bids_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings are being imported.',
  history_dpi_sl_bids_manual:
    'Sponsored Listings uploaded outside trivago Intelligence. Manual upload',
  history_dpi_sl_bids_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_sl_bids_manual_error_history_key:
    'An error occurred and Sponsored Listings bid file was not pushed.',
  history_dpi_sl_bids_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings are being imported.',
  history_dpi_sl_bids_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_sl_bids_manual_success_history_key:
    'Sponsored Listings bid file was successfully uploaded.',
  history_dpi_sl_bids_manual_waiting_history_key:
    'SL Bids upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_sl_bids_pending_history_key: 'Your file is being uploaded.',
  history_dpi_sl_bids_success_history_key: 'Sponsored Listings bid file was successfully uploaded',
  history_dpi_sl_bids_waiting_history_key:
    'SL Bids upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_sl_budget_cap:
    'Sponsored Listings Budget Caps file upload outside of tI. Scheduled upload.',
  history_dpi_sl_budget_cap_canceled_history_key: 'File processing has been canceled',
  history_dpi_sl_budget_cap_error_history_key:
    'An error occurred and Sponsored Listings Budget Caps file upload was not pushed.',
  history_dpi_sl_budget_cap_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings Budget Caps are being imported.',
  history_dpi_sl_budget_cap_manual:
    'Sponsored Listings Budget Caps file upload outside of tI. Manual upload.',
  history_dpi_sl_budget_cap_manual_canceled_history_key: 'File processing has been canceled',
  history_dpi_sl_budget_cap_manual_error_history_key:
    'An error occurred and Sponsored Listings Budget Caps file upload was not pushed',
  history_dpi_sl_budget_cap_manual_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings Budget Caps are being imported.',
  history_dpi_sl_budget_cap_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_sl_budget_cap_manual_success_history_key:
    'Sponsored Listings Budget Caps file upload was successfully pushed',
  history_dpi_sl_budget_cap_manual_waiting_history_key:
    'SL Budget Cap upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_sl_budget_cap_pending_history_key: 'Your file is being uploaded.',
  history_dpi_sl_budget_cap_success_history_key:
    'Sponsored Listings Budget Caps file upload was successfully pushed',
  history_dpi_sl_budget_cap_waiting_history_key:
    'SL Budget Cap upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_cpa_bid_modifiers:
    'CPA Bidding Modifiers file upload outside of tI. Scheduled upload.',
  history_dpi_cpa_bid_modifiers_canceled_history_key: 'File processing has been canceled',
  history_dpi_cpa_bid_modifiers_error_history_key:
    'An error occurred and CPA Bidding Modifiers file upload was not pushed',
  history_dpi_cpa_bid_modifiers_inprogress_history_key:
    'Your file was successfully uploaded and your CPA Bidding Modifiers are being imported.',
  history_dpi_cpa_bid_modifiers_manual:
    'CPA Bid Modifiers uploaded outside trivago Intelligence. Manual upload',
  history_dpi_cpa_bid_modifiers_manual_canceled_history_key: 'File processing has been canceled.',
  history_dpi_cpa_bid_modifiers_manual_error_history_key:
    'An error occurred and CPA Bidding Modifiers file were not pushed.',
  history_dpi_cpa_bid_modifiers_manual_inprogress_history_key:
    'Your file was successfully uploaded and your CPA Bidding Modifiers are being imported.',
  history_dpi_cpa_bid_modifiers_manual_pending_history_key: 'Your file is being uploaded.',
  history_dpi_cpa_bid_modifiers_manual_success_history_key:
    'CPA Bidding Modifiers file upload was successfully pushed.',
  history_dpi_cpa_bid_modifiers_manual_waiting_history_key:
    'CPA Bid Modifiers upload outside of tI is awaiting further input from Account Manager.',
  history_dpi_cpa_bid_modifiers_pending_history_key: 'Your file is being uploaded.',
  history_dpi_cpa_bid_modifiers_success_history_key:
    'CPA Bidding Modifiers file upload was successfully pushed',
  history_dpi_cpa_bid_modifiers_waiting_history_key:
    'CPA Bid Modifiers upload outside of tI is awaiting further input from Account Manager.',
  history_edit_bm_ui: 'CPC Bid Modifier edit',
  history_edit_bm_ui_canceled_history_key: 'File processing has been canceled.',
  history_edit_bm_ui_error_history_key: 'An error occurred and your Bid Modifier was not updated.',
  history_edit_bm_ui_inprogress_history_key: ' -',
  history_edit_bm_ui_pending_history_key: ' -',
  history_edit_bm_ui_success_history_key:
    'Your Bid Modifier was updated and is scheduled to be pushed.',
  history_cpa_edit_bm_ui: 'CPA Bid Modifier edit',
  history_cpa_edit_bm_ui_canceled_history_key: 'File processing has been canceled.',
  history_cpa_edit_bm_ui_error_history_key:
    'An error occurred and your Bid Modifier was not updated.',
  history_cpa_edit_bm_ui_inprogress_history_key: ' -',
  history_cpa_edit_bm_ui_pending_history_key: ' -',
  history_cpa_edit_bm_ui_success_history_key:
    'Your Bid Modifier was updated and is scheduled to be pushed.',
  history_edit_cpa_value_ui: 'CPA value edit',
  history_edit_cpa_value_ui_error_history_key:
    'An error occurred and your Bid CPC was not updated.',
  history_edit_cpa_value_ui_inprogress_history_key: 'Your CPA value edit is in progress. ',
  history_edit_cpa_value_ui_pending_history_key: 'CPA value edit is being uploaded.',
  history_edit_cpa_value_ui_success_history_key:
    'Your CPA value edit was successful and is scheduled to be pushed.',
  history_edit_cpc_ui: 'Bid CPC edit',
  history_edit_cpc_ui_canceled_history_key: 'File processing has been canceled.',
  history_edit_cpc_ui_error_history_key: 'An error occurred and your Bid CPC was not updated.',
  history_edit_cpc_ui_inprogress_history_key: ' - ',
  history_edit_cpc_ui_pending_history_key: ' - ',
  history_edit_cpc_ui_success_history_key:
    'Your Bid CPC was updated and is scheduled to be pushed.',
  history_edit_sl_bid_ui: 'SL Bid edit',
  history_edit_sl_bid_ui_canceled_history_key: 'File processing has been canceled.',
  history_edit_sl_bid_ui_error_history_key: 'An error occurred and your SL Bid was not updated.',
  history_edit_sl_bid_ui_inprogress_history_key: '-',
  history_edit_sl_bid_ui_pending_history_key: '-',
  history_edit_sl_bid_ui_success_history_key:
    'Your SL Bid was updated and is scheduled to be pushed.',
  history_file_bid_modifier: 'CPC bid modifier file upload',
  history_file_bid_modifier_canceled_history_key: 'File processing has been canceled.',
  history_file_bid_modifier_error_history_key:
    'An error occurred and your bid modifiers were not imported.',
  history_file_bid_modifier_inprogress_history_key:
    'Your file was successfully uploaded and your bid modifiers are being imported.',
  history_file_bid_modifier_pending_history_key: 'Your file is being uploaded.',
  history_file_bid_modifier_success_history_key:
    'Your bid modifiers were successfully imported and are scheduled to be pushed.',
  history_file_bidding: 'CPC base bid file upload',
  history_file_bidding_canceled_history_key: 'File processing has been canceled.',
  history_file_bidding_error_history_key: 'An error occurred and your bids were not imported.',
  history_file_bidding_inprogress_history_key:
    'Your file was successfully uploaded and your bids are being imported.',
  history_file_bidding_pending_history_key: 'Your file is being uploaded.',
  history_file_bidding_success_history_key:
    'Your bids were successfully imported and are scheduled to be pushed.',
  history_file_campaign_allocation: 'Campaign allocation file upload ',
  history_file_campaign_allocation_canceled_history_key: 'File processing has been canceled.',
  history_file_campaign_allocation_error_history_key:
    'An error occurred and your campaign allocations were not imported.',
  history_file_campaign_allocation_inprogress_history_key:
    'Your file was successfully uploaded and your campaign allocations are being imported. ',
  history_file_campaign_allocation_pending_history_key: 'Your file is being uploaded.',
  history_file_campaign_allocation_success_history_key:
    'Your campaign allocations were successfully imported and are scheduled to be pushed.',
  history_file_cpa_bm: 'CPA Bid Modifier file upload',
  history_file_cpa_bm_canceled_history_key: 'File processing has been canceled.',
  history_file_cpa_bm_error_history_key:
    'An error occurred and your CPA Bid Modifiers were not imported.',
  history_file_cpa_bm_inprogress_history_key:
    'Your file was successfully uploaded and your CPA Bid Modifiers are being imported.',
  history_file_cpa_bm_pending_history_key: 'Your file is being uploaded.',
  history_file_cpa_bm_success_history_key:
    'Your CPA Bid Modifiers were successfully imported and are scheduled to be pushed.',
  history_push_cpa_bm: 'CPA Bid Modifier push',
  history_push_cpa_canceled_history_key: 'File processing has been canceled.',
  history_push_cpa_bm_error_history_key:
    'An error occurred and your CPA Bid Modifiers were not pushed.',
  history_push_cpa_bm_inprogress_history_key: 'Your CPA Bid Modifiers are being pushed.',
  history_push_cpa_bm_pending_history_key: 'Your file is being uploaded.',
  history_push_cpa_bm_success_history_key: 'Your CPA Bid Modifiers were successfully pushed.',
  history_file_cpa_value: 'CPA value file upload',
  history_file_cpa_value_canceled_history_key: 'File processing has been canceled.',
  history_file_cpa_value_error_history_key:
    'An error occurred and your CPA values were not imported.',
  history_file_cpa_value_inprogress_history_key:
    'Your file was successfully uploaded and your CPA values are being imported.',
  history_file_cpa_value_pending_history_key: 'Your file is being uploaded.',
  history_file_cpa_value_success_history_key:
    'Your CPA values were successfully imported and are scheduled to be pushed.',
  history_file_property_group_id: 'Property Groups file upload',
  history_file_property_group_id_canceled_history_key: 'File processing has been canceled',
  history_file_property_group_id_error_history_key:
    'An error occurred and your Property Groups were not imported.',
  history_file_property_group_id_inprogress_history_key:
    'Your file was successfully uploaded and your Property Groups are being imported.',
  history_file_property_group_id_pending_history_key: 'Your file is being uploaded.',
  history_file_property_group_id_success_history_key:
    'Your Property Groups were successfully imported and are scheduled to be pushed.',
  history_file_sl_bid: 'Sponsored Listings bid file upload',
  history_file_sl_bid_canceled_history_key: 'File processing has been canceled.',
  history_file_sl_bid_error_history_key:
    'An error occurred and your Sponsored Listings bids were not imported.',
  history_file_sl_bid_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings bids are being imported.',
  history_file_sl_bid_pending_history_key: 'Your file is being uploaded.',
  history_file_sl_bid_success_history_key:
    'Your Sponsored Listings bids were successfully imported and are scheduled to be pushed.',
  history_file_sl_bm: 'Sponsored Listings Bidding Modifiers file upload',
  history_file_sl_bm_canceled_history_key: 'File processing has been canceled.',
  history_file_sl_bm_error_history_key:
    'An error occurred and your Sponsored Listings Bid Modifiers were not imported.',
  history_file_sl_bm_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings Bid Modifiers are being imported.',
  history_file_sl_bm_pending_history_key: ' Your file is being uploaded.',
  history_file_sl_bm_success_history_key:
    'Your Sponsored Listings Bid Modifiers were successfully imported and are scheduled to be pushed.',
  history_file_sl_budget_cap: 'Sponsored Listings Budget Cap file upload',
  history_file_sl_budget_cap_canceled_history_key: 'File processing has been canceled.',
  history_file_sl_budget_cap_error_history_key:
    'An error occurred and your Sponsored Listings budget caps were not imported.',
  history_file_sl_budget_cap_inprogress_history_key:
    'Your file was successfully uploaded and your Sponsored Listings budget caps are being imported.',
  history_file_sl_budget_cap_pending_history_key: 'Your file is being uploaded.',
  history_file_sl_budget_cap_success_history_key:
    'Your Sponsored Listings budget caps were successfully imported and are scheduled to be pushed.',
  history_header_title: 'History',
  history_no_files: 'No files for this event',
  history_other_events_tooltip: 'Inventory, Combined Bidding',
  history_property_allocation_push: 'Property allocation push',
  history_property_allocation_push_error_history_key:
    'An error occurred and your property allocations were not imported.',
  history_property_allocation_push_inprogress_history_key:
    'Your file was successfully uploaded and your property allocations are being imported.',
  history_property_allocation_push_pending_history_key: 'Your file is being uploaded.',
  history_property_allocation_push_success_history_key:
    'Your property allocations were successfully pushed.',
  history_property_allocation_push_waiting_history_key:
    'Your property allocation push is awaiting further input from Account Manager.',
  history_push: 'Manual CPC base bids push',
  history_push_bm: 'CPC bid modifiers push',
  history_push_bm_error_history_key:
    'An error occurred and your Bid Modifier values were not pushed.',
  history_push_bm_inprogress_history_key: 'Your Bid Modifier values are being pushed.',
  history_push_bm_pending_history_key: 'Your file is being uploaded.',
  history_push_bm_success_history_key: 'Your Bid Modifier values were successfully pushed.',
  history_push_bm_waiting_history_key:
    'Your Bid Modifiers push is awaiting further input from Account Manager.',
  history_push_error_history_key: 'An error occurred and manual bids were not pushed live.',
  history_push_inprogress_history_key:
    'The push has been initiated and all manual bids are pending.',
  history_push_pending_history_key: 'Your file is being uploaded.',
  history_push_property_group_id: 'Property Groups push',
  history_push_property_group_id_error_history_key:
    'An error occurred and Property Groups were not pushed live.',
  history_push_property_group_id_inprogress_history_key:
    'The Property Groups push has been initiated and is in progress.',
  history_push_property_group_id_pending_history_key:
    'The Property Groups push has been initiated and is pending.',
  history_push_property_group_id_success_history_key: 'All Property Groups have been pushed.',
  history_push_property_group_id_waiting_history_key:
    'Your Property Groups push is awaiting further input from Account Manager.',
  history_push_sl_bid: 'Sponsored Listings bid push',
  history_push_sl_bid_error_history_key:
    'An error occurred and Sponsored Listings bids were not pushed.',
  history_push_sl_bid_inprogress_history_key:
    'The Sponsored Listings bid push has been initiated and is in progress.',
  history_push_sl_bid_pending_history_key:
    'The Sponsored Listings bid push has been initiated and is pending.',
  history_push_sl_bid_success_history_key:
    'All Sponsored Listings bids have successfully been pushed.',
  history_push_sl_bid_waiting_history_key:
    'The Sponsor Listings bid push is awaiting further input from Account Manager.',
  history_push_sl_bm: 'SL Bid modifiers push',
  history_push_sl_bm_error_history_key:
    'An error occurred and your SL Bid Modifier values were not pushed.',
  history_push_sl_bm_inprogress_history_key: 'Your SL Bid Modifier values are being pushed.',
  history_push_sl_bm_pending_history_key:
    'The Sponsored Listings bid modifier push has been initiated and is pending.',
  history_push_sl_bm_success_history_key: 'Your SL Bid Modifier values were successfully pushed.',
  history_push_sl_bm_waiting_history_key:
    'Your SL Bid Modifiers push is awaiting further input from Account Manager.',
  history_push_sl_budget_cap: 'Sponsored Listings Budget Cap push',
  history_push_sl_budget_cap_error_history_key:
    'An error occurred and Sponsored Listings budget caps were not pushed live.',
  history_push_sl_budget_cap_inprogress_history_key:
    'The Sponsored Listings budget caps push has been initiated and is in progress.',
  history_push_sl_budget_cap_pending_history_key:
    'The Sponsored Listings budget caps push has been initiated and is pending.',
  history_push_sl_budget_cap_success_history_key:
    'Your Sponsored Listings budget caps have successfully been pushed.',
  history_push_sl_budget_cap_waiting_history_key:
    'The Sponsor Listings Budget Cap push is awaiting further input from Account Manager.',
  history_push_success_history_key: 'All manual bids have been pushed. ',
  history_push_waiting_history_key:
    'All manual bids are awaiting further input from Account Manager.',
  history_revert_bm_ui: 'Revert all CPC bid modifier changes',
  history_revert_bm_ui_error_history_key:
    'An error occurred and all global bid modifier changes made today were not canceled. Bid modifiers have not been reverted to their previous values.',
  history_revert_bm_ui_inprogress_history_key: '-',
  history_revert_bm_ui_pending_history_key: '-',
  history_revert_bm_ui_success_history_key:
    'All global bid modifier changes that were made today have been canceled, and bid modifiers have been reverted to their previous values.',
  history_revert_pending_campaign_allocation_ui: 'Campaign allocation revert',
  history_revert_pending_campaign_allocation_ui_error_history_key:
    'An error occurred and your campaign allocations were not reverted.',
  history_revert_pending_campaign_allocation_ui_inprogress_history_key:
    'Your campaign allocation revert is in progress. ',
  history_revert_pending_campaign_allocation_ui_pending_history_key:
    'Your campaign allocation revert is pending.',
  history_revert_pending_campaign_allocation_ui_success_history_key:
    'Your campaign allocation revert was successfully processed.',
  history_revert_pending_cpa_value_ui: 'CPA value revert',
  history_revert_pending_cpa_value_ui_error_history_key:
    'An error occurred and your CPA values were not reverted.',
  history_revert_pending_cpa_value_ui_inprogress_history_key:
    'Your CPA value revert is in progress.',
  history_revert_pending_cpa_value_ui_pending_history_key: 'Your CPA value revert is pending.',
  history_revert_pending_cpa_value_ui_success_history_key:
    'Your CPA value revert was successfully processed.',
  history_revert_pending_sl_bid: 'Sponsored Listings bid revert',
  history_revert_pending_sl_bid_error_history_key:
    'An error occurred and your Sponsored Listings bids were not reverted.',
  history_revert_pending_sl_bid_inprogress_history_key:
    'Your Sponsored Listings bid revert is in progress.',
  history_revert_pending_sl_bid_modifiers: 'Sponsored Listings Bid Modifiers revert',
  history_revert_pending_sl_bid_modifiers_error_history_key:
    'An error occurred and your Sponsored Listings Bid Modifiers were not reverted.',
  history_revert_pending_sl_bid_modifiers_inprogress_history_key:
    'Your Sponsored Listings Bid Modifiers revert is in progress.',
  history_revert_pending_sl_bid_modifiers_pending_history_key:
    'Your Sponsored Listings Bid Modifiers revert is pending.',
  history_revert_pending_sl_bid_modifiers_success_history_key:
    'Your Sponsored Listings Bid Modifiers revert was successfully processed.',
  history_revert_pending_sl_bid_pending_history_key:
    'Your Sponsored Listings bid revert is pending.',
  history_revert_pending_sl_bid_success_history_key:
    'Your Sponsored Listings bid revert was successfully processed.',
  history_revert_pending_sl_budget_cap: 'Sponsored Listings Budget Cap revert',
  history_revert_pending_sl_budget_cap_error_history_key:
    'An error occurred and your Sponsored Listings budget caps were not reverted.',
  history_revert_pending_sl_budget_cap_inprogress_history_key:
    'Your Sponsored Listings budget cap revert is in progress.',
  history_revert_pending_sl_budget_cap_pending_history_key:
    'Your Sponsored Listings budget cap revert is pending.',
  history_revert_pending_sl_budget_cap_success_history_key:
    'Your Sponsored Listings budget cap revert was successfully processed.',
  history_revert_property_groups_ui: 'Property Groups revert',
  history_revert_property_groups_ui_error_history_key:
    'An error occurred and your Property Groups were not reverted.',
  history_revert_property_groups_ui_inprogress_history_key:
    'Your Property Groups revert is in progress.',
  history_revert_property_groups_ui_pending_history_key: 'Your Property Groups revert is pending.',
  history_revert_property_groups_ui_success_history_key:
    'Your Property Groups revert was successfully processed.',
  history_revert_ui: 'Revert all CPC base bid changes',
  history_revert_ui_error_history_key:
    'An error occurred and all global bid changes made today were not canceled. Bids have not been reverted to their previous amount.',
  history_revert_ui_inprogress_history_key: ' - ',
  history_revert_ui_pending_history_key: ' - ',
  history_revert_ui_success_history_key:
    'All global bid changes that were made today have been canceled, and bids have been reverted to their previous amount.',
  history_source_file: 'Source file',
  history_source_file_unexpectedly_unavailable: 'Source file unavailable',
  history_summary_file: 'Summary file',
  history_summary_file_unexpectedly_unavailable: 'Summary file unavailable',
  history_toolbar_filter_show_all: 'All events',
  history_toolbar_search_placeholder: 'Search for an Event ID',
  include_non_performing_data_tooltip_hotel_view: `Showing items that have only received "hotel impressions" and haven’t received clicks, bookings, or other actions from users. These items are not categorized by bidding type and are always displayed in their entirety when toggled on.`,
  include_non_performing_data_tooltip_hotel_details_view: `Also showing the days that received "hotel impressions" but haven’t received clicks, bookings, or other actions from users based on the hotel.`,
  include_non_performing_data_toggle_label: 'Include non-performing data',
  inactive_matched: 'Inactive matched',
  inactive_unmatched: 'Inactive unmatched',
  inprogress_history_key: 'In progress',
  locale_AA: 'Arab World',
  locale_AE: 'UAE',
  locale_AR: 'Argentina',
  locale_AT: 'Austria',
  locale_AU: 'Australia',
  locale_BE: 'Belgium',
  locale_BG: 'Bulgaria',
  locale_BR: 'Brazil',
  locale_CA: 'Canada',
  locale_CH: 'Switzerland',
  locale_CL: 'Chile',
  locale_CN: 'China',
  locale_CO: 'Colombia',
  locale_CZ: 'Czech Republic',
  locale_DE: 'Germany',
  locale_DK: 'Denmark',
  locale_EC: 'Ecuador',
  locale_ES: 'Spain',
  locale_FI: 'Finland',
  locale_FR: 'France',
  locale_GR: 'Greece',
  locale_HK: 'Hong Kong',
  locale_HR: 'Croatia',
  locale_HU: 'Hungary',
  locale_ID: 'Indonesia',
  locale_IE: 'Ireland',
  locale_IL: 'Israel',
  locale_IN: 'India',
  locale_IT: 'Italy',
  locale_JP: 'Japan',
  locale_KR: 'South Korea',
  locale_MX: 'Mexico',
  locale_MY: 'Malaysia',
  locale_NG: 'Nigeria',
  locale_NL: 'The Netherlands',
  locale_NO: 'Norway',
  locale_NZ: 'New Zealand',
  locale_PE: 'Peru',
  locale_PH: 'The Philippines',
  locale_PL: 'Poland',
  locale_PT: 'Portugal',
  locale_RO: 'Romania',
  locale_RS: 'Serbia',
  locale_RU: 'Russia',
  locale_SE: 'Sweden',
  locale_SG: 'Singapore',
  locale_SI: 'Slovenia',
  locale_SK: 'Slovakia',
  locale_TH: 'Thailand',
  locale_TR: 'Turkey',
  locale_TW: 'Taiwan',
  locale_UK: 'UK',
  locale_US: 'USA',
  locale_UY: 'Uruguay',
  locale_VN: 'Vietnam',
  locale_ZA: 'South Africa',
  member_partner_empty_partner_list: 'There are no partners associated with the selected member ',
  member_partner_header_actions: 'Actions',
  member_partner_header_name: 'Partner Name',
  member_partner_header_partner_id: 'Partner ID',
  member_partner_level_selector_bidding_access: 'Bidding Access',
  member_partner_level_selector_developer: 'Developer Access',
  member_partner_level_selector_non_advertiser: 'Non-Advertiser',
  member_partner_level_selector_read_access: 'Read Access',
  member_partner_level_updated: "Member's partner level has been updated",
  member_partner_level_title: 'Member Partner Level',
  menu_link_name_admin: 'Admin',
  menu_link_name_analytics: 'Analytics',
  menu_link_name_bidding_suggestions: 'Opportunities',
  menu_link_name_campaigns: 'Campaigns',
  menu_link_name_dashboard: 'Dashboard',
  menu_link_name_history: 'History',
  menu_link_name_reports: 'Reports',
  menu_link_name_resource_center: 'Resource Center',
  menu_link_name_resource_center_tooltip:
    'Our Resource Center is packed with useful articles that will help you manage your campaigns and optimize your performance on trivago. ',
  menu_link_name_sponsored: 'Sponsored Listings',
  menu_link_name_sponsored_analytics: 'Analytics',
  menu_link_name_sponsored_campaigns: 'Campaigns',
  menu_link_name_campaigns_on_boarding:
    'You can now apply bid modifiers to CPA bidding types to target specific traffic and enhance bidding efficiency.',
  menu_link_name_sponsored_on_boarding:
    'In this section, you can now monitor the performance of your Sponsored Listings campaigns on an item and point of sale level. <a href="https://resourcecenter.trivago.com/optimizing-your-performance/sponsored-listings/">Click here</a> to read more about Sponsored Listings and how they can boost your performance on trivago. ',
  'multi-select_dropdown_clear_selection': 'Clear selection',
  'multi-select_dropdown_select_all': 'Select all',
  notification_partial_success: '<strong>Partial success.</strong> ',
  notification_settings_option_file_base_bid_upload_error: 'Bid import failure ',
  notification_settings_option_file_base_bid_upload_success: 'Bid import success',
  notification_settings_option_file_base_bid_upload_warning: 'Bid import partial success ',
  notification_settings_option_file_bid_modifiers_error: 'Bid Modifiers failure ',
  notification_settings_option_file_bid_modifiers_success: 'Bid Modifiers success ',
  notification_settings_option_file_bid_modifiers_warning: 'Bid Modifiers partial success',
  notification_settings_option_file_campaign_allocations_error:
    'Campaign Allocations import failure',
  notification_settings_option_file_campaign_allocations_success:
    'Campaign Allocations import success',
  notification_settings_option_file_campaign_allocations_warning:
    'Campaign Allocations import partial success',
  notification_settings_option_file_cpa_values_error: 'CPA Values import failure',
  notification_settings_option_file_cpa_values_success: 'CPA Values import success',
  notification_settings_option_file_cpa_values_warning: 'CPA Values import partial success',
  notification_settings_option_file_property_groups_error: 'Property Groups failure',
  notification_settings_option_file_property_groups_success: 'Property Groups success ',
  notification_settings_option_file_property_groups_warning: 'Property Groups partial success ',
  notification_settings_option_file_sl_bid_error: 'SL Bids import failure',
  notification_settings_option_file_sl_bid_success: 'SL Bids import success',
  notification_settings_option_file_sl_bid_warning: 'SL Bids import partial success',
  notification_settings_option_file_sl_bm_error: 'SL Modifiers import failure',
  notification_settings_option_file_sl_bm_success: 'SL Modifiers import success',
  notification_settings_option_file_sl_bm_warning: 'SL Modifiers import partial success',
  notification_settings_option_file_sl_budget_cap_error: 'SL Budget Caps import failure',
  notification_settings_option_file_sl_budget_cap_success: 'SL Budget Caps import success',
  notification_settings_option_file_sl_budget_cap_warning: 'SL Budget Caps import partial success',
  notifications_clear_all: 'Clear all',
  notifications_fetch_error:
    'Unable to load notifications. Please reload the page to display notifications',
  notifications_get_error:
    'An error occurred and we were unable to fetch your notifications. Please try again later. ',
  notifications_mark_all_as_read: 'Mark all as read',
  notifications_mark_notification_as_read: 'Mark as read',
  notifications_mark_notification_as_unread: 'Mark as unread',
  notifications_mark_read_all_error:
    'Unable to mark notifications as read. Please try again or reload the page',
  notifications_mark_read_error:
    'Unable to mark notification as read. Please try again or reload the page',
  notifications_no_results: "You haven't received any notification yet. ",
  notifications_save_settings_error:
    'Unable to save notification settings. Please try again or reload the page',
  notifications_settings_error:
    'Unable to load notification settings. Please reload the page to display notification settings',
  notifications_settings_fetch_error:
    'Unable to load notification settings. Please reload the page to display notification settings',
  notifications_settings_receive_email: 'Receive email',
  notifications_settings_save_error:
    'An error occurred and your changes were not saved. Please try again.',
  notifications_settings_title: 'Notification settings',
  notifications_title: 'Notifications',
  partial_success_history_details: 'Check summary file for error report',
  partial_success_history_key: 'Partial success',
  pending_history_key: 'Pending',
  prevent_navigation_prompt_message: 'Are you sure you want to leave?',
  process_base_bids_file_action_error:
    'An error occurred and your base bid file was not imported. Please try again or contact your Account Manager for further assistance. ',
  process_base_bids_file_action_success:
    'Your base bid file was successfully imported and is scheduled to be pushed.',
  process_base_bids_file_action_warning:
    'There was an error and only part of your base bid file could be imported. This part is scheduled to be pushed.',
  process_bid_modifiers_file_action_error:
    'An error occurred and your Bid Modifier file was not imported. Please try again or contact your Account Manager for further assistance. ',
  process_bid_modifiers_file_action_success:
    'Your Bid Modifier file was successfully imported and is scheduled to be pushed.',
  process_bid_modifiers_file_action_warning:
    'There was an error and only part of your Bid Modifier file could be imported. This part is scheduled to be pushed.',
  process_campaign_allocations_file_action_error:
    'An error occurred and your Campaign Allocations file was not imported. Please try again or contact your Account Manager for further assistance.',
  process_campaign_allocations_file_action_success:
    'Your Campaign Allocations file was successfully imported and is scheduled to be pushed.',
  process_campaign_allocations_file_action_warning:
    'There was an error and only part of your Campaign Allocations file could be imported. This part is scheduled to be pushed.',
  process_cpa_values_file_action_error:
    'An error occurred and your CPA Values file was not imported. Please try again or contact your Account Manager for further assistance.',
  process_cpa_values_file_action_success:
    'Your CPA Values file was successfully imported and is scheduled to be pushed.',
  process_property_group_file_action_error:
    'An error occurred and your Property Groups file was not imported. Please try again or contact your Account Manager for further assistance. ',
  process_property_group_file_action_success:
    'Your Property Groups file was successfully imported and is scheduled to be pushed.',
  process_property_group_file_action_warning:
    'There was an error and only part of your Property Groups file could be imported. This part is scheduled to be pushed.',
  process_sl_bid_file_action_error:
    'An error occurred and your SL Bids file was not imported. Please try again or contact your Account Manager for further assistance.',
  process_sl_bid_file_action_success:
    'Your SL Bids file was successfully imported and is scheduled to be pushed.',
  process_sl_bm_file_action_success:
    'Your SL Bid Modifiers file was successfully imported and is scheduled to be pushed.',
  process_sl_bm_file_action_warning:
    'There was an error and only part of your SL Bid Modifiers file could be imported. This part is scheduled to be pushed.',
  process_sl_budget_cap_file_action_error:
    'An error occurred and your SL Budget Caps file was not imported. Please try again or contact your Account Manager for further assistance.',
  process_sl_budget_cap_file_action_success:
    'Your SL Budget Caps file was successfully imported and is scheduled to be pushed.',
  property_groups: 'Property Groups',
  reports_active: 'Generate',
  reports_active_reports: 'Active reports ',
  reports_back: 'Back to My reports',
  reports_bid_modifiers: 'Bid Modifier property report',
  reports_bm_pos_combined_view: 'Bid Modifier point of sale report (combined)',
  reports_bm_pos_report: 'Bid Modifier point of sale report ',
  reports_bm_property_combined_view: 'Bid Modifier property report (combined)',
  reports_bm_property_group_suggestion_report: 'Bid Modifier Property Groups suggestions report ',
  reports_bml_champion_report: 'BML vs Champion report',
  reports_clickshare_report: 'Click share report ',
  reports_clickshare_report_combined: 'Click share report (combined)',
  reports_clickshare_report_combined_aggregated: 'Aggregated click share report (combined) ',
  reports_cpa_cost_combined_report: 'CPA Cost report (combined) ',
  reports_cpa_cost_report: 'CPA Cost report',
  reports_cpa_fallback_report: 'CPA Adjustment report ',
  reports_cpa_predictions_report: 'CPA Suggestions report',
  reports_documentation_link: 'More information',
  reports_enable_modal_enable: 'Activate',
  reports_enable_modal_folder: 'Folder name',
  reports_enable_modal_folder_placeholder: 'Please enter the name of the folder here.',
  reports_enable_modal_frequency: 'Report frequency ',
  reports_enable_modal_title: 'Activate report',
  reports_export_report: 'Export report',
  reports_fetch_error: 'An error occurred while fetching your reports. Please try again. ',
  reports_fetch_error_by_type:
    'An error occurred while fetching the details of your report. Please try again. ',
  reports_generated_daily: 'Generated daily',
  reports_generated_monthly: 'Generated monthly',
  reports_generated_weekly: 'Generated weekly',
  reports_hotel_imp_impressions: 'Hotel Impressions report',
  reports_inactive: "Don't generate",
  reports_inactive_reports: 'Inactive reports ',
  reports_inventory_matched_active_report: 'Active matched inventory report ',
  reports_inventory_matched_inactive_report: 'Inactive matched inventory report',
  reports_inventory_unmatched_active_report: 'Active unmatched inventory report',
  reports_inventory_unmatched_inactive_report: 'Inactive unmatched inventory report',
  reports_item_combined_view: 'Property report (combined)',
  reports_item_export: 'Property report',
  reports_list: 'Reports list ',
  reports_meta_performance_combined: 'Meta Performance report (combined)',
  reports_meta_performance_combined_v1_1: 'Meta Performance report (combined) v1.1',
  reports_meta_performance_combined_v2: 'Meta Performance report v2 (combined)',
  reports_meta_performance_combined_v2_1: 'Meta Performance report v2.1 (combined)',
  reports_meta_performance_integrity_check_combined:
    'Meta Performance Integrity Check report (combined)',
  reports_meta_performance_integrity_check_report: 'Meta Performance Integrity Check report',
  reports_meta_performance_pos: 'Meta Performance Point of Sale report ',
  reports_meta_performance_pos_combined: 'Meta Performance Point of Sale report (combined)',
  reports_meta_performance_report: 'Meta Performance report',
  reports_meta_performance_report_v1_1: 'Meta Performance report v1.1',
  reports_meta_performance_report_v2: 'Meta Performance report v2 ',
  reports_meta_performance_report_v2_1: 'Meta Performance report v2.1',
  reports_meta_performance_report_v3_1: 'Meta Performance report v3.1',
  reports_modal_input_error_maximum_length:
    'The folder name must be between 2-40 characters in length. ',
  reports_modal_input_error_minimum_length:
    'The folder name must be between 2-40 characters in length. ',
  reports_modal_input_error_not_alphanumeric:
    'The folder name cannot contain any special characters. Please only use letters, numbers, and underscores. ',
  reports_modal_tooltip_folder:
    'This will be the name of the folder where reports will be stored on the SFTP. ',
  reports_my_reports: 'My reports',
  reports_pos_combined_view: 'Point of sale report (combined)',
  reports_pos_report: 'Point of sale report',
  reports_property_group_suggestion_report: 'Property Groups suggestions report',
  reports_rac_report: 'RAC report',
  reports_ras_pos_report_combined_v1: 'RAS POS report v1.0 (combined)',
  reports_ras_pos_report_v1: 'RAS POS report v1.0',
  reports_ras_report: 'RAS report',
  reports_rate_insights_combined_report_v2: 'Rate insights report v2 (combined)',
  reports_rate_insights_report_v2: 'Rate insights report v2',
  reports_report_date: 'Date of creation',
  reports_sl_bid_suggestions_report_v1: 'Sponsored Listing Bid Suggestions report',
  reports_sl_bm_combined_report: 'Sponsored Listing Bid Modifier (combined)',
  reports_sl_bm_report: 'Sponsored Listings Bid Modifier report',
  reports_sl_bm_report_agency: 'Sponsored Listings Bid Modifier agency report',
  reports_table_no_result_message:
    "It seems that you don't have any reports enabled at this time. Please contact your Account Manager to start activating reports now. ",
  reports_update_error: 'An error occurred while updating your reports. Please try again. ',
  reset_default_dashboard_modal_body:
    'By resetting to the default dashboard, you are removing any changes that were made to the current dashboard.',
  reset_default_dashboard_modal_title: 'Are you sure you want to reset?',
  revert_bid_changes_bids_subtitle: 'Cancel all base bid changes that are scheduled to be pushed.',
  revert_bid_changes_bids_title: '<b>Revert base bid changes</b>',
  revert_bid_changes_campaign_allocation_subtitle:
    'Cancel all campaign allocation files and related actions that are scheduled to be pushed.',
  revert_bid_changes_campaign_allocation_title: '<b>Revert campaign allocation changes</b>',
  revert_bid_changes_cpa_value_subtitle:
    'Cancel all pending CPA value changes that are scheduled to be pushed.',
  revert_bid_changes_cpa_value_title: '<b>Revert CPA value changes</b>',
  revert_bid_changes_modifiers_subtitle:
    'Cancel all Bid Modifier changes that are scheduled to be pushed.',
  revert_bid_changes_modifiers_title: '<b>Revert Bid Modifier changes</b>',
  revert_bid_changes_property_groups_subtitle:
    'Cancel all Property Group changes that are scheduled to be pushed.',
  revert_bid_changes_property_groups_title: '<b>Revert Property Group changes</b>',
  selector_bar_compare: 'Compare ($$ periods selected)',
  selector_bar_period: '$$ days ($$)',
  shown_metrics_dropdown_disabled_tooltip: 'This feature is currently disabled',
  shown_metrics_dropdown_selection_error: 'You must select at least one metrics column',
  shown_metrics_onboarding_tooltip:
    'Customize your table columns to show the metrics relevant to you and hide those that are not.',
  shown_metrics_selector_label: 'Hide/Show column',
  shown_metrics_selector_search_placeholder: 'Search for metrics',
  simple_columns_error:
    'Unable to load table columns. Please reload the page to display table data',
  simple_count_error: 'Unable to load rows count. Please reload the page to display rows count',
  simple_rows_error: 'Unable to load row data. Please reload the page to display table data',
  'sl-bm-report-aggregated': 'Sponsored Listings Bid Modifier report (combined)',
  'sl-bm-report-aggregated-agency': 'Sponsored Listings Bid Modifier agency report (combined)',
  sl_analytics_control_panel_current_sl_bid_cpc: 'SL Bid CPC',
  sponsored_campaigns__title: 'My campaigns',
  sponsored_campaigns__upload_base_bid: 'Upload base bid file',
  sponsored_campaigns__upload_base_bid_file_error_invalid_locales:
    "<strong>Oops!</strong> You've included an invalid point of sale in your file. Please remove it and try again.",
  sponsored_campaigns__upload_bid_modifier: 'Upload Bid Modifier file',
  sponsored_campaigns__upload_bid_modifier_file_error_invalid_locales:
    "<strong>Oops!</strong> You've included an invalid point of sale in your file. Please remove it and try again.",
  sponsored_campaigns__upload_budget_cap: 'Upload budget cap file',
  sponsored_campaigns__upload_budget_cap_file_error_invalid_locales:
    "<strong>Oops!</strong> You've included an invalid point of sale in your file. Please remove it and try again.",
  sponsored_home_dimensions: 'Primary dimensions',
  sponsored_home_export_report: 'Export report',
  sponsored_home_hotel_view: 'Hotels',
  sponsored_home_pos_view: 'Points of sale',
  sponsored_home_search_item_hotels: 'Search property name or partner reference',
  sponsored_home_search_item_pos: 'Search point of sale',
  sponsored_home_time_view: 'Time period',
  sponsored_hotel_view_no_results:
    'Data not available for the period or point of sale selected. <a href="https://resourcecenter.trivago.com/optimizing-your-performance/sponsored-listings/">Click here</a> to read more about Sponsored Listings and how they can boost your performance on trivago.',
  sponsored_pos_view_no_results:
    'Data not available for the period or point of sale selected. <a href="https://resourcecenter.trivago.com/optimizing-your-performance/sponsored-listings/">Click here</a> to read more about Sponsored Listings and how they can boost your performance on trivago. ',
  sponsored_revert_base_bids_subtitle:
    'Cancel all Sponsored Listings bid changes that are scheduled to be pushed.',
  sponsored_revert_base_bids_title: '<b>Revert Sponsored Listings bid changes</b>',
  sponsored_revert_budget_cap_subtitle:
    'Cancel all Sponsored Listings budget cap changes that are scheduled to be pushed.',
  sponsored_revert_budget_cap_title: '<b>Revert Sponsored Listings budget cap changes</b>',
  sponsored_revert_modal_info_base_bids:
    'Remove all pending Sponsored Listings bid changes that are scheduled to be pushed.',
  sponsored_revert_modal_info_budget_cap:
    'Remove all pending Sponsored Listings budget cap changes that are scheduled to be pushed.',
  sponsored_revert_modal_info_modifiers:
    'Remove all pending Sponsored Listings Bid Modifiers changes that are scheduled to be pushed.',
  sponsored_revert_modal_title_info_base_bids: 'Revert Sponsored Listings bid changes',
  sponsored_revert_modal_title_info_budget_cap: 'Revert Sponsored Listings budget cap changes',
  sponsored_revert_modal_title_info_modifiers: 'Revert Sponsored Listings Bid Modifier changes',
  sponsored_revert_modifiers_subtitle:
    'Cancel all Sponsored Listings Bid Modifier changes that are scheduled to be pushed.',
  sponsored_revert_modifiers_title: '<b>Revert Sponsored Listings Bid Modifier changes</b>',
  sponsored_toolbar_export_performance_grid: 'Performance grid for all properties (.csv)',
  sponsored_toolbar_export_performance_grid_excel: 'Performance grid for all properties (.xlsx)',
  success_history_key: 'Success',
  target_cpa_comparator_close: '<strong>Close to</strong> your Target CPA',
  target_cpa_comparator_high: '<strong>High</strong> compared to your Target CPA',
  target_cpa_comparator_low: '<strong>Low</strong> compared to your Target CPA',
  time_range_aggregation_day: 'Day',
  time_range_aggregation_month: 'Month',
  time_range_aggregation_quarter: 'Quarter',
  time_range_aggregation_week: 'Week',
  // If you change time_period translations then you must change the periodFromEn function in src/helpers/utils/format-date.ts
  time_period_previous_period: 'Previous period',
  time_period_year_over_year: 'Period Year over Year',
  time_period_year_over_2_years: 'Period Year over 2 Years',
  timeline_metric_dropdown_no_results: 'Metric not found',
  timeline_metric_dropdown_search_dashboard: 'Search metrics',
  timeline_metric_dropdown_selected: 'Selected',
  unknown: 'Unknown',
  upload_bids_campaign_allocation: 'Upload a campaign allocations file',
  upload_bids_container_upload_bids_field: 'Upload',
  upload_bids_cpa_values: 'Upload a CPA value file',
  upload_bids_file_error_invalid_locales:
    "<strong>Oops!</strong> You've included an invalid point of sale in your file. Please remove it and try again.",
  upload_bids_file_success: '<strong>Success!</strong> Your file has been uploaded.',
  upload_property_groups_file_error_invalid_locales:
    "<strong>Oops!</strong> You've included an invalid point of sale in your file. Please remove it and try again.",
  view_fetch_error: 'Oops! Something went wrong. Please try again later. ',
  waiting_history_key: 'Waiting',
  widget_columns_error:
    'Unable to load widget columns. Please reload the page to display widget data',
  widget_data_error: 'Unable to load widget data. Please reload the page to display widget data',
  max_cpc_bids_cap_error: 'An error occurred and we were unable to fetch max bids caps.',
  inventory_file_upload:
    '<strong>Important Notice</strong> Please be aware that partial uploads are not supported directly through tI. By continuing with this full upload, it will remove all previous inventory and replace it with the newly uploaded entries. This action is irreversible.',
  inventory_file_upload_modal_title: 'Upload Type',
  upload_bids_inventory_file: 'Upload an inventory file',
  inventory_file_upload_nonvalidated_success:
    'The inventory file has been uploaded and is currently undergoing validation. To review the status of the inventory upload, go to History and select Other Events.',
  pop_custom_starting_date: 'Custom starting date',
  pop_custom_starting_date_short: 'Custom',
  absolute_relative_toggle_disabled_message:
    'Please select max. 2 periods to see absolute & relative changes',
  bid_form_cpc_set_to: 'Set to',
  bid_form_cpc_change_by: 'Change by',
  bid_form_cpc_change_by_percentage: 'Change by percentage',
  confirm_bid_update: 'Confirm bid update',
  apply_cpa_value_changes: 'Apply CPA Value changes',
  confirm_cpc_bid_update_multiple: 'Are you sure you want to update the bid CPC for $$ properties?',
  confirm_cpa_bid_update_multiple: 'Are you sure you want to change the CPA value of $$ campaigns?',
  confirm_cpc_bid_update: 'Are you sure you want to update the bid CPC for $$?',
  confirm_cpa_bid_update: 'Are you sure you want to change the CPA value of $$?',
  cpc_bid_percentage_exceeded_error:
    'The percentage change you entered exceeds the limit of €$$ and has been capped automatically.',
  cpc_bid_percentage_below_error:
    'The bid CPC % change you entered below the minimum amount of €$$.',
  cpc_bid_absolute_exceeded_error:
    'The bid CPC you entered exceeds €$$ and has been capped automatically.',
  cpc_bid_absolute_below_error: 'The bid CPC you entered below the minimum amount of €$$.',
  cpc_bid_percentage_multiple_partial_exceeded_error:
    'Some items exceed the €$$ limit due to the percentage change and have been capped automatically.',
  cpa_bid_absolute_exceeded_error:
    'The CPA value you entered exceeds $$% and has been capped automatically.',
  cpa_bid_absolute_below_error:
    'The CPA value you entered is below $$% and has been capped automatically.',
  cpa_bid_percentage_exceeded_error:
    'Invalid CPA value: The percentage entered is above the maximum allowed.',
  cpa_bid_percentage_below_error:
    'Invalid CPA value: The percentage entered is below than the minimum allowed.',
} as const;
