import { QueryKey } from '@tanstack/query-core/build/lib';
import { AliasVariant } from './use-update-alias-mutation';

/**
 * A map of aliases by id and locale code.
 */
export type Aliases = Record<number, Record<string, string>>;

export const aliasesQueryKey = (variant: AliasVariant, partnerId: number): QueryKey => [
  'aliases',
  variant,
  partnerId,
];
