import { useQuery } from '@tanstack/react-query';
import { Row } from '../../../components/grid';
import { useSelectedSubPartners } from '../../../hooks/use-selected-partner';
import { PropertyGroupFilters } from './filters/property-groups-filters';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';
import { generateCompositeConditionForBetween, getFormattedDates } from '../../../helpers/utils';
import { httpClient } from '../../../helpers/api/http-client';
import { useSelectedLocales } from '../../../hooks/use-selected-locales/use-selected-locales';
import { PROPERTY_GROUPS_VIEW_COLUMNS, PROPERTY_GROUPS_VIEW_COLUMN_DEFINITIONS } from './columns';
import { useFilters } from '../../../global/context/filter-context';
import { useEffect } from 'react';
import { FilterColumn } from '../../../models';
import { PropertyGroup, usePropertyGroups } from '../../../hooks/use-property-groups';
import { NUMERIC_RANGE } from '../../../constants';

export function usePropertyGroupsView(filters: PropertyGroupFilters) {
  const partnerIds = useSelectedSubPartners().map(p => p.partnerId);
  const [{ from, to }] = useDateRangePeriod();
  const selectedLocales = useSelectedLocales();

  const globalFilters = useFilters({ hideGlobalBiddingTypeDropdown: true });

  useEffect(() => {
    globalFilters.setColumns(PROPERTY_GROUPS_VIEW_COLUMN_DEFINITIONS);
  }, [globalFilters]);

  const applicableGlobalFilters = globalFilters.filters
    .map(filterColumn => {
      if (NUMERIC_RANGE.includes(filterColumn.type)) {
        return generateCompositeConditionForBetween(filterColumn);
      }
      const column = PROPERTY_GROUPS_VIEW_COLUMN_DEFINITIONS.find(
        col => col.name === filterColumn.columnLabel,
      );
      if (column) return { ...filterColumn, columnName: column.key };
      return filterColumn;
    })
    .filter(Boolean) as FilterColumn[];

  const request: FetchPropertyGroupsViewRequest = {
    partnerId: partnerIds,
    date: getFormattedDates(from, to),
    localeCodes: selectedLocales as string[],
    condition: applicableGlobalFilters,
  };
  const propertyGroups = usePropertyGroups().reduce(
    (acc, pg) => {
      acc[pg.id] = pg;
      return acc;
    },
    {} as Record<number, PropertyGroup>,
  );

  const { isFetching, data, refetch } = useQuery(
    ['propertyGroupsView', JSON.stringify(request)],
    () => fetchPropertyGroupsView(request),
    { refetchOnWindowFocus: false },
  );

  return {
    isLoading: isFetching,
    columns: PROPERTY_GROUPS_VIEW_COLUMNS,
    rows: data ? filter(toNestedRows(data, propertyGroups), filters) : [],
    refetch,
  };
}

function filter(data: Row[], filters: PropertyGroupFilters): Row[] {
  if (filters.propertyGroupIds.length === 0) return data;
  return data.filter(el => filters.propertyGroupIds.includes(el.property_group));
}

const toNestedRows = (
  [data, totals]: [PropertyGroupsData, any[]],
  propertyGroups: Record<number, PropertyGroup>,
): Row[] => {
  let id = 0;
  return data.property_groups.map(el => {
    const total = totals.find(t => t.property_group === el.property_group_id) || {};
    return {
      id: id++,
      ...total,
      group: propertyGroups[el.property_group_id].name,
      alias: propertyGroups[el.property_group_id].alias,
      nested: el.modifier.map(modifier => ({
        id: id++,
        group: BM_LABELS[modifier.bm_type],
        nested: modifier.row.map(r => ({
          id: id++,
          ...r,
          group: breakout(r, modifier.bm_type),
        })),
      })),
    };
  });
};

function breakout(row: any, bmType: number): string {
  if (bmType === 0) return row.los_breakout2;
  if (bmType === 1) return row.ttt_breakout2;
  if (bmType === 2) return row.default_date2;
  if (bmType === 3) return row.gs_breakout2;
  return 'Unknown';
}

const BM_LABELS: Record<number, string> = {
  0: 'Length of Stay',
  1: 'Time to Travel',
  2: 'Default Date',
  3: 'Group Size',
};

type PropertyGroupsData = {
  property_groups: {
    property_group_id: number;
    modifier: {
      bm_type: number;
      row: any[];
    }[];
  }[];
};

type FetchPropertyGroupsViewRequest = {
  partnerId: number[];
  date?: {
    from: string;
    to: string;
  };
  localeCodes: string[];
  condition?: FilterColumn[];
};

const fetchPropertyGroupsView = (request: FetchPropertyGroupsViewRequest) =>
  Promise.all([
    httpClient.post<PropertyGroupsData>(PROPERTY_GROUPS_VIEW_URL, request),
    httpClient.post<any[]>(PROPERTY_GROUPS_VIEW_TOTALS_URL, request),
  ]);

const PROPERTY_GROUPS_VIEW_URL = '/analytics/data/property-groups-view';
const PROPERTY_GROUPS_VIEW_TOTALS_URL = PROPERTY_GROUPS_VIEW_URL + '/totals';
