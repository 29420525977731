import { NUMERIC_RANGE_BETWEEN } from '../../constants';
import { FilterColumn, NumericRange } from '../../models';

export const filterListWithPosBySearchText =
  <T extends { pos: { localeCode: string; name: string } }>(text: string) =>
  ({ pos: { localeCode, name } }: T) => {
    const lowerCasedText = text.toLowerCase();
    if (lowerCasedText.includes(',')) {
      const multipleTexts = lowerCasedText.split(',').map(subtext => subtext.trim());
      return multipleTexts.some(
        subtext =>
          localeCode.toLowerCase().includes(subtext) || name.toLowerCase().includes(subtext),
      );
    }
    return (
      localeCode.toLowerCase().includes(lowerCasedText) ||
      name.toLowerCase().includes(lowerCasedText)
    );
  };

export const generateCompositeConditionForBetween = ({
  value,
  type,
  columnName,
  columnLabel,
}: FilterColumn): FilterColumn | undefined => {
  const { from, to } = value as NumericRange;
  if (!from || !to) return;
  const isBetween = type === NUMERIC_RANGE_BETWEEN;
  return {
    columnName: '',
    columnLabel: '',
    type: isBetween ? 'AND' : 'OR',
    value: '',
    condition: [
      {
        columnName,
        columnLabel,
        type: isBetween ? 'GREATER_OR_EQUAL' : 'LESS',
        value: from,
      },
      {
        columnName,
        columnLabel,
        type: isBetween ? 'LESS_OR_EQUAL' : 'GREATER',
        value: to,
      },
    ],
  };
};
