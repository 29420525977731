import { RequestHelper } from '../request-helper';
import { Aggregation, ColumnDef, FilterType, Row, View } from '../../../models';
import { AGGREGATION_NAME, VIEW_NAME } from '../../../constants';
import { BodyFetchCount, BodyFetchRows, ViewPayload } from './types';
import { AxiosError } from 'axios';

const URL_FETCH_COLUMNS = '/analytics/columns/{view}';
const URL_FETCH_ROWS = '/analytics/data/{view}';
const URL_FETCH_COUNT = '/analytics/data/count/{view}';

class ApiViewHelper extends RequestHelper {
  private static instance: ApiViewHelper;

  public static getInstance() {
    if (!ApiViewHelper.instance) {
      ApiViewHelper.instance = new ApiViewHelper();
    }

    return ApiViewHelper.instance;
  }

  public async fetchColumns(view: View, partnersId: number[]): Promise<ColumnDef[]> {
    const params = [{ key: 'view', value: VIEW_NAME[view] }];
    const url = RequestHelper.replaceUrlParams(URL_FETCH_COLUMNS, params);

    try {
      const response = await this.postRequest(url, { partnerId: partnersId });
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'simple_columns_error');
      return [];
    }
  }

  public async fetchRows(
    view: View,
    aggregation: Aggregation | undefined,
    payload: ViewPayload,
  ): Promise<Row[]> {
    const { filters, sort, pagination, partnersId } = payload;

    const paginationTmp =
      pagination !== undefined
        ? {
            page: pagination.numPage,
            size: pagination.numItemsPerPage,
          }
        : undefined;

    const body: BodyFetchRows = {
      condition: filters,
      aggregation: aggregation !== undefined ? AGGREGATION_NAME[aggregation] : undefined,
      sort,
      pagination: paginationTmp,
      partnerId: partnersId,
      localeCodes: payload.localeCodes,
    };

    const params = [{ key: 'view', value: VIEW_NAME[view] }];
    const url = RequestHelper.replaceUrlParams(URL_FETCH_ROWS, params);

    try {
      const response = await this.postRequest(url, body);
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'simple_rows_error');
      return [];
    }
  }

  public async fetchCount(
    view: View,
    aggregation: Aggregation,
    payload: ViewPayload,
  ): Promise<number> {
    const { partnersId, filters } = payload;

    const body: BodyFetchCount = {
      partnerId: partnersId,
      aggregation: AGGREGATION_NAME[aggregation],
      condition: filters,
      localeCodes: payload.localeCodes,
    };

    const params = [{ key: 'view', value: VIEW_NAME[view] }];
    const url = RequestHelper.replaceUrlParams(URL_FETCH_COUNT, params);

    try {
      const response = await this.postRequest(url, body);
      return response.data.resultCount;
    } catch (e) {
      this.handleError(e as AxiosError, 'simple_count_error');
      return 0;
    }
  }
}

export default ApiViewHelper.getInstance();
