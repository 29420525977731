import { useSingleSelectedPartner } from '../../hooks/use-selected-partner';
import { Aliases, aliasesQueryKey } from './aliases';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useCpaGroupAliases() {
  const partner = useSingleSelectedPartner();
  const partnerId = partner?.partnerId;
  return useQuery(aliasesQueryKey('CPA', partnerId ?? 0), () => fetchCpaGroupAliases(partnerId!), {
    enabled: !!partnerId,
  });
}

export const URL_CPA_GROUP_ALIASES = '/cpa-group-aliases';

type CPAAlias = {
  cpaGroupId: number;
  alias: string;
  localeCode: string;
};

async function fetchCpaGroupAliases(partnerId: number): Promise<Aliases> {
  const response = await axios.get<{ cpaGroupAliases: CPAAlias[] }>(
    `${URL_CPA_GROUP_ALIASES}/${partnerId}`,
    {
      baseURL: process.env.REACT_APP_URL,
    },
  );
  const result: Aliases = {};
  for (const { cpaGroupId, localeCode, alias } of response.data.cpaGroupAliases) {
    if (!result[cpaGroupId]) result[cpaGroupId] = {};
    result[cpaGroupId][localeCode] = alias;
  }
  return result;
}
