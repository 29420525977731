import cn from 'classnames';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isSameDay from 'date-fns/is_same_day';
import isValid from 'date-fns/is_valid';
import parse from 'date-fns/parse';
import { useEffect, useRef, useState } from 'react';
import { formattedDate, isUndefinedOrNull } from '../../../helpers/utils';
import { useIsSelectingFrom, useIsSelectingTo } from '../date-picker.state';
import styles from './compare-date-picker.module.css';

type CompareDateRangeInputsProps = {
  from: Date;
  to: Date;
  hoverValue?: string;
  isSelected: boolean;
  onClick: () => void;
  onFromChange: (value: Date | undefined) => void;
  onToChange: (value: Date | undefined) => void;
};

export const CompareDateRangeInputs = ({
  from,
  to,
  isSelected,
  hoverValue,
  onClick,
  onFromChange,
  onToChange,
}: CompareDateRangeInputsProps) => {
  const [fromInput, setFromInput] = useState(formattedDate(from));
  const [isFromInputFocus, setIsFromInputFocus] = useState<boolean>(false);
  const [toInput, setToInput] = useState(formattedDate(to));
  const [isSelectingTo, setIsSelectingTo] = useIsSelectingTo();
  const [isSelectingFrom, setIsSelectingFrom] = useIsSelectingFrom();
  const toInputRef = useRef<HTMLInputElement>(null);
  const fromInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFromInput(formattedDate(from));
    setToInput(formattedDate(to));
  }, [from, to]);

  useEffect(() => {
    if (isSelectingTo && toInputRef.current && isSelected) {
      (toInputRef.current as HTMLInputElement).focus();
    }
    if (isSelectingFrom && fromInputRef.current && isSelected) {
      (fromInputRef.current as HTMLInputElement).focus();
    }
  }, [isSelected, isSelectingFrom, isSelectingTo]);

  const dateFrom = parse(Date.parse(formattedDate(from)));
  const dateTo = parse(Date.parse(formattedDate(to)));
  const isFromInputValid =
    !isUndefinedOrNull(from) &&
    !isUndefinedOrNull(to) &&
    isValid(dateFrom) &&
    (isBefore(dateFrom, dateTo) || isSameDay(dateFrom, dateTo));
  const isToInputValid =
    !isUndefinedOrNull(to) &&
    isValid(dateTo) &&
    (isAfter(dateTo, dateFrom) || isSameDay(dateTo, dateFrom));

  return (
    <>
      <input
        ref={fromInputRef}
        className={cn(styles.datePickerInput, {
          [styles.invalid]: !isFromInputValid,
        })}
        value={isSelected && isFromInputFocus && hoverValue ? hoverValue : fromInput}
        onClick={onClick}
        onChange={event => setFromInput(event.target.value)}
        onFocus={() => {
          setIsFromInputFocus(true);
          setIsSelectingFrom(true);
        }}
        onBlur={() => {
          setIsFromInputFocus(false);
          if (isFromInputValid) {
            onFromChange(new Date(fromInput));
            setFromInput(formattedDate(new Date(fromInput)));
          } else {
            onFromChange(undefined);
          }
        }}
        type="text"
      />
      <input
        ref={toInputRef}
        className={cn(styles.datePickerInput, {
          [styles.invalid]: !isToInputValid,
        })}
        value={isSelected && isSelectingTo && hoverValue ? hoverValue : toInput}
        onClick={onClick}
        onChange={event => setToInput(event.target.value)}
        onFocus={() => setIsSelectingTo(true)}
        onBlur={() => {
          if (isToInputValid && to) {
            onToChange(new Date(toInput));
            setToInput(formattedDate(new Date(toInput)));
          } else {
            onToChange(undefined);
          }
        }}
        type="text"
      />
    </>
  );
};
