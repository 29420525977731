import { ChangeEvent, useEffect, useState } from 'react';
import './date-picker-with-compare.css';
import DayPicker from 'react-day-picker';
import TrackerService, { getCurrentPath } from '../../services/tracker/tracker-service';
import { DatePeriod } from '../../models';
import { DATE_SELECTED_NAME, DateSelected } from '../../services/tracker/customer-actions';
import {
  formattedDate,
  getDifferenceBetweenDays,
  getLastXDays,
  isUndefinedOrNull,
} from '../../helpers/utils';
import isSameDay from 'date-fns/is_same_day';
import {
  endOfISOWeek,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isMonday,
  isToday,
  isValid,
  lastDayOfMonth,
  lastDayOfYear,
  parse,
  startOfISOWeek,
  startOfMonth,
  startOfToday,
  startOfYear,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import {
  removeCompareStateFromLocalStorage,
  useCompareDateRangePeriods,
  useDateRangePeriod,
} from './date-picker.state';
import { DAYS_IN_LAST_12_MONTHS } from '../../constants';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';
import { useSortParams } from '../../hooks/use-sort-params';

export function useDatePicker({
  isPeriodChoosable,
  isTodayChoosable,
  onClose,
}: {
  isPeriodChoosable?: boolean;
  isTodayChoosable?: boolean;
  onClose: () => void;
}) {
  const [globalDateRangePeriod, setGlobalDateRangePeriod] = useDateRangePeriod();
  const [, setGlobalCompareDateRangePeriods] = useCompareDateRangePeriods();
  const [from, setFrom] = useState<Date | undefined>(
    globalDateRangePeriod?.from || getLastXDays(7),
  );
  const [to, setTo] = useState<Date | undefined>(globalDateRangePeriod?.to || getLastXDays(1));
  const [period, setPeriod] = useState(globalDateRangePeriod?.period || DatePeriod.LAST_7);
  const [fromInput, setFromInput] = useState(formattedDate(from as Date));
  const [isFromInputFocus, setIsFromInputFocus] = useState<boolean>(false);
  const [toInput, setToInput] = useState(formattedDate(to as Date));
  const [isToInputFocus, setIsToInputFocus] = useState(false);
  const { period: periodParam, removeSortParams } = useSortParams(['period']);

  const dateFrom = parse(Date.parse(fromInput));
  const dateTo = parse(Date.parse(toInput));
  const isFromInputValid =
    isValid(dateFrom) && (isBefore(dateFrom, dateTo) || isSameDay(dateFrom, dateTo));
  const isToInputValid =
    isValid(dateTo) && (isAfter(dateTo, dateFrom) || isSameDay(dateTo, dateFrom));
  const isCustomPeriod =
    period === DatePeriod.CUSTOM || isPeriodChoosable === undefined || isPeriodChoosable === false;

  const modifiers = {
    start: from,
    end: to,
  };
  const currentView = String(useCurrentView()).toLowerCase();

  useEffect(() => {
    if (period === DatePeriod.CUSTOM) {
      setFromInput(formattedDate(from as Date));
      setToInput(formattedDate(to as Date));
    }
  }, [period]);

  function getTo(): Date {
    const today = new Date();
    const yesterday = getLastXDays(1);
    return isTodayChoosable ? today : yesterday;
  }

  function isFirstDayOfThisPeriod(): boolean {
    const today = startOfToday();
    return (
      (period === DatePeriod.THIS_WEEK && isMonday(today)) ||
      (period === DatePeriod.THIS_MONTH && isFirstDayOfMonth(today)) ||
      (period === DatePeriod.THIS_YEAR && isSameDay(today, startOfYear(today)))
    );
  }

  function getLastSelectableDay(): Date {
    const limitDate: Date =
      isTodayChoosable || isFirstDayOfThisPeriod() ? startOfToday() : getLastXDays(1);
    limitDate.setHours(0, 0, 0);
    return limitDate;
  }

  const handleDayClick = (day: Date) => {
    const limitDate: Date = getLastSelectableDay();
    if (day > limitDate) {
      return;
    }
    if (isUndefinedOrNull(from)) {
      setFrom(day);
      setTo(undefined);
      setFromInput(formattedDate(day));
      setToInput('');
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(true);
    } else if (!isUndefinedOrNull(from) && isUndefinedOrNull(to)) {
      setTo(day);
      setFromInput(currValue => (from ? formattedDate(from) : currValue));
      setToInput(formattedDate(day));
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(false);
    } else if (from !== undefined && to !== undefined && !DayPicker.DateUtils.isSameDay(from, to)) {
      setFrom(day);
      setTo(day);
      setFromInput(formattedDate(day));
      setToInput(formattedDate(day));
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(true);
    } else if (from !== undefined && to !== undefined) {
      const range: { from: Date | null | undefined; to: Date | null | undefined } =
        DayPicker.DateUtils.addDayToRange(day, {
          from,
          to,
        });
      if (range.from === null || range.to === null) return;
      setFrom(range.from);
      setTo(range.to);
      setFromInput(range.from ? formattedDate(range.from) : '');
      setToInput(range.to ? formattedDate(range.to) : '');
      setPeriod(DatePeriod.CUSTOM);
      setIsToInputFocus(false);
    }
  };

  const onChangeSelectHandler = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    if (periodParam) {
      removeSortParams(['period']);
    }

    const today = new Date();
    const yesterday = getLastXDays(1);
    switch (value) {
      case DatePeriod.CUSTOM:
        setFrom(undefined);
        setTo(undefined);
        setFromInput('');
        setToInput('');
        setPeriod(DatePeriod.CUSTOM);
        setIsToInputFocus(false);
        break;
      case DatePeriod.YESTERDAY:
        setFrom(yesterday);
        setTo(yesterday);
        setPeriod(DatePeriod.YESTERDAY);
        break;
      case DatePeriod.LAST_2:
        setFrom(getLastXDays(2, isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_2);
        break;
      case DatePeriod.LAST_3:
        setFrom(getLastXDays(3, isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_3);
        break;
      case DatePeriod.LAST_7:
        setFrom(getLastXDays(7, isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_7);
        break;
      case DatePeriod.LAST_30:
        setFrom(getLastXDays(30, isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_30);
        break;
      case DatePeriod.THIS_WEEK: {
        const startDate = startOfISOWeek(today);
        const endDate = isToday(startDate) ? startDate : getTo();
        setFrom(startDate);
        setTo(endDate);
        setPeriod(DatePeriod.THIS_WEEK);
        break;
      }
      case DatePeriod.LAST_WEEK: {
        const lastWeek = subWeeks(today, 1);
        setFrom(startOfISOWeek(lastWeek));
        setTo(endOfISOWeek(lastWeek));
        setPeriod(DatePeriod.LAST_WEEK);
        break;
      }
      case DatePeriod.THIS_MONTH: {
        const startDate = startOfMonth(today);
        const endDate = isToday(startDate) ? startDate : getTo();
        setFrom(startDate);
        setTo(endDate);
        setPeriod(DatePeriod.THIS_MONTH);
        break;
      }
      case DatePeriod.LAST_MONTH: {
        const lastMonth = subMonths(today, 1);
        setFrom(startOfMonth(lastMonth));
        setTo(lastDayOfMonth(lastMonth));
        setPeriod(DatePeriod.LAST_MONTH);
        break;
      }
      case DatePeriod.LAST_12_MONTHS: {
        setFrom(getLastXDays(DAYS_IN_LAST_12_MONTHS, isTodayChoosable));
        setTo(getTo());
        setPeriod(DatePeriod.LAST_12_MONTHS);
        break;
      }
      case DatePeriod.THIS_YEAR: {
        const startDate = startOfYear(today);
        const endDate = isToday(startDate) ? startDate : getTo();
        setFrom(startDate);
        setTo(endDate);
        setPeriod(DatePeriod.THIS_YEAR);
        break;
      }
      case DatePeriod.LAST_YEAR: {
        const lastYear = subYears(today, 1);
        setFrom(startOfYear(lastYear));
        setTo(lastDayOfYear(lastYear));
        setPeriod(DatePeriod.LAST_YEAR);
        break;
      }
      default:
        break;
    }
  };

  const onApply = () => {
    const lastSelectableDay = getLastSelectableDay();

    let toTmp = to;
    if (to !== undefined && to >= lastSelectableDay) {
      toTmp = lastSelectableDay;
      toTmp.setHours(to.getHours(), to.getMinutes(), to.getSeconds());
    }
    let fromTmp = from;
    if (
      to !== undefined &&
      ((from !== undefined && from >= lastSelectableDay) || from === undefined)
    ) {
      fromTmp = lastSelectableDay;
      fromTmp.setHours(to.getHours(), to.getMinutes(), to.getSeconds());
    }

    if (fromTmp !== undefined && toTmp !== undefined) {
      // save
      const dateRangePeriodTmp = { from: fromTmp, to: toTmp, period };
      setGlobalDateRangePeriod(dateRangePeriodTmp);
      removeCompareStateFromLocalStorage();
      setGlobalCompareDateRangePeriods([]);
      onClose();
    }

    const currentPath = getCurrentPath().split('/')[0];

    TrackerService.track(`${DATE_SELECTED_NAME} ${currentPath}`, {
      view: currentView,
      calendar: period === DatePeriod.CUSTOM,
      manual: false,
      predefined: period !== DatePeriod.CUSTOM,
      num_days: getDifferenceBetweenDays(from!, to!) + 1,
      predefined_name: period,
    } as DateSelected & { view: string });
  };

  const onFromInputFocus = () => setIsFromInputFocus(true);
  const onToInputFocus = () => setIsToInputFocus(true);

  const onFromInputBlur = () => {
    setIsFromInputFocus(false);
    if (isFromInputValid) {
      setFrom(new Date(fromInput));
      setFromInput(formattedDate(new Date(fromInput)));
    } else {
      setFrom(undefined);
    }
  };

  const onToInputBlur = () => {
    if (isToInputValid) {
      setTo(new Date(toInput));
      setToInput(formattedDate(new Date(toInput)));
    } else {
      setTo(undefined);
    }
  };

  const onChangeFromInput = (event: ChangeEvent<HTMLInputElement>) =>
    setFromInput(event.target.value);
  const onChangeToInput = (event: ChangeEvent<HTMLInputElement>) => setToInput(event.target.value);

  return {
    from,
    to,
    period,
    fromInput,
    isFromInputFocus,
    toInput,
    isToInputFocus,
    isFromInputValid,
    isToInputValid,
    modifiers,
    isCustomPeriod,
    getLastSelectableDay,
    onApply,
    onChangeSelectHandler,
    handleDayClick,
    setPeriod,
    onFromInputBlur,
    onToInputBlur,
    onChangeFromInput,
    onChangeToInput,
    onFromInputFocus,
    onToInputFocus,
  };
}
