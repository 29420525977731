import * as React from 'react';
import { InputFilterFromOtherDimensionProps as Props } from './input-filter.types';
import './input-filter.css';
import DeleteLineDarkIcon from 'tcp-react-icons/lib/DeleteLineDarkIcon';
import { translate } from '../../../../helpers/utils';
import { Input } from './input';

// TODO: Add snapshot tests to inputFilter and this component because they share styles
export const InputFilterFromOtherDimension: React.FunctionComponent<Props> = ({
  filterTypes,
  filter,
  onRemoveFilter,
  index,
}) => {
  const columnOptionsOtherDimensions = [
    <option
      disabled
      selected
      key={filter.columnName}
      value={filter.columnName}
      data-qa={`filters-select-column-${index}-option-${filter.columnName}`}
    >
      {filter.columnLabel}
    </option>,
  ];

  const filterTypeFromOtherDimension = filterTypes
    .filter(filterType => filterType.name === filter.type)
    .map(({ key, name }) => (
      <option
        key={key}
        value={name}
        selected={name === filter.type}
        data-qa={`filters-select-condition-${index}-option-${key}`}
      >
        {name}
      </option>
    ));

  return (
    <div className="u-display--flex u-align-items--center u-margin-bottom--gutter c-input-filter__padding">
      <select
        className="u-margin-right--gutter u-font-size--h-tiny c-input-filter c-input-filter__disabled"
        value={filter.columnName}
        data-qa={`filters-select-column-${index}`}
        disabled
      >
        <option value="" data-qa={`filters-select-column-${index}-option-empty`}>
          {translate('analytics_input_filter_select_column')}
        </option>
        {columnOptionsOtherDimensions}
      </select>
      <select
        className="u-margin-right--gutter u-font-size--h-tiny c-input-filter c-input-filter__disabled"
        value={filter.type}
        data-qa={`filters-select-condition-${index}`}
        disabled
      >
        <option value="">{translate('analytics_input_filter_select_condition')}</option>
        {filterTypeFromOtherDimension}
      </select>
      <Input
        filter={filter}
        updateFilterByAddingValue={() => {}}
        isSelected={false}
        index={index}
        disabled
      />

      <DeleteLineDarkIcon
        onClick={onRemoveFilter}
        className="u-cursor--pointer"
        data-qa={`filters-delete-${index}`}
      />
    </div>
  );
};
