import { Canceler } from 'axios';

export type Token = {
  accessToken: string;
  expirationTime: string;
};

export type Profile = {
  token: Token;
  user: User;
};

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  status: number;
  partners: Partner[];
  memberId: number;
  memberUuid: string;
  level: number;
  partnerLevel: number;
  enabledFeatures: string[];
  isAdmin: boolean;
  sessionId: string;
};

export interface Partner {
  isTrackingWorking: boolean;
  isCpaEligible: boolean;
  partnerId: number;
  name: string;
  type: number;
  subpartners: Partner[];
  locales: Locale[];
}

export interface Region {
  regionName: string;
  localeCodes: string[];
}

export interface Locale {
  localeCode: string;
  name: string;
}

export type RequestQueueMap = Map<string, { canceler: Canceler; path: string }>;

export interface Bid {
  partnerReference: string;
  localeCode: string;
  cpc: string;
}

export enum BiddingActionType {
  CustomEditCpc = 1,
  CPCBiddingModifier = 2,
  CpaValue = 4,
  SlBid = 5,
  CPABiddingModifier = 6,
}
