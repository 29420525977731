import { ColumnDef, FilterColumn, MetricColumn } from '../../../../../models';
import { getColumnKeys } from '../../../../../services/analytics-service';
import { createEmptyFilter } from '../create-filters';
import { NumericRange } from '../../../../../models';
import { NUMERIC_RANGE } from '../../../../../constants';
import { isNumber } from '../../../../../helpers/utils';

export const isFilterFilled = (filter: FilterColumn): boolean => {
  const isNumericRangeFilter = NUMERIC_RANGE.includes(filter.type);

  const isFilterValid = filter.columnName !== '' && filter.columnLabel !== '' && filter.type !== '';

  if (isNumericRangeFilter) {
    return isFilterValid && isValidNumericRange(filter);
  }
  return isFilterValid && filter.value !== '';
};

const doesFilterMatchColumn = (
  filter: FilterColumn,
  columns: ColumnDef[] | MetricColumn[],
): boolean =>
  !!columns.find(
    (column: ColumnDef | MetricColumn) => column.key === filter.columnName && column.isComparable,
  );

const isValidNumber = (filter: FilterColumn, columns: ColumnDef[]): boolean => {
  const columnMatch = columns.find(column => column.key === filter.columnName);
  if (!columnMatch) return false;
  if (columnMatch.type !== 'int' && columnMatch.type !== 'float' && columnMatch.type !== 'long')
    return true;
  if (filter.type === 'NOT_IN' || filter.type === 'IN') {
    const value = filter.value as string;
    return value.split(',').every(numValue => isNumber(numValue));
  }
  if (NUMERIC_RANGE.includes(filter.type)) {
    return isValidNumericRange(filter);
  }
  const numValue = filter.value as string;
  return isNumber(numValue);
};

const isColumnNotComparable = (filter: FilterColumn, columns: ColumnDef[]): boolean => {
  const nonComparableColumns = columns.filter(column => !column.isComparable);
  const columnKeys = getColumnKeys(nonComparableColumns);
  return columnKeys.includes(filter.columnName);
};

export const isFilterValid = (filter: FilterColumn, columns: ColumnDef[]): boolean => {
  if (!isFilterFilled(filter)) return false;
  if (!doesFilterMatchColumn(filter, columns)) return false;
  if (!isValidNumber(filter, columns)) return false;
  if (isColumnNotComparable(filter, columns)) return false;
  return true;
};

export const hasInvalidFilters = (filters: FilterColumn[], columns: ColumnDef[]): boolean =>
  filters.some(filter => !isFilterValid(filter, columns));

export const getValidFilters = (filters: FilterColumn[], columns: ColumnDef[]): FilterColumn[] => {
  const groupedFilters = getGroupedFilters(filters, columns);
  const validFilters = groupedFilters.filter(filter => isFilterValid(filter, columns));
  return validFilters.length > 0 ? validFilters : [createEmptyFilter()];
};

export const getFilledFilters = (filters: FilterColumn[]): FilterColumn[] =>
  filters.filter(filter => isFilterFilled(filter));

export const areAllFiltersFilled = (filters: FilterColumn[]): boolean =>
  filters.filter(filter => !isFilterFilled(filter)).length === 0;

const getColumnMatchingFilters = (
  filters: FilterColumn[],
  columns: MetricColumn[],
): FilterColumn[] => filters.filter(filter => doesFilterMatchColumn(filter, columns));

export const getGroupedFilters = (
  filters: FilterColumn[],
  columns: MetricColumn[] | ColumnDef[],
): FilterColumn[] =>
  filters.map(filter => {
    const widgetColumn = columns?.find(column => column.name === filter.columnLabel);
    if (widgetColumn) return { ...filter, columnName: widgetColumn.key };
    return filter;
  });

export const getMatchingFilters = (
  filters: FilterColumn[],
  columns: MetricColumn[] | ColumnDef[],
): FilterColumn[] => {
  const groupedFilters = getGroupedFilters(filters, columns);
  return getColumnMatchingFilters(groupedFilters, columns);
};

const isValidNumericRange = (filter: FilterColumn): boolean => {
  const value = filter.value as NumericRange;
  return (
    value.from !== undefined && value.to !== undefined && isNumber(value.from) && isNumber(value.to)
  );
};
