import React from 'react';
import { Props } from './input-filter.types';
import './input-filter.css';
import DeleteLineDarkIcon from 'tcp-react-icons/lib/DeleteLineDarkIcon';
import { translate } from '../../../../helpers/utils';
import { ColumnDef } from '../../../../models';
import { inputFilterTypeMap } from './constants';
import { Input } from './input';
import { NumericRange } from '../../../../models';

export const InputFilter: React.FunctionComponent<Props> = ({
  columns,
  filterTypes,
  filter,
  isValid,
  onUpdateFilter,
  onRemoveFilter,
  isSelected,
  isOneFilter,
  index,
}) => {
  const getColumn = (columnKey: string) => columns.find(column => column.key === columnKey);

  const updateFilterBySelectingColumn = (columnKey: string) => {
    const columnLabel = getColumn(columnKey)?.name || '';
    const nextFilter = { ...filter, columnName: columnKey, columnLabel, type: '', value: '' };
    onUpdateFilter(nextFilter, index);
  };

  const updateFilterBySelectingFilterOption = (filterOptionValue: string) => {
    const nextFilter = { ...filter, type: filterOptionValue, value: '' };
    onUpdateFilter(nextFilter, index);
  };

  const updateFilterByAddingValue = (value: string | NumericRange) => {
    const nextFilter = { ...filter, value };
    onUpdateFilter(nextFilter, index);
  };

  const getPossibleFilterTypes = (columnTmp: ColumnDef | undefined) => {
    if (!columnTmp) {
      return [];
    }
    return filterTypes.filter(({ type }) =>
      type.includes(inputFilterTypeMap.get(columnTmp.type) || ''),
    );
  };

  const column = getColumn(filter.columnName);

  const columnOptions = [...columns]
    .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
    .map(({ key, name }) => (
      <option key={key} value={key} data-qa={`filters-select-column-${index}-option-${key}`}>
        {name}
      </option>
    ));

  const filterTypesSorted =
    filter.columnName === ''
      ? []
      : getPossibleFilterTypes(column)
          .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
          .map(({ key, name }) => (
            <option
              key={key}
              value={key}
              data-qa={`filters-select-condition-${index}-option-${key}`}
            >
              {name}
            </option>
          ));

  return (
    <div className="u-display--flex u-align-items--center u-margin-bottom--gutter c-input-filter__padding">
      <select
        className="u-margin-right--gutter u-font-size--h-tiny c-input-filter "
        onChange={({ target: { value } }) => updateFilterBySelectingColumn(value)}
        value={filter.columnName}
        data-qa={`filters-select-column-${index}`}
      >
        <option value="" data-qa={`filters-select-column-${index}-option-empty`}>
          {translate('analytics_input_filter_select_column')}
        </option>
        {columnOptions}
      </select>
      <select
        className="u-margin-right--gutter u-font-size--h-tiny c-input-filter"
        onChange={({ target: { value } }) => updateFilterBySelectingFilterOption(value)}
        value={filter.type}
        data-qa={`filters-select-condition-${index}`}
      >
        <option value="">{translate('analytics_input_filter_select_condition')}</option>
        {filterTypesSorted}
      </select>
      <Input
        {...{ filter, updateFilterByAddingValue, isSelected, index, wrapper: column?.wrapper }}
      />

      {isValid || (isValid && isOneFilter) || !isOneFilter ? (
        <DeleteLineDarkIcon
          onClick={onRemoveFilter}
          className="u-cursor--pointer"
          data-qa={`filters-delete-${index}`}
        />
      ) : (
        <div className="c-input-filter__space-no-trash" />
      )}
    </div>
  );
};
