import { FC } from 'react';
import { Row } from '../../../components/grid';
import { CellWithAlias } from '../../../features/aliases/cell-with-alias';
import { useSingleSelectedLocale } from '../../../hooks/use-selected-locales/use-selected-locales';

export const PropertyGroupCell: FC<{ row: Row }> = ({ row }) => {
  const selectedLocale = useSingleSelectedLocale();
  return (
    <CellWithAlias
      variant="PG"
      id={row.property_group}
      alias={row.alias}
      localeCode={selectedLocale}
    >
      <b>{row.group}</b>
    </CellWithAlias>
  );
};
