import { FC } from 'react';
import styles from './cell-with-alias.module.css';
import { translate } from '../../helpers/utils';
import cn from 'classnames';
import { TooltipPosition, TooltipWrapper } from '../../components/tooltip-wrapper';

type EditAliasLinkProps = {
  alias?: string;
  onClick: VoidFunction;
  isDisabled: boolean;
};

export const EditAliasLink: FC<EditAliasLinkProps> = ({ alias, onClick, isDisabled }) => {
  const link = (
    <a
      className={cn(styles.editAliasLink, { [styles.disabled]: isDisabled })}
      onClick={e => {
        e.stopPropagation();
        if (isDisabled) return;
        onClick();
      }}
    >
      {alias ? translate('aliases_edit') : translate('aliases_add')}
    </a>
  );

  if (isDisabled) {
    return (
      <TooltipWrapper
        noTinyPaddingTop
        message={translate('alias_disabled_tooltip')}
        position={TooltipPosition.TRAILING_CENTER}
        classNameParent={styles.editAliasLink}
      >
        {link}
      </TooltipWrapper>
    );
  }

  return link;
};
