import { Aggregation, DimensionValueLabels, fromTo, MetricColumn, View } from '../../../models';

export const BID_MODIFIER_DIMENSIONS = ['ttt', 'los', 'dd', 'gs'] as const;

export type BidModifierDimensionName = (typeof BID_MODIFIER_DIMENSIONS)[number];

export const isBidModifierDimension = (x: any): x is BidModifierDimensionName =>
  BID_MODIFIER_DIMENSIONS.includes(x);

export const DESTINATION_DIMENSIONS = ['country', 'region', 'city'] as const;

export type DestinationDimensionName = (typeof DESTINATION_DIMENSIONS)[number];

export const isDestinationDimension = (x: any): x is DestinationDimensionName =>
  DESTINATION_DIMENSIONS.includes(x);

export const BIDDING_TYPE_DIMENSION = 'biddingType';
export type Dimension =
  | BidModifierDimensionName
  | DestinationDimensionName
  | typeof BIDDING_TYPE_DIMENSION
  | 'posCountry'
  | 'posRegion'
  | 'propertyName'
  | 'brand'
  | 'propertyGroups'
  | 'timePeriod';

export type DimensionAggregation = {
  aggregation: Aggregation;
  columnName: string;
};

export const CHART_BUILDER_VIEWS = [
  View.TIMELINE_WITH_SL,
  View.BM_CB_WITH_SL,
  View.BIDDING_TYPE_CB_WITH_SL,
  View.POS_CB_WITH_SL,
  View.POS_REGION_CB_WITH_SL,
  View.NAME_PARTNERREF_CB_WITH_SL,
  View.BRAND_CB_WITH_SL,
  View.PROPERTY_GROUPS_WITH_SL,
  View.PROPERTY_LOCATION_CB_WITH_SL,
] as const;

export type ChartBuilderView = (typeof CHART_BUILDER_VIEWS)[number];

export const DIMENSION_VIEW: fromTo<Dimension, ChartBuilderView> = {
  ttt: View.BM_CB_WITH_SL,
  los: View.BM_CB_WITH_SL,
  dd: View.BM_CB_WITH_SL,
  gs: View.BM_CB_WITH_SL,
  biddingType: View.BIDDING_TYPE_CB_WITH_SL,
  posCountry: View.POS_CB_WITH_SL,
  posRegion: View.POS_REGION_CB_WITH_SL,
  propertyName: View.NAME_PARTNERREF_CB_WITH_SL,
  brand: View.BRAND_CB_WITH_SL,
  propertyGroups: View.PROPERTY_GROUPS_WITH_SL,
  city: View.PROPERTY_LOCATION_CB_WITH_SL,
  region: View.PROPERTY_LOCATION_CB_WITH_SL,
  country: View.PROPERTY_LOCATION_CB_WITH_SL,
  timePeriod: View.TIMELINE_WITH_SL,
};

export const DIMENSION_AGGREGATION: fromTo<Dimension, DimensionAggregation> = {
  ttt: { aggregation: Aggregation.BM_POS_TIME_TO_TRAVEL, columnName: 'ttt_breakout' },
  los: { aggregation: Aggregation.BM_POS_LENGTH_OF_STAY, columnName: 'los_breakout' },
  dd: { aggregation: Aggregation.BM_POS_DEFAULT_DATE, columnName: 'is_standard_date' },
  gs: { aggregation: Aggregation.CHART_GROUP_SIZE, columnName: 'gs_breakout2' },
  biddingType: { aggregation: Aggregation.BY_BIDDING_TYPE, columnName: 'cpa_group' },
  posCountry: { aggregation: Aggregation.POS, columnName: 'pos2_cb' },
  posRegion: { aggregation: Aggregation.CHART_POS_REGION, columnName: 'pos_region' },
  propertyName: { aggregation: Aggregation.BY_ITEM_ID, columnName: 'item_id' },
  brand: { aggregation: Aggregation.BRAND, columnName: 'partner_id' },
  propertyGroups: { aggregation: Aggregation.PROPERTY_GROUPS, columnName: 'property_group_ids' },
  city: { aggregation: Aggregation.CHART_CITY, columnName: 'city' },
  region: { aggregation: Aggregation.CHART_REGION, columnName: 'region' },
  country: { aggregation: Aggregation.CHART_COUNTRY, columnName: 'country' },
  timePeriod: { aggregation: Aggregation.TIME_PERIOD, columnName: 'timePeriod' },
};

export interface SelectedDimension {
  name: Dimension;
  values: DimensionValueLabels[];
}

export type Props = {
  title: string;
  applySelection: (filters: SelectedDimension | MetricColumn[] | undefined) => void;
  dimension?: SelectedDimension;
  metrics?: MetricColumn[];
  columns: MetricColumn[];
};
export type MenuItemsProps = {
  applyFilters: (filters: SelectedDimension | MetricColumn[]) => void;
  selectedDimension?: SelectedDimension;
  selectedMetrics?: MetricColumn[];
  columns?: MetricColumn[];
};
export type MenuItemType = {
  key: number;
  label: string;
  subMenu?: {
    key: number;
    label: JSX.Element;
  }[];
};

export type SelectedDimensionMenuProps = {
  menuItems: MenuItemType[];
  selectedDimension: SelectedDimension;
};

export interface Item<T> {
  values?: T[];
  name: string;
}

export interface SelectedItemsListProps<T> {
  items: Item<T>[];
  onOpenMenu: () => void;
  onRemoveItem: (item: Item<T>) => void;
  className?: string;
  children: (item: Item<T>) => React.ReactNode;
}

export type FullScreenDatePickerProps = {
  showFullScreenDatePicker: VoidFunction | undefined;
  customSelectedDate: Date | undefined;
  clearSelectedDate: VoidFunction;
  isModalOpen: boolean;
};
