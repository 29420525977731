import * as React from 'react';
import { Props } from './assign-partner-modal.types';
import './assign-partner-modal.css';
import { ScrollableModal } from '../../../../components/scrollable-modal';
import { TranslationKey, translate } from '../../../../helpers/utils';
import { InputIcon } from '../../../../components/input-icon';
import SearchLineDarkIcon from 'tcp-react-icons/lib/SearchLineDarkIcon';
import { useEffect, useState } from 'react';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { List } from '../../../../components/list';
import ApiAdminHelper from '../../../../helpers/api/admin/api-admin-helper';
import { PartnerAdministration } from '../../../../models';
import { AssignPartnerModalRow } from './assign-partner-modal-row';
import { showNotification } from '../../../../services/notification-service';
import { NotificationLevel } from '../../../../constants';

export const AssignPartnerModal: React.FunctionComponent<Props> = ({
  onClose,
  member,
  memberPartnersIds,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [partners, setPartners] = useState<PartnerAdministration[]>([]);
  const [selectedPartners, setSelectedPartners] = useState<Set<number>>(new Set<number>());

  useEffect(() => {
    getAllPartners();
  }, []);

  const getAllPartners = async () => {
    try {
      setPartners(await ApiAdminHelper.getAllPartner());
    } catch (e) {
      setErrorMessage(translate((e as any).message));
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onReset = () => {
    if (searchValue.length > 0) setSearchValue('');
  };

  const onCheckboxChange = (partnerId: number) => {
    if (selectedPartners.has(partnerId)) {
      selectedPartners.delete(partnerId);
    } else {
      selectedPartners.add(partnerId);
    }
    setSelectedPartners(new Set(selectedPartners));
  };

  const onAssignPartners = async () => {
    if (member) {
      try {
        await ApiAdminHelper.assignMemberPartners(
          member.memberId,
          Array.from(selectedPartners.values()),
          member.memberUuid,
        );
      } catch (e) {
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate((e as any).message),
        });
      }
    }
    onClose();
  };

  const onFilterText =
    <T extends PartnerAdministration>(text: string) =>
    ({ partnerId, name }: T) => {
      const lowerCasedText = text.toLowerCase();
      if (isNaN(Number(text))) {
        return name.toLowerCase().includes(lowerCasedText);
      } else {
        return String(partnerId).includes(lowerCasedText);
      }
    };

  return (
    <ScrollableModal
      title={translate('assign_partner_modal_title')}
      onClose={onClose}
      primaryButton={{
        actionFn: onAssignPartners,
        title: translate('assign_partner_modal_primary_button'),
      }}
      backdropStatic={true}
    >
      <div className="u-padding--gutter">
        <section className="u-display--flex">
          <InputIcon
            title={translate('assign_partner_modal_search_title')}
            className="c-input"
            style={{ width: 500 }}
            icon={searchValue.length === 0 ? SearchLineDarkIcon : CloseIcon}
            value={searchValue}
            onChange={onChange}
            onClickIcon={onReset}
          />
        </section>
        <section className="u-display--block u-margin-top--gutter">
          {errorMessage !== '' ? (
            <span> {translate(errorMessage as TranslationKey)} </span>
          ) : (
            <List
              headers={[
                '',
                translate('member_partner_header_partner_id'),
                translate('member_partner_header_name'),
              ]}
              rows={partners.filter(onFilterText(searchValue)).map(p => (
                <AssignPartnerModalRow
                  key={`assign-partner-modal-row-${p.partnerId}`}
                  partner={p}
                  checkbox={
                    !memberPartnersIds.includes(p.partnerId)
                      ? {
                          isChecked:
                            !memberPartnersIds.includes(p.partnerId) &&
                            selectedPartners.has(p.partnerId),
                          onChange: onCheckboxChange,
                        }
                      : undefined
                  }
                />
              ))}
            />
          )}
        </section>
      </div>
    </ScrollableModal>
  );
};
