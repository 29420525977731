import { useQuery } from '@tanstack/react-query';
import { useSingleSelectedPartner } from '../../hooks/use-selected-partner';
import axios from 'axios';
import { Aliases, aliasesQueryKey } from './aliases';

export function usePropertyGroupAliases() {
  const partner = useSingleSelectedPartner();
  const partnerId = partner?.partnerId;
  return useQuery(
    aliasesQueryKey('PG', partnerId ?? 0),
    () => fetchPropertyGroupAliases(partnerId!),
    { enabled: !!partnerId },
  );
}

export const URL_PROPERTY_GROUP_ALIASES = '/property-group-aliases';

type PGAlias = {
  propertyGroupId: number;
  alias: string;
  localeCode: string;
};

async function fetchPropertyGroupAliases(partnerId: number): Promise<Aliases> {
  const response = await axios.get<{ propertyGroupAliases: PGAlias[] }>(
    `${URL_PROPERTY_GROUP_ALIASES}/${partnerId}`,
    {
      baseURL: process.env.REACT_APP_URL,
    },
  );
  const result: Aliases = {};
  for (const { propertyGroupId, localeCode, alias } of response.data.propertyGroupAliases) {
    if (!result[propertyGroupId]) result[propertyGroupId] = {};
    result[propertyGroupId][localeCode] = alias;
  }
  return result;
}
