import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { Modifier } from 'react-day-picker';
import { DatePeriod, DateRangePeriod } from '../../models';
import {
  CompareColor,
  saveDatePickerStateToLocalStorage,
  useCompareColors,
  useCompareDateRangePeriods,
  useDateRangePeriod,
  useIsCompareEnabled,
  useIsComparing,
} from './date-picker.state';
import { isSameDay, subWeeks } from 'date-fns';
import TrackerService from '../../services/tracker/tracker-service';
import { Color, DATE_PICKER_COMPARE_ADD_PERIOD, DATE_PICKER_COMPARE_CLICK } from '../../constants';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';
import { isUndefinedOrNull } from '../../helpers/utils';

export const MAX_COMPARE_DATE_PERIODS = 2;

const DEFAULT_BLUE_COLOR: CompareColor = {
  name: 'blue',
  primary: Color.Blue700,
  secondary: Color.Blue300,
};
export const COMPARE_DATE_PERIODS_COLORS: CompareColor[] = [
  { name: 'orange', primary: Color.Orange700, secondary: Color.Orange300 },
  { name: 'green', primary: Color.Green700, secondary: Color.Green300 },
];

export function findMissingColors(array: CompareColor[]) {
  const colorNames = new Set(array.map(color => color.name));
  return COMPARE_DATE_PERIODS_COLORS.filter((color: CompareColor) => !colorNames.has(color.name));
}

export function useDatePickerWithCompare({
  from,
  to,
  period,
  setPeriod,
  onClose,
  getLastSelectableDay,
  isComparingPendingValue,
  setIsComparingPendingValue,
}: {
  from?: Date;
  to?: Date;
  period: DatePeriod;
  setPeriod: (value: DatePeriod) => void;
  onClose: () => void;
  getLastSelectableDay: () => Date;
  isComparingPendingValue: boolean;
  setIsComparingPendingValue: (value: boolean) => void;
}) {
  const currentView = String(useCurrentView()).toLowerCase().replace(/_/g, ' ');
  const [isComparing, setIsComparing] = useIsComparing();
  const [isCompareEnabled] = useIsCompareEnabled();
  const [, setGlobalDateRangePeriod] = useDateRangePeriod();
  const [globalCompareDateRangePeriods, setGlobalCompareDateRangePeriods] =
    useCompareDateRangePeriods();
  const [compareDateRangePeriods, setCompareDateRangePeriods] = useState<Array<DateRangePeriod>>(
    globalCompareDateRangePeriods,
  );
  const [selectedDatePeriodIndex, setSelectedDatePeriodIndex] = useState<number | null>(null);
  const [compareColors, setCompareColors] = useCompareColors();
  const selectedDatePeriod =
    selectedDatePeriodIndex !== null
      ? compareDateRangePeriods[selectedDatePeriodIndex]
      : { from, to, period };
  const currentSelectionColor =
    selectedDatePeriodIndex !== null ? compareColors[selectedDatePeriodIndex] : DEFAULT_BLUE_COLOR;

  useEffect(() => {
    if (!from || !to) return;
    if (isComparingPendingValue) {
      if (compareDateRangePeriods.length === 0) {
        addNewCompareDateRangePeriod();
      } else {
        setSelectedDatePeriodIndex(compareDateRangePeriods.length - 1);
      }

      if (period !== DatePeriod.CUSTOM) {
        setPeriod(DatePeriod.CUSTOM);
      }
      if (isComparing) {
        setSelectedDatePeriodIndex(globalCompareDateRangePeriods.length - 1);
        setCompareDateRangePeriods(globalCompareDateRangePeriods);
        setCompareColors(reloadCompareColors());
      }
    } else {
      setSelectedDatePeriodIndex(null);
      setCompareDateRangePeriods([]);
    }
  }, [isComparingPendingValue]);

  function reloadCompareColors(): CompareColor[] {
    const otherTimePeriodCount = globalCompareDateRangePeriods.length;
    const prevStoredCompareColors = compareColors.slice(0, otherTimePeriodCount);
    const missingCompareColorsCount = otherTimePeriodCount - prevStoredCompareColors.length;
    const missingCompareColors = findMissingColors(compareColors).slice(
      0,
      missingCompareColorsCount,
    );
    return prevStoredCompareColors.concat(missingCompareColors);
  }

  const setCompareValue = (value: { from?: Date; to?: Date }, index: number) => {
    const newCompareDatePeriods = cloneDeep(compareDateRangePeriods);
    newCompareDatePeriods[index] = { ...newCompareDatePeriods[index], ...value };
    setCompareDateRangePeriods(newCompareDatePeriods);
  };

  const compareModifiers = {
    start: selectedDatePeriod?.from,
    end: selectedDatePeriod?.to,
    selectedDatePeriod: (selectedDatePeriod as Modifier) || {
      from,
      to,
    },
    otherDatePeriods: isComparingPendingValue
      ? ([{ from, to }, ...compareDateRangePeriods].filter(
          datePeriod =>
            datePeriod.from !== selectedDatePeriod?.from ||
            datePeriod.to !== selectedDatePeriod?.to,
        ) as Modifier[])
      : [],
  };

  const handleCompareDayClick = (day: Date) => {
    const limitDate: Date = getLastSelectableDay();
    if (day > limitDate) {
      return;
    }
    if (isUndefinedOrNull(selectedDatePeriod.from)) {
      setCompareValue({ from: day, to: undefined }, selectedDatePeriodIndex as number);
    } else if (
      !isUndefinedOrNull(selectedDatePeriod.from) &&
      isUndefinedOrNull(selectedDatePeriod.to)
    ) {
      setCompareValue({ to: day }, selectedDatePeriodIndex as number);
    } else if (
      !isUndefinedOrNull(selectedDatePeriod.from) &&
      !isUndefinedOrNull(selectedDatePeriod.to) &&
      !isSameDay(selectedDatePeriod.from as Date, selectedDatePeriod.to as Date)
    ) {
      setCompareValue({ from: day, to: day }, selectedDatePeriodIndex as number);
    } else if (
      !isUndefinedOrNull(selectedDatePeriod.from) &&
      !isUndefinedOrNull(selectedDatePeriod.to)
    ) {
      if (selectedDatePeriod.from && day < selectedDatePeriod.from) {
        setCompareValue(
          { from: day, to: selectedDatePeriod.from },
          selectedDatePeriodIndex as number,
        );
      } else {
        setCompareValue({ to: day }, selectedDatePeriodIndex as number);
      }
    }
  };

  const onCompare = () => {
    const areAllCompareValuesValid = [{ from, to }, ...compareDateRangePeriods].every(
      dateRangePeriod => {
        return dateRangePeriod.from && dateRangePeriod.to;
      },
    );

    if (areAllCompareValuesValid) {
      TrackerService.track(DATE_PICKER_COMPARE_CLICK, {
        view: currentView,
        amountOfComparePeriods: compareDateRangePeriods.length + 1,
      });
      setGlobalDateRangePeriod({ from: from!, to: to!, period });
      setGlobalCompareDateRangePeriods(compareDateRangePeriods);
      saveDatePickerStateToLocalStorage(compareDateRangePeriods);
      onClose();
    }
  };

  const addNewCompareDateRangePeriod = () => {
    if (!from || !to) return;
    if (compareDateRangePeriods.length > 0) {
      TrackerService.track(DATE_PICKER_COMPARE_ADD_PERIOD, {
        fromView: currentView,
      });
    }
    setCompareDateRangePeriods([
      ...compareDateRangePeriods,
      { from: subWeeks(from, 1), to: subWeeks(to, 1), period },
    ]);
    setSelectedDatePeriodIndex(compareDateRangePeriods.length);
    const missingColors = findMissingColors(compareColors);
    setCompareColors([
      ...compareColors,
      missingColors.length > 0 ? missingColors[0] : COMPARE_DATE_PERIODS_COLORS[0],
    ]);
  };

  const removeCompareDateRangePeriod = (index: number) => {
    if (compareDateRangePeriods.length === 1) {
      setSelectedDatePeriodIndex(null);
      setIsComparingPendingValue(false);
      setCompareColors([]);
    } else {
      setSelectedDatePeriodIndex((prev: number) => (prev === 0 ? 0 : prev - 1));
      setCompareDateRangePeriods(compareDateRangePeriods.filter((_, tmpIdx) => tmpIdx !== index));
      setCompareColors(compareColors.filter((_, colorIndex) => colorIndex !== index));
    }
  };

  return {
    isComparing,
    isCompareEnabled,
    compareDateRangePeriods,
    selectedDatePeriodIndex,
    selectedDatePeriod,
    compareModifiers,
    currentSelectionColor,
    compareColors,
    setIsComparing,
    setCompareValue,
    setSelectedDatePeriodIndex,
    setCompareDateRangePeriods,
    handleCompareDayClick,
    addNewCompareDateRangePeriod,
    removeCompareDateRangePeriod,
    onCompare,
  };
}
