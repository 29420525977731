import { FC } from 'react';
import { Button } from '../../../core-ui/components/button/button';
import { translate } from '../../../helpers/utils/translations';
import styles from './compare-date-picker.module.css';

type CompareDatePickerFooterProps = {
  isComparingPendingValue: boolean;
  onClose: VoidFunction;
  onCompare: VoidFunction;
  onApply: VoidFunction;
  setIsComparing: (value: boolean) => void;
  isCompareButtonDisabled: boolean;
  isApplyButtonDisabled: boolean;
};

export const CompareDatePickerFooter: FC<CompareDatePickerFooterProps> = ({
  isComparingPendingValue,
  onClose,
  onCompare,
  onApply,
  setIsComparing,
  isCompareButtonDisabled,
  isApplyButtonDisabled,
}) => {
  return (
    <div className={styles.footer}>
      <Button
        text={translate('analytics_home_cancel')}
        variant="flat"
        onClick={onClose}
        data-qa="date-picker-cancel"
      />
      {isComparingPendingValue ? (
        <Button
          data-qa="date-picker-compare"
          text={translate('analytics_date_compare')}
          onClick={() => {
            setIsComparing(true);
            onCompare();
          }}
          disabled={isCompareButtonDisabled}
        />
      ) : (
        <Button
          data-qa="date-picker-apply"
          text={translate('analytics_home_apply')}
          onClick={() => {
            setIsComparing(false);
            onApply();
          }}
          disabled={isApplyButtonDisabled}
        />
      )}
    </div>
  );
};
