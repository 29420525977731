import { TranslationKey } from '../helpers/utils';

export enum TimeOption {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  QUARTER = 'Quarter',
}

type TimeOptionWithTranslation = {
  translation: TranslationKey;
  option: TimeOption;
};

export const PERIOD_PICKER_OPTIONS: TimeOptionWithTranslation[] = [
  {
    translation: 'time_range_aggregation_day',
    option: TimeOption.DAY,
  },
  {
    translation: 'time_range_aggregation_week',
    option: TimeOption.WEEK,
  },
  {
    translation: 'time_range_aggregation_month',
    option: TimeOption.MONTH,
  },
  {
    translation: 'time_range_aggregation_quarter',
    option: TimeOption.QUARTER,
  },
];

export const NUMERIC_RANGE_BETWEEN = 'BETWEEN';
export const NUMERIC_RANGE_NOT_BETWEEN = 'NOT_BETWEEN';
export const NUMERIC_RANGE = [NUMERIC_RANGE_BETWEEN, NUMERIC_RANGE_NOT_BETWEEN];
