import { FC, PropsWithChildren, useState } from 'react';
import { useSingleSelectedPartner } from '../../hooks/use-selected-partner';
import styles from './cell-with-alias.module.css';
import { EditAliasDialog } from './edit-alias-dialog';
import { EditAliasLink } from './edit-alias-link';
import { AliasVariant } from './use-update-alias-mutation';

type CellWithAliasProps = {
  id: number;
  variant: AliasVariant;
  localeCode: string | null;
  alias?: string;
};

export const CellWithAlias: FC<PropsWithChildren<CellWithAliasProps>> = ({
  id,
  alias,
  variant,
  localeCode,
  children,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const selectedPartner = useSingleSelectedPartner();

  return (
    <span className={styles.cellWithAlias}>
      {children}
      {alias ? (
        <span title={alias} className={styles.alias}>
          {alias}
        </span>
      ) : null}
      <EditAliasLink
        isDisabled={!localeCode || !selectedPartner}
        alias={alias}
        onClick={() => setIsDialogOpen(true)}
      />
      {isDialogOpen ? (
        <EditAliasDialog
          variant={variant}
          onClose={() => setIsDialogOpen(false)}
          initialValue={alias}
          id={id}
          partner={selectedPartner!}
          localeCode={localeCode!}
        />
      ) : null}
    </span>
  );
};
